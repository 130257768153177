import styled from 'styled-components';
import React from 'react';

const GridCard = (props) => {
    const {
        cards = [],
        component: Card,
        width = '300px',
        display = "flex",
        templateColumns = "repeat(auto-fill, minmax(300px, 1fr))",
        margin = "1rem",
        gap = "20px",
        widthContainer = ""
    } = props;

    return (
        <GridCardWrapper
            width={width}
            display={display}
            templateColumns={templateColumns}
            margin={margin}
            gap={gap}
            widthContainer={widthContainer}
        >
            {cards.map((card, i) => (
                <Card key={i} values={card} />
            ))}
        </GridCardWrapper>
    );
};

export default GridCard;

const GridCardWrapper = styled.div`
    ${(props) => props.display === "flex"
        ? `width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-items: center;`
        : `margin: ${props.margin};
    width: ${props.widthContainer};
    display: grid;
    grid-template-columns: ${props.templateColumns};
    gap: ${props.gap};`}
`;
