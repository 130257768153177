import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Twemoji from 'react-emoji-render';
import FileComponent from './FileComponent';
import { Modal } from '@mui/material';
import { Text } from '../../../../components/common/Texts';
import {
    MIMETYPE_CASES,
    MIMETYPE_DOCS_CASES,
} from '../../../../utils/file.utils';
import SkeletonLoading from '../../../../components/common/SkeletonLoading';
import ImageModal from './ImageModal';
import {
    formatDate,
    formatDateTime,
    getFullTimeFormat,
} from '../../../../utils/dates/dates.utils';

const Message = ({
    messageBody,
    message,
    justify,
    isNamesake,
    file,
    loading,
    emitter,
    displayLoader,
    loadingFile,
    lastMessage,
    date,
}) => {
    const lastMessageValidation =
        lastMessage?.message_body?.message === messageBody?.message;

    const [openModal, setOpenModal] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const handleImageViewer = () => {
        setOpenModal(!openModal);
    };

    const imageRef = useRef(null);

    useEffect(() => {
        if (!!imageRef.current) {
            imageRef?.current?.addEventListener('load', imageState);
        }

        return () => {
            imageRef?.current?.removeEventListener('load', imageState);
        };
    }, [imageRef?.current]);

    const imageState = () => {
        setIsLoaded(!isLoaded);
    };

    return (
        <MessageWrapper
            loading={loading}
            displayLoader={displayLoader}
            justifyContent={justify}
        >
            <PositionMsg justifyContent={justify}>
                <MessageInfo jc={justify}>
                    {!!message?.length && (
                        <MessageContainer isNamesake={isNamesake}>
                            <MessageContent isNamesake={isNamesake}>
                                {typeof message === 'string' ? (
                                    <p>
                                        <Twemoji text={message} />
                                    </p>
                                ) : (
                                    <p>{message}</p>
                                )}
                                <Hour isNamesake={isNamesake}>
                                    {formatDate(date) + ' '}
                                    {formatDateTime(date)}
                                </Hour>
                            </MessageContent>
                        </MessageContainer>
                    )}
                    {MIMETYPE_CASES.includes(messageBody?.mime_type) && (
                        <ImageWrapper onClick={handleImageViewer}>
                            <Image
                                isLoaded={isLoaded}
                                ref={imageRef}
                                className="attachment"
                                loading="lazy"
                                src={file}
                            />
                        </ImageWrapper>
                    )}
                    {MIMETYPE_DOCS_CASES.includes(messageBody?.mime_type) && (
                        <FileComponent
                            name={messageBody?.download_name}
                            size={messageBody?.file_size}
                            href={file}
                        />
                    )}

                    {loadingFile && lastMessageValidation && (
                        <CustomSkeleton
                            width="18rem"
                            height="70px"
                            radius="10px"
                        />
                    )}
                </MessageInfo>
            </PositionMsg>
            <ImageModal
                open={openModal}
                onClose={handleImageViewer}
                image={file}
            />
        </MessageWrapper>
    );
};

export default Message;

const MessageWrapper = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: ${(p) => p.justifyContent};
    width: 100%;
    height: auto;

    &:last-child::after {
        position: absolute;
        font-size: 12px;
        content: ${({ loading, displayLoader }) => {
            if (displayLoader) return loading ? "'Enviando...'" : "'Enviado'";
        }};
        height: auto;
        width: auto;
        bottom: -20px;
        right: 0;
    }
`;

const PositionMsg = styled.div`
    display: flex;
    justify-content: ${(p) => p.justifyContent};
`;

const MessageInfo = styled.div`
    width: 100%;
    gap: 0.3rem;
    display: flex;
    flex-direction: column;
    align-items: ${(p) => p.jc};
`;

const MessageContent = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 0.3rem;
    padding: 0.5rem 0.7rem 0.5rem 0.7rem;
    margin: 0.1rem 0;
    color: #535353;
    background-color: ${(p) => (p.isNamesake ? '#C1F0E3' : '#f1f1f1')};
    ${(p) =>
        p.isNamesake
            ? 'border-radius: 10px 0 10px 10px;'
            : 'border-radius: 0 10px 10px 10px;'}

    p {
        font-size: 14px;
        margin-right: ${({ isNamesake }) => isNamesake && '5rem'};
    }
`;

const Hour = styled.span`
    display: flex;
    justify-content: flex-end;
    font-size: 10px;
    color: #888888;
    white-space: nowrap;
    padding: 0 2px 2px 0;
    z-index: 9;

    @media screen and (max-width: 768px) {
        font-size: 8px;
    }
`;

const MessageContainer = styled.div`
    position: relative;
    max-width: 50rem;

    &::after {
        position: absolute;
        content: '';
        height: 15px;
        width: 15px;
        margin: auto;
        top: 1.5px;
        background-color: ${(p) => (p.isNamesake ? '#C1F0E3' : '#f1f1f1')};

        ${(p) => (p.isNamesake ? 'right: -14px;' : 'left: -14px;')}
        ${(p) =>
            p.isNamesake
                ? 'clip-path: polygon(0 0, 0% 100%, 100% 0);'
                : 'clip-path: polygon(0 0, 100% 100%, 100% 0);'}
        ${(p) =>
            p.isNamesake
                ? ' border-radius: 0 5px 0 0;'
                : ' border-radius: 5px 0 0 0;'}
    }
`;

const ImageWrapper = styled.div`
    max-width: 500px;
    width: auto;
    height: auto;
    border-radius: 10px;
    background-color: #f1f1f1;
    border-radius: 10px;
    cursor: pointer;
`;

const Image = styled.img`
    max-width: 100%;
    max-height: 600px;
    width: ${({ isLoaded }) => (isLoaded ? 'auto' : '500px')};
    height: ${({ isLoaded }) => (isLoaded ? 'auto' : '600px')};
    object-fit: cover;
    object-position: top;
    border-radius: 10px;
    outline: 1px solid #c1c1c1;
    visibility: ${({ isLoaded }) => (isLoaded ? 'visible' : 'hidden')};
`;

const CustomSkeleton = styled(SkeletonLoading)``;
