import axios from 'axios';

export const getUpdatedPrograms = async (repoId) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/assignment/current_subjects/student/${repoId}`;

    try {
        const response = await axios.get(URL);
        return response.data.response_data.response;
    } catch (error) {
        // console.log(error);
        return error;
    }
};
