import { IconButton } from '@mui/material';
import { ForumRounded } from '@mui/icons-material';
import React, { useContext } from 'react';
import { ChatContext } from '../../../../contexts/chat/ChatProvider';
import { getUserByRepoPartnerId } from '../../../../redux/api/chatAPI/user';

const ButtonChat = (props) => {
    const { advisor } = props;

    const { handleChat } = useContext(ChatContext);

    return (
        <IconButton onClick={() => handleChat(advisor.partner_id)} size="large">
            <ForumRounded color="primary" />
        </IconButton>
    );
};

export default ButtonChat;
