import React from "react";
import styled from "styled-components";

const estados = [
    { id: 0, name: "Todos" },
    { id: 1, name: "Solicitados" },
    { id: 2, name: "Confirmados" },
];

const ReferredFilter = (props) => {
    const { currentState, handleCurrentState } = props;

    // RETURN
    return (
        <CourseFiltersContainer>
            {estados.map((estado) => (
                <SpanWrapper
                    key={estado.id}
                    currentState={currentState === estado.name}
                    onClick={() => handleCurrentState(estado.name)}
                >
                    <Span currentState={currentState === estado.name}>
                        {estado.name}
                    </Span>
                </SpanWrapper>
            ))}
        </CourseFiltersContainer>
    );
};

export default ReferredFilter;

const CourseFiltersContainer = styled.div`
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    flex-wrap: wrap;
`;

const SpanWrapper = styled.div`
    width: 180px;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: ${(props) => props.currentState && "1px solid #F1F1F1" };
    color: ${(props) => (props.currentState ? "#b31d15" : "#bfbfbf")};
    background-color: ${(props) => props.currentState ? "#ffffff" : "transparent"};
    box-shadow: ${(props) => props.currentState ? "0px 4px 4px -2px #18274B14, 0px 2px 4px -2px #18274B1F" : "none"};

    :hover {
        color: #b31d15;
        background-color: ${(props) => props.currentState ? "#fff" : "#FFF7F6"};
        font-weight: 700;
    }
`;

const Span = styled.span`
    font-size: 1rem;
    font-weight: ${(props) => (props.currentState ? "700" : "400")};
    text-transform: uppercase;
`;
