import moment from 'moment';

function formatDate(date, format = 'DD/MM/YYYY') {
    return moment(date).startOf('day').format(format);
}

export const historicalGradesAdapter = (grades) => {
    const total = grades?.total || 0;
    const mappedGrades =
        grades?.data.map((grade) => ({
            id: grade?.id,
            student: grade?.student.name,
            activity: grade?.activity?.name,
            teacher: grade?.submission?.grading_professor?.name,
            type: grade?.type,
            qualificationDate:
                grade?.type === 'auto_corregible'
                    ? formatDate(grade?.submission?.date, 'DD/MM/YYYY')
                    : //siempre tiene fecha "date" en auto_corregible
                      //  grade?.type === 'presentacion'
                      grade?.submission?.correction_date
                      ? formatDate(
                            grade?.submission?.correction_date,
                            'DD/MM/YYYY'
                        )
                      : null,
            presentationDate:
                grade?.type === 'auto_corregible'
                    ? formatDate(grade?.submission?.date, 'DD/MM/YYYY')
                    : grade?.submission?.send_date
                      ? formatDate(grade?.submission?.send_date, 'DD/MM/YYYY')
                      : null, //solo para tipo "presentacion", las "auto_corregible" solo tienen fecha "date"
            qualification: grade?.score,
            maxScore: grade?.max_score,
            idSubmission: grade?.submission?.id,
            numberSubmission: grade?.submission?.number,
            stateSubmission: grade?.submission?.state,
        })) || [];
    return {
        total,
        grades: mappedGrades,
    };
};

export const historicalStudentsAdapter = (students) => {
    const mappedStudents =
        students?.map((student) => ({
            id: student?.id,
            name: student?.name,
            email: student?.email,
        })) || [];
    return {
        studentsList: mappedStudents,
    };
};

export const historicalActivitiesAdapter = (activities) => {
    const mappedActivities =
        activities?.map((activity) => ({
            id: activity?.id,
            name: activity?.name,
            type: activity?.type,
        })) || [];
    return {
        activitiesList: mappedActivities,
    };
};
