import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

// Components
import Navbar from '../../components/ui/organisms/certificate/Navbar';
import SimpleLoading from '../../components/common/SimpleLoading';
import InfoSection from './components/my-referrals/InfoSection';
import ModalConfirm from './components/referred/ModalConfirm';
import FormRefered from './components/FormRefered';
import ModalError from './components/referred/ModalError';
import ModalForm from './components/referred/ModalForm';
import { Text } from '../../components/common/Texts';
import Footer from './components/referred/Footer';

// Services
import { getPublicInfoForEmail } from './services/auth';

// Redux
import { getMyReferred } from '../../redux/api/referred';
import { getCountries } from '../../redux/api/utils';
import { useSelector } from 'react-redux';

// Assets
import PeopleHighFive from '../../assets/media/aden/people-high-five.png';
import AdenTeAcompaña from '../../assets/media/aden/aden-te-acompaña.png';
import RightImage from './assets/right-image.png';
import LeftImage from './assets/left-image.png';

// Adapters
import { createReferredAdapter, createUserAdapter } from './adapters';

const Referred = () => {
    // User desde params
    const { id } = useParams();

    // User desde redux
    const reduxUser = useSelector((state) => state?.auth?.user);

    const user = createUserAdapter(reduxUser);

    const contactEmailAlumni = user ? user.contactEmailAlumni : null;

    const [student, setStudent] = useState(null);
    const [countries, setCountries] = useState(null);
    const [modalForm, setModalForm] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [myReferred, setMyReferred] = useState(null);

    const confirmed =
        myReferred?.filter((item) => item?.stage === 'Ganadas')?.length || 0;

    useEffect(() => {
        if (!student && (id !== undefined || !!contactEmailAlumni)) {
            getData(id ?? contactEmailAlumni);
            setModalForm(false);
        } else {
            setModalForm(true);
        }
        !countries && getCountry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [student, countries]);

    useEffect(() => {
        myReferred === null && handleReferred();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // FUNCTIONS
    const handleReferred = async () => {
        const response = await getMyReferred();

        if (response.error) {
            setMyReferred([]);
            return;
        }

        const adaptedReferrers = createReferredAdapter(response);
        setMyReferred(adaptedReferrers);
    };

    const getData = async (email, fromForm = false) => {
        setModalLoading(true);
        setModalForm(false);
        const request = await getPublicInfoForEmail(email);
        if (request.error) {
            setStudent(false);
            setModalLoading(false);
            setModalError(true);
        } else {
            setStudent(request);
            setModalLoading(false);
            setModalForm(false);
            if (fromForm) {
                setModalConfirm(true);
            }
        }
    };

    const getCountry = async () => {
        try {
            const request = await getCountries();
            setCountries(request.data.response_data);
        } catch (error) {}
    };

    return (
        <Page>
            {modalForm && (
                <ModalContainer>
                    <Modal>
                        <ModalForm getData={getData} />
                    </Modal>
                </ModalContainer>
            )}
            {modalError && (
                <ModalContainer>
                    <Modal>
                        <ModalError
                            setModalError={setModalError}
                            setModalForm={setModalForm}
                        />
                    </Modal>
                </ModalContainer>
            )}
            {modalLoading && (
                <ModalContainer>
                    <Modal loading={true}>
                        <SimpleLoading />
                    </Modal>
                </ModalContainer>
            )}
            {modalConfirm && (
                <ModalContainer>
                    <Modal>
                        <ModalConfirm
                            student={student}
                            setModalConfirm={setModalConfirm}
                            setModalForm={setModalForm}
                        />
                    </Modal>
                </ModalContainer>
            )}
            <Navbar />
            <Container>
                <NavInfo>
                    <a href="https://aden.org" target="_blank" rel="noreferrer">
                        <Icon
                            icon="fluent:home-16-filled"
                            width="20"
                            height="20"
                            color="#b31d15"
                        />
                    </a>
                    <Icon
                        icon="material-symbols:chevron-right-rounded"
                        width="30"
                        height="30"
                        color="#535353"
                    />
                    <Text
                        fontSize="16px"
                        fontWeight="500"
                        color="#535353"
                        ellipsis={true}
                    >
                        Referidos
                    </Text>
                </NavInfo>
                <CardInfo>
                    <img src={PeopleHighFive} alt="People high five" />
                    <h2>
                        Comparte, expande y crece con el{' '}
                        <b>"Programa de Referidos"</b>
                    </h2>
                    <p>
                        ¡Sigamos impulsando el talento y la prosperidad de la
                        comunidad latinoamericana!
                        <br />
                        Queremos que más ejecutivos vivan la experiencia ADEN.
                    </p>
                    <p>
                        <b>
                            Invita a un colega, amigo o familiar y obtén
                            increíbles beneficios.
                        </b>
                    </p>
                    <a href="#form">
                        <Icon
                            icon="simple-line-icons:plus"
                            width="24px"
                            height="24px"
                        />
                        Añadir referido
                    </a>
                </CardInfo>
                <UserInfo>
                    <img src={user?.photo} alt="User" />
                    <section>
                        <label>
                            Nombre: <b>{user?.name}</b>
                        </label>
                        <label>
                            Correo electrónico: <b>{user?.personalEmail}</b>
                        </label>
                        <label>
                            Legajo: <b>{user?.sisId}</b>
                        </label>
                    </section>
                    <section>
                        <Text fontSize="20px" fontWeight="600" color="#b31d15">
                            Mi resumen del Programa de Referidos:
                        </Text>
                        {myReferred === null ? (
                            <SimpleLoading />
                        ) : (
                            <Body>
                                <MiniCard>
                                    <span>{myReferred?.length || '0'}</span>
                                    <p>
                                        Amigos <br /> referidos
                                    </p>
                                </MiniCard>
                                <MiniCard>
                                    <span>{confirmed}</span>
                                    <p>
                                        Amigos <br /> confirmados
                                    </p>
                                </MiniCard>
                                <MiniCard>
                                    <span>{myReferred?.benefits || '0'}</span>
                                    <p>
                                        Beneficios <br /> recibidos <br />
                                    </p>
                                </MiniCard>
                            </Body>
                        )}
                    </section>
                    <a href="/mis-referidos">Ver más detalles</a>
                </UserInfo>
                <InfoCard>
                    <p>
                        <b>¿Cuáles son los beneficios que tú recibirás?</b>
                    </p>
                    <span>
                        <label>
                            <Icon
                                icon="iconamoon:gift"
                                width="24"
                                height="24"
                                color="#fff"
                            />
                            200 USD
                        </label>
                        <Icon
                            icon="mi:arrow-right"
                            width="1.5rem"
                            height="1.5rem"
                            color="#fff"
                        />
                        <p>
                            Si la persona que refieres se matricula en un MBA o
                            en un GLOBAL MBA.
                        </p>
                    </span>
                    <span>
                        <label>
                            <Icon
                                icon="iconamoon:gift"
                                width="24"
                                height="24"
                                color="#fff"
                            />
                            100 USD
                        </label>
                        <Icon
                            icon="mi:arrow-right"
                            width="1.5rem"
                            height="1.5rem"
                            color="#fff"
                        />
                        <p>
                            Si la persona que refieres se matricula en una
                            MAESTRIA ESPECIALIZADA.
                        </p>
                    </span>
                    <span>
                        <label>
                            <Icon
                                icon="iconamoon:gift"
                                width="24"
                                height="24"
                                color="#fff"
                            />
                            75 USD
                        </label>
                        <Icon
                            icon="mi:arrow-right"
                            width="1.5rem"
                            height="1.5rem"
                            color="#fff"
                        />
                        <p>
                            Si la persona que refieres se matricula en un MAJOR
                            o PROGRAMA ESPECIALIZADO.
                        </p>
                    </span>
                    <hr />
                    <p>
                        <b>¿Y qué beneficio obtiene el referido?</b>
                    </p>
                    <span>
                        <Icon
                            icon="iconamoon:gift"
                            width="24"
                            height="24"
                            color="#fff"
                        />
                        <p>
                            15% de bonificación en TODA NUESTRA PROPUESTA
                            ACADÉMICA.
                        </p>
                    </span>
                </InfoCard>
                <InfoSection type="landing" />
                <FormCard id="form">
                    <img
                        src={LeftImage}
                        alt="left"
                        style={{
                            position: 'absolute',
                            top: '45%',
                            left: '0%',
                            transform: 'translate(0%, -50%)',
                            width: '40vw',
                            height: 'auto',
                            maxHeight: '670px',
                            objectFit: 'cover',
                        }}
                    />
                    <img
                        src={RightImage}
                        alt="right"
                        style={{
                            position: 'absolute',
                            top: '45%',
                            right: '0%',
                            transform: 'translate(0%, -50%)',
                            width: '40vw',
                            height: 'auto',
                            maxHeight: '670px',
                            objectFit: 'cover',
                        }}
                    />
                    <FormRefered student={student} countries={countries} />
                    <img
                        src={AdenTeAcompaña}
                        alt="right"
                        style={{
                            position: 'absolute',
                            top: '96%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 'auto',
                            height: '90px',
                            objectFit: 'cover',
                        }}
                    />
                    <GoUpButton href="#">
                        <Icon
                            icon="iconoir:arrow-up"
                            width="1.2em"
                            height="1.2em"
                        />
                        <label>Volver</label>
                    </GoUpButton>
                </FormCard>
            </Container>
            <Footer />
        </Page>
    );
};

export default Referred;

const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000040;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Modal = styled.div`
    width: 500px;
    height: 320px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow:
        0px 12px 24px -15px #0000001a,
        0px 0px 10px -6px #00000040;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => (props.loading ? 'center' : 'space-between')};
    align-items: center;
    gap: 2rem;

    h2 {
        color: #b31d15;
        text-align: center;
        font-size: 24px;
    }

    p {
        color: #616161;
        font-size: 20px;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        font-size: 16px;
    }

    @media (width < 768px) {
        width: 74vw;
    }
`;

const Page = styled.div`
    width: 100%;
    height: auto;
    background-color: #f9f9f9;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;

    h3 {
        font-weight: 400;
        font-size: 24px;

        @media (width < 768px) {
            font-size: 14px;
            max-width: 70vw;
            text-align: center;
        }
    }
`;

const NavInfo = styled.div`
    width: calc(100% - 4rem);
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 3rem 0 1rem 3rem;
`;

const CardInfo = styled.div`
    position: relative;
    width: calc(100vw - 24rem);
    height: calc(330px - 4rem);
    background-color: #fff;
    border-radius: 20px;
    box-shadow:
        0px 3px 6px 0px #0000003b,
        0px 3px 6px 0px #00000029;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    padding: 2rem;

    img {
        height: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        image-rendering: high-quality;
    }

    h2 {
        color: #b31d15;
        font-size: 24px;
    }

    p {
        color: #000000;
        font-size: 20px;
    }

    a {
        cursor: pointer;
        width: fit-content;
        height: 44px;
        padding: 0 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        background-color: #cd2118;
        color: #ffffff;
        border-radius: 100px;
    }
`;

const UserInfo = styled.div`
    width: calc(100vw - 20rem);
    height: 250px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow:
        0px 3px 6px 0px #0000003b,
        0px 3px 6px 0px #00000029;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    img {
        border-radius: 100px;
    }

    section {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    a {
        cursor: pointer;
        border: 1px solid #b31d15;
        background-color: #fff;
        padding: 12px 24px;
        color: #b31d15;
        border-radius: 100px;
        font-size: 15px;
        font-weight: 600;
    }
`;

const InfoCard = styled.div`
    width: 50%;
    height: 540px;
    border-radius: 20px;
    box-shadow:
        0px 12px 24px -15px #0000001a,
        0px 0px 10px -6px #00000040;
    background: rgb(229, 26, 26);
    background: linear-gradient(
        180deg,
        rgba(229, 26, 26, 1) 0%,
        rgba(179, 29, 21, 1) 100%
    );
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: start;
    padding: 0 3rem;
    z-index: 99;

    @media (width < 768px) {
        width: calc(100% - 8rem);
    }

    p {
        font-size: 20px;
        font-weight: 500;
        color: #fff;

        @media (width < 768px) {
            font-size: 13px;
        }
    }

    span {
        display: flex;
        gap: 1rem;

        @media (width < 768px) {
            svg {
                min-height: 2rem;
                min-width: 2rem;
            }
        }

        label {
            height: fit-content;
            min-width: 120px;
            max-width: 120px;
            display: flex;
            gap: 1rem;
            font-size: 20px;
            font-weight: 500;
            color: #fff;

            @media (width < 768px) {
                font-size: 13px;
                min-width: 90px;
                max-width: 90px;
            }
        }
    }

    hr {
        width: 100%;
        height: 0.5px;
        background-color: #fff;
    }
`;

const FormCard = styled.div`
    position: relative;
    width: 100%;
    height: 900px;
    background-color: #f1f1f1;

    @media (max-width: 768px) {
        height: 1000px;

        img {
            display: none;
        }
    }
`;

const Body = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
`;

const MiniCard = styled.div`
    width: 157px;
    height: 124px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid #c4c4c4;
    box-shadow:
        0px 8px 8px -4px #18274b0a,
        0px 4px 6px -4px #18274b0a;
    border-radius: 20px;

    span {
        color: #000000;
        font-weight: 700;
        font-size: 36px;
    }

    p {
        color: #000000;
        font-size: 15px;
        text-align: center;
    }
`;

const GoUpButton = styled.a`
    cursor: pointer;
    position: absolute;
    bottom: 2rem;
    right: 8rem;
    z-index: 99999;
    background-color: #cd2118;
    color: #fff;
    border-radius: 100px;
    padding: 12px 24px 12px 16px;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    label {
        cursor: pointer;
    }
`;
