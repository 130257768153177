import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Components
import Achievements from '../../components/templates/my-progress/Achievements';
import CoursesAden from '../../components/templates/business/CoursesAden';
import Courses from '../../components/templates/progress/Courses';
import MyTasks from '../../components/templates/progress/MyTasks';
import Tabs from '../../components/common/Tabs';
import Programs from './components/Programs';

// Hooks
import { useQueryParams } from '../../hooks/useQueryParams';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { programsActions } from '../../redux/actions';
import Updates from './tabs/Updates';

const Progress = () => {
    const dispatch = useDispatch();
    const programs = useSelector((state) => state.programs.programs);
    const queryTab = useQueryParams();

    const initialTab =
        queryTab.get('tab') === 'mis-asignaturas'
            ? 1
            : queryTab.get('tab') === 'mis-cursos'
              ? 4
              : 0;

    // EFFECTS
    useEffect(() => {
        if (programs === null) {
            dispatch(programsActions.getProgramsRequest());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programs]);

    const tabs = [
        { label: 'Programas', id: 0 },
        { label: 'Asignaturas', id: 1 },
        { label: 'Cursos', id: 4 },
        { label: 'Logros', id: 2 },
        { label: 'Banco de proyectos', id: 3 },
        { label: 'Acceso de por vida', id: 5 },
    ];
    // STATE
    const [tab, setTab] = useState(initialTab);

    // FUNCTIONS
    function handleTab(value) {
        setTab(value);
    }

    function ToggleMain(props) {
        const { tab, programs, setTab } = props;
        switch (tab) {
            case 0:
                return <Programs programs={programs} report={true} />;
            case 1:
                return <Courses tabsFake={setTab} />;
            case 2:
                return <Achievements />;
            case 3:
                return <MyTasks />;
            case 4:
                return <CoursesAden />;
            case 5:
                return <Updates />;
            default:
                return null;
        }
    }

    // RETURN
    return (
        <ProgressContainer>
            <TabsWrapper>
                <Tabs
                    tabValue={tab}
                    handleTabValue={handleTab}
                    tabOptions={tabs}
                />
            </TabsWrapper>
            <ContentWrapper>
                <ToggleMain programs={programs} setTab={setTab} tab={tab} />
            </ContentWrapper>
        </ProgressContainer>
    );
};

export default Progress;

const ProgressContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-bottom: 4rem;
    @media (max-width: 768px) {
        margin-bottom: 0rem;
        overflow-x: hidden;
    }
`;

const TabsWrapper = styled.div`
    width: calc(100% - 6rem - 70px);
    position: fixed;
    left: 70px;
    top: 70px;
    z-index: 10;
    border-top: 1px solid #f7f7f7;
    padding: 0 3rem;
    background-color: #ffffff;

    & > div {
        justify-content: start;
    }

    @media (max-width: 768px) {
        left: 0;
        width: calc(100% - 6rem);
    }
`;

const ContentWrapper = styled.div`
    width: 100%;
    margin-top: 3rem;
    padding-bottom: 2rem;
    padding-right: 2rem;
    border-radius: 20px;
`;
