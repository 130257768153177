import axios from 'axios';

export const createRefered = async (body) => {
    const URL = `${process.env.REACT_APP_NEW_CRM}/v1/referrals/leads`;

    try {
        const response = await axios.post(URL, body);
        return response.data;
    } catch (error) {
        return { error };
    }
};
