import styled from 'styled-components';
import { Text } from '../../components/common/Texts';

export const WrapperModal = styled.div`
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    gap: ${(props) => (props.gapModal ? props.gapModal : '1rem')};
    border-radius: 20px;
    width: ${(props) => (props.widthModal ? props.widthModal : '550px')};
    height: auto;
    max-height: ${(props) =>
        props.maxHeightModal ? props.maxHeightModal : '80vh'};
    padding: 3rem 2rem;
    position: absolute;
    top: ${(props) => (props.topModal ? props.topModal : '50%')};
    left: ${(props) => (props.leftModal ? props.leftModal : '50%')};
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow:
        0px 12px 24px -15px #0000001a,
        0px 0px 10px -6px #00000040;
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width: 768px) {
        width: ${(props) =>
            props.minWidthModal ? props.minWidthModal : '80%'};
        height: 70%;
        /* justify-content: center; */
    }
`;

export const DividerTitulationModal = styled.div`
    width: 100%;
    margin-bottom: 0.8rem;
    border: ${(props) =>
        props.color ? `1px solid ${props.color}` : '1.5px solid #b31d15'};
`;

export const IdTrackingDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-top: -1rem;
`;

export const TextButtonWhitIcon = styled.button`
    cursor: pointer;
    color: #b31d15;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
`;

export const FilledButton = styled.button`
    cursor: pointer;
    background-color: #b31d15;
    padding: 0.8rem 1.5rem;
    color: #fafafa;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    align-self: center;
`;

export const CloseButton = styled.button`
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    color: #000;
`;

//Chip estado
export const getStatusStyles = (status) => {
    const styles = {
        'pendiente aprobación': {
            color: '#C29F43',
            backgroundColor: '#F9F5EC',
            borderColor: '#C29F43',
        },
        rechazado: {
            color: '#E94C44',
            backgroundColor: '#FFF5F5',
            borderColor: '#E94C44',
        },
        aceptado: {
            color: '#1E8065',
            backgroundColor: '#F7FDFB',
            borderColor: '#1E8065',
        },
        'pendiente carga': {
            color: '#5E80DB',
            backgroundColor: '#F7F8FD',
            borderColor: '#5E80DB',
        },
        'entregado a participante': {
            color: '#5E80DB',
            backgroundColor: '#F7F8FD',
            borderColor: '#5E80DB',
        },
        loading: {
            color: '#616161',
            backgroundColor: '#f9f9f9',
            borderColor: '#616161',
        },
        default: {
            color: '#C29F43',
            backgroundColor: '#F9F5EC',
            borderColor: '#C29F43',
        },
    };
    return styles[status.toLowerCase()] || styles['default'];
};

export const Badge = styled.div`
    max-width: 140px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.5%;
    font-weight: 600;
    padding: 4px 8px;
    color: ${(props) => getStatusStyles(props.status).color};
    background-color: ${(props) =>
        getStatusStyles(props.status).backgroundColor};
    border-color: ${(props) => getStatusStyles(props.status).borderColor};
    border: 1px solid;
    border-radius: 100px;
`;

export const Name = styled.div`
    padding-left: 0.5rem;
    width: fit-content;
`;

export const ListDocumentRequirements = styled.div`
    line-height: 25px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 0.45rem;
    padding: 0 0.5rem;
`;

export const Image = styled.img`
    width: ${(props) => (props.width ? props.width : '90%')};
    height: ${(props) => (props.height ? props.height : '90%')};
    background-repeat: no-repeat;
    object-fit: cover;
    object-position: center;
    align-self: center;
`;

export const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: center;
    align-items: center;
    align-self: center;
    gap: 1.5rem;
    padding: 1rem;
`;

export const RowAlert = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    color: #b31d15;
`;

//InputPDFDocument
export const WrapperInputPDFDocument = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        gap: 19rem;
        width: 100%;
    }
`;

export const ContainerInputPDFDocument = styled.div`
    box-sizing: border-box;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    @media (max-width: 768px) {
        margin-bottom: 1.5rem;
        flex-direction: column;
    }
`;

export const LabelInputPDFDocument = styled.label`
    width: calc(100% - 4rem);
    outline: 2px dashed #a8a8a8;
    background-color: ${({ dragOver }) => (dragOver ? '#e0e0e0' : '#f1f1f1')};
    transition: 0.3s ease-in-out all;
    padding: 2.5rem;
    border-radius: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    svg {
        color: #a8a8a8;
        font-size: 4rem;
    }
    :hover {
        background-color: #f0f0f0;
    }

    @media (max-width: 768px) {
        width: calc(100% - 4rem);
        min-height: 300px;
    }
`;

export const InputForPDFDocument = styled.input`
    display: none;
`;

export const SpanInputPDFDocument = styled.span`
    font-weight: 600;
    font-size: 14px;
    color: #616161;
`;

export const ButtonFileInputPDFDocument = styled.div`
    cursor: pointer;
    max-width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
    padding: 12px 24px;
    border-radius: 100px;

    background-color: #cd2118;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    color: white;
    svg {
        color: white;
    }
`;

//Succes Upload
export const InfoSuccesTop = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
    width: 100%;
`;

export const InfoSuccesTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

export const UploadSuccessWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
`;

export const UploadSuccessMessage = styled.div`
    display: flex;
    width: 90%;
    padding: 12px 14px;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #c4c4c4;

    color: #28a986;
    font-size: 12px;
    line-height: 16px;
    font-weight: 900;

    svg {
        color: #28a986;
    }
`;

export const InfoFileDivInputPDFDocument = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    width: 90%;
    padding: 8px 1rem;
    border-radius: 16px;

    span {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 8px;
    }

    button {
        cursor: pointer;
        padding-left: 1rem;
    }
`;

export const GapInputPDFDocument = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
`;

export const ProgressWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 1rem;
    width: 100%;
    margin-top: 0.5rem;
    position: relative;
    p {
        font-size: 14px;
        font-weight: 600;
        color: #616161;
    }
`;

export const LinearProgressWrapper = styled.div`
    border-radius: 50px;
    width: 80%;
    height: 7px;
    background-color: ${(props) => props.backgroundColor ?? '#eaeaea'};
`;

export const LinearProgress = styled.div`
    border-radius: 50px;
    width: ${(props) => `${props.porcentaje}%`};
    height: 7px;
    background-color: #35d0a5;
`;

export const RowDetails = styled.div`
    display: flex;
    flex-direction: row;
    text-align: center;
    gap: 0.8rem;

    @media (max-width: 380px) {
        flex-direction: column;
    }
`;

export const TextState = styled(Text)`
    @media (max-width: 768px) {
        display: none;
    }
`;

export const StateWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0.8rem 2rem;
    border-radius: 100px;
    border: 1px solid #cacaca;
    background-color: #ffffff;
    align-items: center;
    @media (max-width: 500px) {
        padding: 1rem 1rem;
        flex-direction: column;
    }
`;

//Descarga de archivos

export const FileActionsButton = styled.div`
    width: calc(48px - 16px);
    height: calc(48px - 16px);
    padding: 8px;
    border-radius: 100px;
    border: 1.5px solid #cd2118;
    background-color: #cd2118;
    color: #fafafa;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const DownloadOutlinedButton = styled.div`
    width: max-content;
    height: calc(48px - 16px);
    padding: 0.5rem 1rem;
    border-radius: 100px;
    border: 1.5px solid #cd2118;
    color: #cd2118;
    background-color: #fafafa;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover {
        background-color: #cd2118;
        color: #fafafa;
    }
`;

// Motivos de rechazo
export const CommentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
export const CommentText = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 2rem);
    height: 50px;
    overflow-y: scroll;
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid #c4c4c4;
`;
