import axios from 'axios';

export async function postEvets(data) {
    try {
        const URL = `${process.env.REACT_APP_NEW_CRM}/v1/event/register-event`;
        const response = await axios.post(URL, data);
        return response.data;
    } catch (error) {
        return { error };
    }
}

export async function getInfoEvents(id) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/events/${id}`;

    try {
        const response = await axios.get(URL);
        return response.data.response_data;
    } catch (error) {
        return { error };
    }
}

export async function getDynamicEvents(modality, limit, timelapse, partnerId) {
    let URL = '';
    if (limit === 'all') {
        URL = `${process.env.REACT_APP_NEW_CRM}/v1/event?published=true&modality=${modality}&timelapse=${timelapse}&partner_id=${partnerId}`;
    } else {
        URL = `${process.env.REACT_APP_NEW_CRM}/v1/event?published=true&modality=${modality}&limit=${limit}&timelapse=${timelapse}&partner_id=${partnerId}`;
    }
    if (URL !== '') {
        try {
            const response = await axios.get(URL);
            return response.data;
        } catch (error) {
            return { error };
        }
    }
}
