import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import AutocompleteSelector from './AutocompleteSelector';
import { Button } from '@mui/material';
import ErrorResponse from '../../../common/ErrorResponse';
import { Text } from '../../../common/Texts';

const Filters = (props) => {
    const {
        updateData,
        handleClearFilters,
        filterStudents,
        filterActivities,
        loading_studentsList,
        loading_activitiesList,
        error_studentsList,
        error_activitiesList,
    } = props;

    const [filter1Student, setFilter1Student] = useState(0);
    const [filter1Activity, setFilter1Activity] = useState(0);

    const filterStudentSelected = (value) => {
        if (value && value.id !== undefined) {
            setFilter1Student(value.id);
        } else {
            setFilter1Student(0);
        }
    };

    const filterActivitySelected = (value) => {
        if (value && value.id !== undefined) {
            setFilter1Activity(value.id);
        } else {
            setFilter1Activity(0);
        }
    };

    //Búsqueda de datos con filtros seleccionados
    const handleSearch = () => {
        updateData(filter1Student, filter1Activity);
    };

    //Limpieza de filtros y búsqueda de datos sin filtros
    const handleClear = () => {
        setFilter1Activity(0);
        setFilter1Student(0);
        handleClearFilters();
    };

    if (error_activitiesList || error_studentsList) {
        return <ErrorResponse />;
    }
    if (loading_activitiesList || loading_studentsList) {
        return (
            <FiltersWrapper>
                <FilterSkeleton />
                <FilterSkeleton />
                <FilterSkeleton />
            </FiltersWrapper>
        );
    }
    return (
        <FiltersWrapper>
            {filterStudents?.studentsList?.length > 0 &&
                filterActivities?.activitiesList?.length > 0 && (
                    <>
                        <FilterDiv>
                            <Text
                                fontSize=".8rem"
                                fontWeight="600"
                                color="#222222"
                            >
                                Buscar por nombre de estudiante
                            </Text>
                            <AutocompleteSelector
                                key="estudiantes"
                                options={filterStudents?.studentsList}
                                label={'Buscar estudiante'}
                                filterSelected={filterStudentSelected}
                                value={filter1Student}
                            />
                        </FilterDiv>
                        <FilterDiv>
                            <Text
                                fontSize=".8rem"
                                fontWeight="600"
                                color="#222222"
                            >
                                Buscar por nombre de actividad
                            </Text>
                            <AutocompleteSelector
                                key="actividades"
                                options={filterActivities?.activitiesList}
                                label={'Buscar actividad'}
                                filterSelected={filterActivitySelected}
                                value={filter1Activity}
                            />
                        </FilterDiv>

                        <FilterRow>
                            <ApplyButton
                                onClick={handleSearch}
                                variant="contained"
                                // disabled={
                                //     filter1Activity === '' && filter1Student === ''
                                //         ? true
                                //         : false
                                // }
                            >
                                Buscar
                            </ApplyButton>

                            <ClearButton
                                onClick={handleClear}
                                variant="outlined"
                                dissabled={
                                    filter1Activity === '' &&
                                    filter1Student === ''
                                        ? true
                                        : false
                                }
                            >
                                Limpiar filtros
                            </ClearButton>
                        </FilterRow>
                    </>
                )}
        </FiltersWrapper>
    );
};

export default Filters;

const FiltersWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: stretch;
    align-content: center;
    justify-content: space-between;
    gap: 20px;
    position: relative;
    margin: 2rem 0;
    @media (max-width: 1300px) {
        flex-direction: column;
        height: auto;
        gap: 10px;
        overflow: hidden;
    }
`;

const twinkle = keyframes`
    0%{
        background-color: #e1e1e1;
    }
    50%{
        background-color: #cccccc;
    }
    100%{
        background-color: #e1e1e1;
    }
`;

const FilterSkeleton = styled.div`
    position: relative;
    width: 280px;
    height: 30px;
    display: flex;
    margin-top: 0.5rem;
    justify-content: space-between;
    align-items: center;
    animation: ${twinkle} 2s linear infinite;
`;

const ApplyButton = styled(Button)`
    width: 150px;
    padding: 8px 24px 8px 24px;
    gap: 8px;
    border-radius: 100px;
    color: #fafafa;
    background-color: #cd2118;
    @media (max-width: 380px) {
        width: 100px;
    }

    :hover {
        background-color: #b31d15;
    }
`;

const ClearButton = styled(Button)`
    width: 150px;
    padding: 8px 24px 8px 24px;
    gap: 8px;
    border-radius: 100px;
    color: #b31d15;
    font-weight: 500;
    border: 1px solid #b31d15;
    @media (max-width: 380px) {
        width: 100px;
        padding: 8px 8px 8px 8px;
    }
`;

const FilterDiv = styled.div`
    display: flex;
    align-content: flex-start;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    gap: 1rem;

    p {
        padding-left: 0.5rem;
    }
`;

const FilterRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;

    @media (max-width: 768px) {
        padding-top: 1rem;
    }
`;
