import { useContext, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { titleHTML } from '../../helpers/title.helper';
import useRefreshTheme from '../../hooks/useRefreshTheme';
import { authActions } from '../../redux/actions';
import { socketIO } from '../../helpers/connectionSocket.helper';
import { ChatContext } from '../../contexts/chat/ChatProvider';

const Logout = () => {
    titleHTML('Cerraste sesión');

    const { user } = useContext(ChatContext);

    // REDUX
    const dispatch = useDispatch();
    const authToken = useSelector((state) => state.auth.authToken);
    const theme = useRefreshTheme();
    const history = useHistory();

    // EFFECTS
    useEffect(() => {
        if (authToken !== null) {
            socketIO.emit('setUserStatus', {
                user_id: user?.id,
                status: false,
            });
            socketIO.disconnect();
            dispatch(authActions.logoutUser());
        } else {
            history.push('/auth');
        }
    }, [authToken, dispatch, theme, history, user?.id]);

    return null;
};

export default Logout;
