import styled from 'styled-components';
import { Icon } from '@iconify/react/dist/iconify.js';

// Assets
import {
    socialNetworks,
    aboutAden,
    academicOffering,
    corporateSolutions,
    moreOfAden,
} from './constants/constantsArrays';
import OnlineLearning from '../../../../assets/media/aden/online-learning.png';
import LogoAdenWhite from '../../../../assets/media/aden/aden-white.png';
import QS from '../../../../assets/media/aden/qs.png';
import Flywire from '../../assets/flywire.png';
import TuCuota from '../../assets/tucuota.png';
import Paypal from '../../assets/paypal.png';

const Footer = () => {
    return (
        <Container>
            <span>
                <Icon
                    icon="material-symbols:home-outline"
                    width="1.2em"
                    height="1.2em"
                    color="#7f7f7f"
                />
                <label>Inicio</label>
            </span>
            <Line />
            <Info>
                <AdenInfo>
                    <img
                        src={LogoAdenWhite}
                        alt="Logo ADEN"
                        height={40}
                        width={140}
                    />
                    <SocialNetworks>
                        {socialNetworks.map((social, index) => (
                            <a
                                key={index}
                                href={social.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Icon
                                    icon={social.icon}
                                    width="16px"
                                    height="16px"
                                    color="#ACACAC"
                                />
                            </a>
                        ))}
                    </SocialNetworks>
                    <label>Excelencia académica</label>
                    <img
                        src={OnlineLearning}
                        alt="Estrellas"
                        height={50}
                        width={130}
                    />
                    <img src={QS} alt="QS" height={48} width={48} />
                </AdenInfo>
                <AdenColumnMiddle>
                    <AdenColumn>
                        <label>SOBRE ADEN</label>
                        {aboutAden.map((about, index) => (
                            <a
                                key={index}
                                href={about.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {about.label}
                            </a>
                        ))}
                    </AdenColumn>
                    <AdenColumn>
                        <label>OFERTA ACADÉMICA</label>
                        {academicOffering.map((academic, index) => (
                            <a
                                key={index}
                                href={academic.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {academic.label}
                            </a>
                        ))}
                        <label>SOLUCIONES CORPORATIVAS</label>
                        {corporateSolutions.map((corporate, index) => (
                            <a
                                key={index}
                                href={corporate.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {corporate.label}
                            </a>
                        ))}
                    </AdenColumn>
                    <AdenColumn>
                        <label>MÁS DE ADEN</label>
                        {moreOfAden.map((more, index) => (
                            <a
                                key={index}
                                href={more.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {more.label}
                            </a>
                        ))}
                    </AdenColumn>
                </AdenColumnMiddle>
                <div></div>
                <div></div>
                <MoreInfo>
                    <PaymentsContainer>
                        <label>Procesamos pagos con:</label>
                        <Payments>
                            <img
                                src={Paypal}
                                alt="paypal"
                                width="auto"
                                height="20px"
                            />
                            <img
                                src={Flywire}
                                alt="flywire"
                                width="auto"
                                height="20px"
                            />
                            <img
                                src={TuCuota}
                                alt="tucuota"
                                width="auto"
                                height="20px"
                            />
                        </Payments>
                    </PaymentsContainer>
                </MoreInfo>
            </Info>
            <Line />
            <section>
                <p>Copyright © ADEN International Business School</p>
                <br />
                <p>UNIVERSITE DE MANAGEMENT DE SUISSE, S.A.</p>
            </section>
        </Container>
    );
};

export default Footer;

const Container = styled.div`
    width: calc(100% - 12rem);
    height: calc(666px - 4rem);
    background-color: #1d1d18;
    padding: 2rem 6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 1rem;

    section {
        text-align: center;
        color: #acacac;
        font-size: 14px;
    }

    span {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        label {
            color: #fff;
            font-size: 14px;
        }
    }

    @media (width < 1200px) {
        flex-direction: column;
        padding: 2rem 2rem;
        width: calc(100% - 4rem);
        height: fit-content;
    }
`;

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: #fff;
`;

const Info = styled.div`
    width: 100%;
    min-height: 400px;
    height: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;

    p {
        color: #fff;
    }

    @media (width < 1200px) {
        width: 100%;
    }

    @media (width < 768px) {
        flex-direction: column;
    }
`;

const AdenInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    label {
        color: #acacac;
        font-size: 14px;
    }

    span {
        font-size: 12px;
    }
`;

const SocialNetworks = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 3rem;

    @media (width < 768px) {
        margin-bottom: 1rem;
        justify-content: start;
        gap: 1.3rem;
    }
`;

const AdenColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: #fff;

    label {
        color: #acacac;
        font-weight: 700;
        font-size: 14px;
    }

    a {
        color: #fff;
        font-size: 16px;
    }
`;

const AdenColumnMiddle = styled.div`
    display: flex;
    flex-direction: row;
    gap: 2rem;

    @media (width < 768px) {
        gap: 0;
    }
`;

const MoreInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const PaymentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    label {
        font-size: 14px;
        font-weight: 700;
        color: #acacac;
    }
`;

const Payments = styled.div`
    display: flex;
    gap: 1rem;

    @media (width < 768px) {
        margin-bottom: 2rem;
    }
`;
