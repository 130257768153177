import styled from 'styled-components';

// Components
import FontAwesomeIcon from '../../../../components/common/FontAwesomeIcon';
import CommonButton from '../../../../components/common/CommonButton';
import LazyImg from '../../../../components/common/LazyImg';

// Material UI
import { Modal } from '@mui/material';

// Assets
import ErrorImg from '../../../../assets/media/aden/error-v2.png';

const CommunityModal = (props) => {
    const { openModal, handleCloseModal, user } = props;

    const biographyIncomplete = ``;

    const biography = biographyIncomplete;

    return (
        <FakeModal open={openModal} onClose={handleCloseModal}>
            <Container>
                <MinusInfoButton onClick={handleCloseModal}>
                    <FontAwesomeIcon icon="fa-regular fa-minus" size={24} />
                </MinusInfoButton>
                <CardContainer>
                    <span>
                        <LazyImg
                            src={'https://picsum.photos/300/300'}
                            alt={'imagen'}
                            borderRadius="50%"
                            width="120px"
                            height="120px"
                        />
                        <h2>{user.name}</h2>
                        {/* <p>Licenciado en administración de empresas.</p> */}
                    </span>
                    <CommonButton
                        variant="filled"
                        label="Iniciar chat"
                        onClick={() => {}}
                        Icon={() => (
                            <div>
                                <FontAwesomeIcon
                                    icon="fa-light fa-message-lines"
                                    size={24}
                                />
                            </div>
                        )}
                    />
                </CardContainer>
                <BiographyContainer>
                    <h2>Biografía</h2>
                    {!!biography ? (
                        <p>{biography}</p>
                    ) : (
                        <LackOfInfo>
                            <LazyImg
                                src={ErrorImg}
                                alt={'imagen'}
                                width="150px"
                                height="120px"
                            />
                            <h3>¡Ups!</h3>
                            <label>
                                Faltan algunos datos en la biografía de este
                                estudiante.
                            </label>
                        </LackOfInfo>
                    )}
                </BiographyContainer>
                <ButtonsContainer>
                    <CommonButton
                        label="Iniciar chat"
                        variant="filled"
                        width="100%"
                        Icon={() => (
                            <div>
                                <FontAwesomeIcon
                                    icon="fa-light fa-message-lines"
                                    size={24}
                                />
                            </div>
                        )}
                        onClick={() => {}}
                    />
                    <CommonButton
                        label="Ver menos"
                        variant="text"
                        width="100%"
                        onClick={handleCloseModal}
                    />
                </ButtonsContainer>
            </Container>
        </FakeModal>
    );
};

export default CommunityModal;

const FakeModal = styled(Modal)`
    background-color: #00000010;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    border: none;
    outline: none;
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 24px;
    width: 50%;
    height: fit-content;
    padding: 2rem;
    border-radius: 30px;
    box-shadow:
        0px 4px 4px -2px #18274b14,
        0px 2px 4px -2px #18274b1f;
    position: relative;
    z-index: 999;
    background-color: #fff;
    border: 1px solid #a8a8a8;

    @media (width < 1068px) {
        width: 80%;
        flex-direction: column;
    }
`;

const MinusInfoButton = styled.button`
    width: 48px;
    height: 48px;
    position: absolute;
    top: 1rem;
    right: 1rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;

    &:disabled {
        cursor: not-allowed;
    }

    &:hover {
        box-shadow:
            0px 4px 4px -2px #18274b14,
            0px 2px 4px -2px #18274b1f;
        background-color: #f1f1f1;
    }

    @media (width < 1068px) {
        display: none;
    }
`;

const CardContainer = styled.div`
    position: relative;
    min-width: 180px;
    width: calc(100% - 6rem);
    max-width: 180px;
    min-height: 400px;
    height: calc(100% - 2rem);
    max-height: 400px;
    background-color: #f1f1f1;
    padding: 1rem 3rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border: 1px solid #a8a8a8;
    border-radius: 30px;

    span {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    h2 {
        font-size: 20px;
        font-weight: 700;
        color: #b31d15;
        text-align: start;
    }

    p {
        font-size: 14px;
        font-weight: 600;
        color: #222;
        text-align: start;
    }

    @media (width < 1068px) {
        min-width: 0;
        width: 100%;
        min-height: 0;
        height: fit-content;
        background-color: #fff;
        border: none;
        padding: 0;
        justify-content: start;

        span {
            align-items: start;

            div {
                display: none;
            }

            img {
                display: none;
            }
        }

        button {
            display: none;
        }
    }
`;

const BiographyContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 2rem;

    p {
        height: 100%;
        max-height: 368px;
        overflow-y: auto;
    }
`;

const LackOfInfo = styled.div`
    width: 100%;
    min-height: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    h3 {
        font-size: 24px;
        font-weight: 700;
        color: #b31d15;
    }

    label {
        width: 50%;
        text-align: center;
        color: #222;
        font-size: 16px;
        font-weight: 400;
    }
`;

const ButtonsContainer = styled.div`
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 100%;

    @media (width < 1068px) {
        display: flex;
    }
`;
