import axios from 'axios';

export async function uploadFile(file) {
    const URL = `${process.env.REACT_APP_CHAT_V2}/files/upload-file`;

    try {
        const response = await axios.post(URL, file, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        throw new Error(error);
    }
}

export const download = async (fileName, downloadProgress) => {
    const URL = `${process.env.REACT_APP_CHAT_V2}/files/download/${fileName}`;

    try {
        const response = await axios.post(
            URL,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            },
            {
                onDownloadProgress: downloadProgress,
            }
        );
        return response.data;
    } catch (error) {
        throw new Error({
            message: 'Somthing went wrong!',
            error: error.toString(),
        });
    }
};
