import React, { useEffect } from 'react';
import styled from 'styled-components';
import Message from '../common/Message';
import SkeletonLoading from '../../../../components/common/SkeletonLoading';
import { Text } from '../../../../components/common/Texts';

const MessageBoard = ({ messages, user, loading, loadingFile }) => {
    if (!messages?.length) {
        return (
            <Wrapper isEmpty={messages?.length}>
                <EmptyTextWrapper>
                    <TextWrapper>
                        <Text fontSize="24px" fontWeight="700" color="#b31d15">
                            Este chat se encuentra vacío
                        </Text>
                        <Text fontSize="16px" fontWeight="600">
                            Escribe un mensaje para iniciar la conversación
                        </Text>
                    </TextWrapper>
                </EmptyTextWrapper>
            </Wrapper>
        );
    }

    return (
        <Wrapper isEmpty={messages?.length}>
            {messages?.map((message, index) => (
                <Message
                    key={index}
                    date={message?.created_at}
                    emitter={message?.emitter}
                    user={user?.id}
                    displayLoader={message?.emitter === user?.id}
                    justify={
                        message?.reciver !== user?.id
                            ? 'flex-end'
                            : 'flex-start'
                    }
                    loading={loading}
                    isNamesake={message?.reciver !== user?.id}
                    message={message?.message_body?.message}
                    file={message?.message_body?.file_url}
                    messageBody={message?.message_body}
                    loadingFile={loadingFile}
                    lastMessage={messages[messages.length - 1]}
                />
            ))}
        </Wrapper>
    );
};

export default MessageBoard;

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    gap: 1.7rem;
    padding: 2rem;
    ${({ isEmpty }) => !isEmpty && `height: calc(100% - 4rem)`}
`;

const EmptyTextWrapper = styled.div`
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    /* height: calc(100% + 8rem); */
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
`;
