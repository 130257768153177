import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Components

// Material UI
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import { Text } from '../../../common/Texts';
import SimpleLoading from '../../../common/SimpleLoading';
import NoResultsFound from '../../../common/NoResultsFound';
import Document from '../../molecules/titulation/Document';
import { Icon } from '@iconify/react';
import {
    Badge,
    FilledButton,
    Name,
    RowDetails,
} from '../../../../styled-components';
import {
    ProgressWrapper,
    LinearProgress,
    LinearProgressWrapper,
} from '../../../../styled-components/titulation/titulation';

const Documentation = (props) => {
    const {
        handleSetStateDocumentation,
        documents,
        handleOpenDegreeTrackingModal,
        setStatusLog,
    } = props;

    // State
    const [expanded, setExpanded] = useState(false);

    // Effect
    useEffect(() => {
        if (documents !== null && documents !== undefined) {
            countDocumentation(documents);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documents]);

    // Function
    const countDocumentation = (documents) => {
        documents.map((doc) => {
            if (doc.estado === 'Pendiente') {
                handleSetStateDocumentation(false);
                return false;
            }
            handleSetStateDocumentation(true);
            return true;
        });
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    //RETURNS
    if (documents === null) {
        return (
            <Page>
                <SimpleLoading />
            </Page>
        );
    } else {
        if (documents.length === 0) {
            return (
                <NoResultsFound message="¡Ups! No se encontró ninguna documentación." />
            );
        }
    }
    if (!expanded) {
        setStatusLog('');
    }
    return (
        <>
            {documents.map((document, i) => (
                <AccordionContainer
                    expanded={expanded === `panel${i}`}
                    onChange={handleChange(`panel${i}`)}
                >
                    <AccordionSumm>
                        <ContentWrapper>
                            <Text fontWeight="700" color="#555555">
                                {document.nombre_tipo}
                            </Text>
                            <Text
                                fontSize=".8rem"
                                fontWeight="600"
                                color="#999999"
                            >
                                {document.nombre}
                            </Text>
                            <ProgressWrapper
                                number={document.porcentaje_documentacion}
                            >
                                <LinearProgressWrapper
                                    backgroundColor={'#fafafa'}
                                >
                                    <LinearProgress
                                        porcentaje={
                                            document.porcentaje_documentacion
                                        }
                                    />
                                </LinearProgressWrapper>
                                <Text
                                    fontWeight="400"
                                    fontSize="12px"
                                    color="#222222"
                                    letterSpacing="0.1%"
                                    lineHeight="20px"
                                >
                                    {''}
                                    {document.porcentaje_documentacion} %
                                </Text>
                            </ProgressWrapper>
                        </ContentWrapper>
                        {expanded === `panel${i}` ? (
                            <ViewMoreButton>
                                Ver menos
                                <Icon
                                    icon="lsicon:up-filled"
                                    width="24"
                                    height="24"
                                />
                            </ViewMoreButton>
                        ) : (
                            <ViewMoreButton>
                                Ver más
                                <Icon
                                    icon="lsicon:down-filled"
                                    width="24"
                                    height="24"
                                />
                            </ViewMoreButton>
                        )}
                    </AccordionSumm>
                    <AccordionDetail>
                        {document.requerimientos.length === 0 ? (
                            <Center>
                                <Text
                                    fontWeight="bold"
                                    fontSize={'1.1rem'}
                                    color="#777777"
                                >
                                    No requiere ninguna documentación
                                </Text>
                            </Center>
                        ) : (
                            <>
                                {document.registro_cambio_estado_ids.length !==
                                    0 && (
                                    <>
                                        <Text
                                            fontWeight="600"
                                            fontSize="16px"
                                            color="#222222"
                                            letterSpacing="0.1%"
                                            lineHeight="20px"
                                        >
                                            ENVÍO DE TITULACIÓN
                                        </Text>
                                        <RowDetails>
                                            <StateWrapper>
                                                <Text
                                                    fontWeight="600"
                                                    fontSize="15px"
                                                    color="#616161"
                                                    letterSpacing="0.1%"
                                                    lineHeight="20px"
                                                >
                                                    Estado del título:
                                                </Text>
                                                <Badge status={document.estado}>
                                                    {document.estado ===
                                                    'Entregado a participante' ? (
                                                        <>
                                                            <Icon
                                                                icon="circum:circle-check"
                                                                width="24"
                                                                height="24"
                                                            />
                                                            <Name>
                                                                Entregado
                                                            </Name>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Icon
                                                                icon="mdi-light:clock"
                                                                width="24"
                                                                height="24"
                                                            />
                                                            <Name>
                                                                {
                                                                    document.estado
                                                                }
                                                            </Name>
                                                        </>
                                                    )}
                                                </Badge>
                                            </StateWrapper>
                                            <FilledButton
                                                onClick={() =>
                                                    handleOpenDegreeTrackingModal(
                                                        document
                                                    )
                                                }
                                            >
                                                Ver más detalles
                                            </FilledButton>
                                        </RowDetails>
                                    </>
                                )}
                                <Text
                                    fontWeight="600"
                                    fontSize="16px"
                                    color="#222222"
                                    letterSpacing="0.1%"
                                    lineHeight="20px"
                                >
                                    DOCUMENTACIÓN REQUERIDA
                                </Text>
                                <GridDocument>
                                    {document.requerimientos.map(
                                        (req, index) => {
                                            return (
                                                <Document
                                                    key={index}
                                                    data={{
                                                        ...req,
                                                        id: document.id,
                                                    }}
                                                />
                                            );
                                        }
                                    )}
                                </GridDocument>
                            </>
                        )}
                    </AccordionDetail>
                </AccordionContainer>
            ))}
        </>
    );
};

export default Documentation;

const AccordionSumm = styled(AccordionSummary)`
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #f1f1f1;
    border-radius: 20px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.23);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    .MuiAccordionSummary-content {
        flex-direction: row !important;
        justify-content: space-between !important;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
    @media (max-width: 500px) {
        width: 50%;
    }
`;

const ViewMoreButton = styled.button`
    color: #616161;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    svg {
        color: #616161;
    }
`;

const AccordionContainer = styled(Accordion)`
    margin-bottom: 1rem;
    align-items: center;
    box-shadow: none;
    &::before {
        content: '';
        width: 0px;
        height: 0px;
    }
`;

const AccordionDetail = styled(AccordionDetails)`
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    border-radius: 0 0 20px 20px;
    padding-top: 2rem;
    gap: 1rem;
`;

const Center = styled.div`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const GridDocument = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));
    gap: 10px;
`;

const Page = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StateWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0.8rem 2rem;
    border-radius: 100px;
    border: 1px solid #cacaca;
    background-color: #ffffff;
    align-items: center;
    @media (max-width: 500px) {
        padding: 1rem 1rem;
        flex-direction: column;
    }
`;
