export const usersAdapter = (users) => {
    return {
        users: users.data.map((user) => {
            return {
                id: user._id,
                name: user.name,
                repoId: user.partner_id,
                origins: user.origins,
                createdAt: user.created_at,
                profilePhoto: user.profile_photo,
            };
        }),
        total: users.total,
    };
};
