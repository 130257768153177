import { Modal } from '@mui/material';
import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import {
    CloseButton,
    DividerTitulationModal,
    RowAlert,
    WrapperModal,
} from '../../../../styled-components';
import { Text } from '../../../common/Texts';
import InputPDFDocument from './InputPDFDocument';

const UploadDocumentsModal = (props) => {
    const { open, handleCloseModal, title, handleSave } = props;

    const [file, setFile] = useState(null);

    const handleSaveFile = () => {
        if (!!file) {
            handleSave(file);
            handleCloseModal(true);
        }
    };

    return (
        <Modal onClose={handleCloseModal} open={open}>
            <WrapperModal
                widthModal="550px"
                maxHeightModal="80vh"
                gapModal="1rem"
                topModal="50%"
                leftModal="50%"
            >
                <CloseButton onClick={handleCloseModal}>
                    <Icon icon="iconoir:cancel" width="24px" height="24px" />
                </CloseButton>

                <Text
                    fontSize="20px"
                    fontWeight={700}
                    color="#B31D15"
                    textAlign="left"
                    lineHeight="24px"
                >
                    Subir documentación: {title}
                </Text>
                <DividerTitulationModal />
                <RowAlert>
                    <Icon
                        icon="pepicons-pencil:exclamation-circle"
                        width="24"
                        height="24"
                    />
                    <Text
                        fontSize="16px"
                        fontWeight={700}
                        color="#B31D15"
                        textAlign="left"
                        fontStyle="italic"
                        lineHeight="25px"
                    >
                        Recuerda que sólo puedes subir el documento en formato
                        PDF.{' '}
                    </Text>
                </RowAlert>

                <InputPDFDocument
                    file={file}
                    setFile={setFile}
                    handleSaveFile={handleSaveFile}
                />
            </WrapperModal>
        </Modal>
    );
};

export default UploadDocumentsModal;
