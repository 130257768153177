import { Button } from '@mui/material';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { PersonOutlined } from '@mui/icons-material';
import ForumIcon from '@mui/icons-material/Forum';
import * as encode from 'nodejs-base64-encode';
import LazyImg from '../../../../components/common/LazyImg';
import { ChatContext } from '../../../../contexts/chat/ChatProvider';
import { getUserByRepoPartnerId } from '../../../../redux/api/chatAPI/user';

const SubjectInstructors = (props) => {
    const { descr } = props;
    const { handleChat } = useContext(ChatContext);

    const history = useHistory();

    return (
        <Wrapper>
            <HeaderTile>
                <h2>
                    Equipo docente que te acompañará durante esta asignatura
                </h2>
            </HeaderTile>
            <WrapperInstructor>
                {/* CAMBIO PROVISORIO OCULTAR ESA PERSONA PORQUE ME LO DIJO EL AGUS */}
                {descr.instructors
                    .filter((value) => value.id !== 85362)
                    .map((instructor) => {
                        const hash = encode.encode(
                            instructor?.id + '',
                            'base64'
                        );
                        const academicHash = encode.encode(
                            instructor?.crm_partner_id + '',
                            'base64'
                        );
                        return (
                            <WrapperPerson>
                                <InstructorProfile>
                                    <Image>
                                        <LazyImg
                                            src={instructor.img}
                                            height="100%"
                                            width="100%"
                                        />
                                    </Image>
                                    <ProfileButton
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => {
                                            instructor.rol ===
                                            'ACADEMIC ADVISOR'
                                                ? history.push(
                                                      `/perfil-publico/${academicHash}`
                                                  )
                                                : history.push(
                                                      `/perfil-profesor/${hash}?name=${instructor.name}`
                                                  );
                                        }}
                                    >
                                        <PersonOutlined />
                                        Ver perfil
                                    </ProfileButton>
                                </InstructorProfile>
                                <InstructorInfo>
                                    <InfoInstructor>
                                        {instructor.rol && (
                                            <Label rol={instructor.rol}>
                                                {instructor.rol}
                                            </Label>
                                        )}
                                        <OrnamentLine />
                                        <IntructorNameWrapper>
                                            <InstructorName>
                                                {instructor.name}
                                            </InstructorName>
                                            {!!instructor.title && (
                                                <span>{instructor.title}</span>
                                            )}
                                        </IntructorNameWrapper>
                                        <Body>
                                            <p>{instructor.description}</p>
                                        </Body>{' '}
                                    </InfoInstructor>
                                    <IntructorContact>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            startIcon={<ForumIcon />}
                                            onClick={() => {
                                                handleChat(instructor.id);
                                            }}
                                        >
                                            Enviar mensaje
                                        </Button>
                                        {!!instructor.link_calendly && (
                                            <Button
                                                color="primary"
                                                disabled={
                                                    !instructor.link_calendly
                                                }
                                                variant="contained"
                                                startIcon={
                                                    <CalendarTodayIcon />
                                                }
                                                onClick={() =>
                                                    window.open(
                                                        instructor.link_calendly
                                                    )
                                                }
                                            >
                                                Agendar consulta personalizada
                                            </Button>
                                        )}
                                    </IntructorContact>
                                </InstructorInfo>
                            </WrapperPerson>
                        );
                    })}
            </WrapperInstructor>
        </Wrapper>
    );
};

export default SubjectInstructors;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    margin: 0rem;
    gap: 1rem;
    border-radius: 20px;
    box-shadow:
        0px 12px 24px -15px #0000001a,
        0px 0px 10px -6px #00000040;
    background-color: #ffffff;
`;

const HeaderTile = styled.div`
    width: 100%;
    text-align: start;
    padding: 0 0 1rem 0;

    h2 {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #222222;
    }
`;

const WrapperInstructor = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
`;

const WrapperPerson = styled.div`
    display: grid;
    grid-template-columns: 20% 76.5%;
    padding: 2rem;
    border-radius: 30px;
    gap: 40px;
    background-color: #f1f1f1;
    box-shadow:
        0px 3px 6px 0px #0000003b,
        0px 3px 6px 0px #00000029;
    border: 1px solid #a8a8a8;

    @media (max-width: 550px) {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        gap: 0px;
        padding: 1rem;
    }
`;

const InstructorProfile = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 54px;

    @media (max-width: 1000px) {
        padding-top: 2rem;
    }

    @media (max-width: 550px) {
        flex-direction: column;
        justify-content: start;
    }
`;

const ProfileButton = styled(Button)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    gap: 8px;
    padding: 12px 24px 12px 16px;
    border-radius: 100px;
`;

const InstructorInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 54px;
    width: 100%;
`;

const InstructorName = styled.h2`
    color: #b31d15;
    font-family: Lato;
    font-size: 28px;
    font-style: normal;
    font-weight: 900;
    line-height: 32px;
`;

const IntructorNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    span {
        font-weight: 700;
        color: #222222 !important;
        font-size: 20px;
        font-style: italic;
    }
`;

const IntructorContact = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;

    @media (max-width: 400px) {
        flex-direction: column;
        align-items: start;
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        gap: 8px;
        padding: 12px 24px 12px 16px;
        border-radius: 100px;
    }
`;

const OrnamentLine = styled.span`
    height: 1px;
    width: 100%;
    background-color: #a8a8a8;
    opacity: 0.6;
`;

const Image = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 223px;
    width: 223px;
    overflow: hidden;
    border-radius: 50%;
    filter: drop-shadow(0px 8px 8px rgba(24, 39, 75, 0.04))
        drop-shadow(0px 4px 6px rgba(24, 39, 75, 0.04));

    @media (max-width: 550px) {
        height: 40vw;
        width: 40vw;
    }

    img {
        width: 100%;
        object-fit: center;
    }
`;

const InfoInstructor = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    h3 {
        color: #222222;
        font-size: 1.1rem;
    }
    span {
        color: #a8a8a8;
        font-size: 1rem;
    }
`;

const Label = styled.div`
    color: #616161;
    width: fit-content;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    border-radius: 5px;
    font-size: 1rem;
`;

const Body = styled.div`
    p {
        color: #222222;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
`;
