import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from 'styled-components';
import LinkToCalifications from './LinkToCalifications';
import ErrorResponse from '../../../../common/ErrorResponse';
import MyTableSortLabel from './MyTableSortLabel';

const headCells = [
    {
        id: 'presentationDate',
        numeric: true,
        label: 'Fecha de Entrega',
    },
    {
        id: 'qualificationDate',
        numeric: true,
        label: 'Fecha de Correción',
    },
    {
        id: 'student',
        numeric: false,
        label: 'Estudiante',
    },
    {
        id: 'teacher',
        numeric: false,
        label: 'Profesor',
    },
    {
        id: 'activity',
        numeric: false,
        label: 'Actividad',
    },
    {
        id: 'numberSubmission',
        numeric: false,
        label: 'Número de entrega',
    },
    {
        id: 'qualification',
        numeric: false,
        label: 'Nota',
    },
];

const TableHistoryGradeBook = (props) => {
    const { id_assignment, gradesData } = props;

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('presentationDate');

    function parseDate(dateString) {
        const [day, month, year] = dateString.split('/').map(Number);
        return new Date(year, month - 1, day);
    }

    function descendingComparator(a, b, orderBy) {
        const dateA = a[orderBy] ? parseDate(a[orderBy]) : null;
        const dateB = b[orderBy] ? parseDate(b[orderBy]) : null;
        if (dateB === null && dateA !== null) {
            return -1;
        }
        if (dateB !== null && dateA === null) {
            return 1;
        }
        if (dateB === null && dateA === null) {
            return 0;
        }
        if (dateB < dateA) {
            return -1;
        }
        if (dateB > dateA) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const createSortHandler = (property) => () => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    if (!gradesData) {
        return <ErrorResponse />;
    }

    return (
        <>
            <Wrapper>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRowHeader>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.numeric ? 'right' : 'left'}
                                    sortDirection={
                                        orderBy === headCell.id ? order : false
                                    }
                                >
                                    <MyTableSortLabel
                                        headCell={headCell}
                                        orderBy={orderBy}
                                        order={order}
                                        createSortHandler={createSortHandler}
                                    />

                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRowHeader>
                    </TableHead>
                    <TableBody>
                        {stableSort(
                            gradesData,
                            getComparator(order, orderBy)
                        ).map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRowBody
                                    key={row.id}
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                    >
                                        {row.presentationDate}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.qualificationDate}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.student}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.type === 'auto_corregible'
                                            ? 'Autocorregible'
                                            : (row.teacher ?? '')}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.activity}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.numberSubmission}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.type === 'auto_corregible' ? (
                                            <CellQualification>
                                                <p>
                                                    {row.qualification}/
                                                    {row.maxScore}
                                                </p>
                                            </CellQualification>
                                        ) : row.stateSubmission ===
                                          'pendiente' ? (
                                            <CellQualification>
                                                <p>Pendiente de corrección</p>
                                            </CellQualification>
                                        ) : (
                                            row.stateSubmission ===
                                                'corregido' &&
                                            row.type !== 'auto_corregible' && (
                                                <CellQualification>
                                                    <p>
                                                        {row.qualification}/
                                                        {row.maxScore}
                                                    </p>
                                                    <p>
                                                        <LinkToCalifications
                                                            id_activity={
                                                                row.idSubmission
                                                            }
                                                            id_assignment={
                                                                id_assignment
                                                            }
                                                        />
                                                    </p>
                                                </CellQualification>
                                            )
                                        )}
                                    </TableCell>
                                </TableRowBody>
                            );
                        })}
                    </TableBody>
                </Table>
            </Wrapper>
        </>
    );
};

export default TableHistoryGradeBook;

const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    width: 100%;
    overflow-x: auto;
    border-radius: 20px 20px 0 0;
    border: 1px solid #a8a8a8;

    .MuiTableContainer-root {
        width: 100%;
        overflow-x: auto;
        border-radius: 20px 20px 0 0;
    }
`;

const TableRowBody = styled(TableRow)`
    &:nth-child(even) {
        background-color: #f2f2f2;
    }
    &:nth-child(odd) {
        background-color: #ffffff;
    }
    th,
    td {
        border: 1px solid #a8a8a8;
        font-family: Lato;
        font-size: 13px;
        line-height: normal;
        font-weight: 400;
        @media (max-width: 768px) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 50px;
            font-size: 12px;
        }
    }
`;

const TableRowHeader = styled(TableRow)`
    background-color: #f2f2f2;
    thead,
    tr,
    th {
        border: 1px solid #a8a8a8;
        font-family: Lato;
        font-size: 13px;
        line-height: normal;
        font-weight: 700;
        text-align: center;
        color: #222222;
        padding: 0 0 0 5px;
    }
`;

const CellQualification = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
`;
