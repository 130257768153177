import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

// Components
import RegisterEventModal from './modals/RegisterEventModal';
import EventCardHeader from './eventCard/EventCardHeader';
import EventCardFooter from './eventCard/EventCardFooter';
import EventCardBody from './eventCard/EventCardBody';

// Utils
import { formatDate } from '../../../utils/dates';
import EventSuccessModal from './modals/EventSuccessModal';

const EventCard = (props) => {
    const { value, handleOpenModal } = props;

    // STATES
    const [openModal, setOpenModal] = useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);

    moment.updateLocale('es', {
        months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
            '_'
        ),
        monthsShort:
            'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split(
                '_'
            ),
        weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split(
            '_'
        ),
        weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
        weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
    });

    const currentDate = moment().format('MM/DD/YYYY');
    const eventDate = formatDate(value.date_begin, 'MM/DD/YYYY');
    const formatCurrentDate = moment(currentDate).locale('es').format('LL');
    const eventDateBegin = formatDate(value.date_begin, 'MM/DD/YYYY');
    const eventDateEnd = formatDate(value.date_end, 'MM/DD/YYYY');
    const formatEventDateBegin = moment(eventDateBegin)
        .locale('es')
        .format('LL');
    const formatEventDateEnd = moment(eventDateEnd).locale('es').format('LL');

    const GetValidDate = () => {
        return (
            <span>
                {formatEventDateBegin}{' '}
                {formatEventDateEnd !== formatEventDateBegin
                    ? ' - ' + formatEventDateEnd
                    : null}
            </span>
        );
    };

    // FUNCIONES PARA ABRIR MODAL DE REGISTRO
    const handleRegister = () => {
        handleClick();
    };

    const handleClick = () => {
        setOpenModal(!openModal);
    };

    const handleModalSuccess = () => {
        setOpenSuccessModal(!openSuccessModal);
        // Más que un reload de la página, se debería hacer un refetch de los eventos
        openSuccessModal && window.location.reload();
    };

    return (
        <>
            {openModal && (
                <RegisterEventModal
                    handleClick={handleClick}
                    event={value}
                    handleModalSuccess={handleModalSuccess}
                />
            )}
            {openSuccessModal && (
                <EventSuccessModal
                    handleModal={handleModalSuccess}
                    openModal={openSuccessModal}
                />
            )}
            <Container>
                <EventCardHeader
                    value={value}
                    eventDateBegin={eventDateBegin}
                    currentDate={currentDate}
                />
                <EventCardBody
                    value={value}
                    currentDate={currentDate}
                    eventDateEnd={eventDateEnd}
                    formatEventDateBegin={formatEventDateBegin}
                    formatEventDateEnd={formatEventDateEnd}
                    formatCurrentDate={formatCurrentDate}
                    GetValidDate={GetValidDate}
                />
                <EventCardFooter
                    value={value}
                    eventDateBegin={eventDateBegin}
                    currentDate={currentDate}
                    handleOpenModal={handleOpenModal}
                    eventDate={eventDate}
                    handleRegister={handleRegister}
                    formatEventDateBegin={formatEventDateBegin}
                    formatCurrentDate={formatCurrentDate}
                    eventDateEnd={eventDateEnd}
                />
            </Container>
        </>
    );
};

export default EventCard;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
    min-height: 300px;
    border-radius: 20px;
    overflow: hidden;
    background-color: #ffffff;

    svg {
        color: #a8a8a8;
    }
`;
