export const createUserAdapter = (user) => {
    return {
        contactEmailAlumni: user?.contacto_email_alumni,
        photo: user?.foto,
        name: user?.name,
        personalEmail: user?.personal_email,
        sisId: user?.sis_id,
        crmPartnerId: user?.partner_id,
    };
};
