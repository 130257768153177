import * as encode from 'nodejs-base64-encode';
import styled from 'styled-components';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import FontAwesomeIcon from '../../../components/common/FontAwesomeIcon';
import UpdatedOA from './UpdatedOA';
import Survey from '../../subject/components/subjectTabs/subjectBoard/subjectFormativeModules/Survey';
import UpdatedElective from './UpdatedElective';

const UpdatedSubjectObject = (props) => {
    const {
        index,
        disableLast,
        open,
        setOpen,
        course: module /* , nextOpen */,
    } = props;

    const [electivesFormated, setElectivesFormated] = useState([]);

    const history = useHistory();

    const handleClick = () => {
        window.location.href = `/curso/ODA4OA==?id=MTg3Mw==&origin=plan&updated=true`;
    };

    const handleOpen = () => {
        setOpen(!open, index);
    };

    const subjectType = '';

    const hasElectiveSubject = false;

    const handleClickSurvey = (module) => {
        const survey = {
            link: module?.survey?.link || false,
            id: module?.survey?.id || false,
            done: module?.survey?.done || false,
        };

        // A veces al encodear en base 64 el hash queda con un "/" que no es permitido en la URL, entonces lo reemplazamos por "*"
        const hashSurvey = encode
            .encode(JSON.stringify(survey), 'base64')
            .replace(/\//g, '*');

        history.push(`/view/survey/${hashSurvey}`);
    };

    const moduleTypeHandler = (module) => {
        const moduleMap = {
            survey: (
                <Survey
                    surveyName={module?.survey?.name}
                    handleClick={() => handleClickSurvey(module)}
                />
            ),
            elective: (
                <UpdatedElective
                    electives={module?.electives}
                    handleClick={handleClick}
                    electivesFormated={electivesFormated}
                    disabled={
                        subjectType === 'DEMO' ? !module?.moduleDemo : false
                    }
                    blocked={false}
                    reasonBlocked={''}
                />
            ),
            default: (
                <UpdatedOA
                    module={module}
                    disabled={
                        subjectType === 'DEMO' ? !module?.moduleDemo : false
                    }
                    blocked={false}
                    reasonBlocked={''}
                    disableLast={
                        module?.type === 'Bloque Integrador' && disableLast
                    }
                    handleClick={() => {
                        const hash = encode.encode(module?.id + '', 'base64');
                        handleClick(hash);
                    }}
                    hasElectiveSubject={hasElectiveSubject}
                    infoEnabled={
                        module?.type === 'Bloque Integrador' && disableLast
                    }
                    updated={module?.updated}
                    handleOpen={handleOpen}
                />
            ),
        };

        if (module?.survey) {
            return moduleMap.survey;
        }
        if (!!module?.electives) {
            return moduleMap.elective;
        }
        if (module?.category === 'electiva') {
            return null;
        }
        return moduleMap.default;
    };

    return (
        <AccordionWrapper updated={module?.updated}>
            {moduleTypeHandler(module)}
            {module?.updated && (
                <NewVersionTip>
                    <FontAwesomeIcon
                        icon="fa-light fa-circle-check"
                        size={24}
                    />
                    ¡Versión actualizada!
                </NewVersionTip>
            )}
        </AccordionWrapper>
    );
};

export default UpdatedSubjectObject;

const AccordionWrapper = styled.div`
    position: relative;
    padding-top: ${({ updated }) => (updated ? '2rem' : '0px')};
`;

const NewVersionTip = styled.div`
    position: absolute;
    top: -1rem;
    right: 0;
    background-color: #ffbc00;
    color: #222;
    font-size: 12px;
    padding: 8px 16px 8px 8px;
    border-radius: 100px;
    z-index: 1;
    font-weight: 700;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: fit-content;
`;
