import React from 'react';
import {
    CloseButton,
    ColumnWrapper,
    FilledButton,
    Image,
    WrapperModal,
} from '../../../../styled-components';
import { Modal } from '@mui/material';
import ErrorImage from '../../../../assets/media/aden/error.png';
import { Icon } from '@iconify/react';
import { Text } from '../../../common/Texts';

const WithoutFileModal = (props) => {
    const { open, handleCloseModal } = props;

    return (
        <>
            <Modal open={open} onClose={handleCloseModal}>
                <WrapperModal
                    widthModal="500px"
                    maxHeightModal="80vh"
                    gapModal="1rem"
                    topModal="50%"
                    leftModal="50%"
                >
                    <CloseButton onClick={handleCloseModal}>
                        <Icon
                            icon="iconoir:cancel"
                            width="24px"
                            height="24px"
                        />
                    </CloseButton>

                    <ColumnWrapper>
                        <Image
                            src={ErrorImage}
                            width={'250px'}
                            height={'250px'}
                        />

                        <Text
                            fontSize="24px"
                            fontWeight={700}
                            color="#B31D15"
                            textAlign="center"
                        >
                            Error en la descarga
                        </Text>
                        <div>
                            <Text
                                fontSize="16px"
                                fontWeight={400}
                                color="#222222"
                                textAlign="center"
                            >
                                Ocurrió un problema en la descarga del archivo.
                            </Text>

                            <Text
                                fontSize="16px"
                                fontWeight={700}
                                color="#222222"
                                textAlign="center"
                            >
                                Por favor, comunícate con tu asesor de
                                documentación.
                            </Text>
                        </div>

                        <FilledButton onClick={handleCloseModal}>
                            Volver
                        </FilledButton>
                    </ColumnWrapper>
                </WrapperModal>
            </Modal>
        </>
    );
};

export default WithoutFileModal;
