import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import { Badge } from '@mui/material';
import { formatDateTime } from '../../../../utils/dates/dates.utils';
import LazyImg from '../../../common/LazyImg';
import Text from '../../../common/Text';
import { toTitleCase } from '../../../../helpers/titleCase.helper';

const NotificationCard = (props) => {
    const { notification, deleteNotification } = props;

    const history = useHistory();

    const handleDeleteNotification = (event) => {
        event.stopPropagation();
        deleteNotification(notification?._id);
    };

    const handleRedirectToChatRoom = () => {
        deleteNotification(notification?._id);
        history.push(`/chat?room=${notification?.room_id}`);
    };

    return (
        <Notification onClick={handleRedirectToChatRoom}>
            <IconWrapper onClick={handleDeleteNotification}>
                <Close />
            </IconWrapper>
            <ContentWrapper>
                <ImageWrapper>
                    <LazyImg
                        src={notification?.emitter?.profile_photo}
                        alt={'imagen'}
                        borderRadius="50%"
                        width="45px"
                        height="45px"
                    />
                </ImageWrapper>
                <TextWrapper>
                    <Text
                        ellipsis="ellipsis"
                        fontSize="16px"
                        fontWeight="600"
                        color="#222222"
                    >
                        {toTitleCase(notification?.emitter?.name)}
                    </Text>
                    <Text
                        display="inline-block"
                        ellipsis="ellipsis"
                        fontSize="14px"
                        fontWeight="500"
                        color="#222222"
                    >
                        {
                            notification?.messages[
                                notification?.messages?.length - 1
                            ]?.message_body?.message
                        }
                    </Text>
                </TextWrapper>
                <InfoWrapper>
                    <Hour>{formatDateTime(notification?.created_at)}</Hour>
                    <CustomBadge
                        color="primary"
                        badgeContent={notification?.messages?.length}
                    />
                </InfoWrapper>
            </ContentWrapper>
        </Notification>
    );
};

export default NotificationCard;

const Notification = styled.section`
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 75px;
    padding: 0 0.5rem;
    cursor: pointer;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow:
        0px 2px 15px -4px rgba(24, 39, 75, 0.12),
        0px 2px 15px -4px rgba(24, 39, 75, 0.08);
`;

const IconWrapper = styled.div`
    display: grid;
    place-items: center;
    position: absolute;
    top: -5px;
    right: -5px;
    border-radius: 50%;
    background-color: #ffffff;
    z-index: 20;
    width: 20px;
    height: 20px;
    box-shadow: 0px 2px 5px 2px rgba(24, 39, 75, 0.08);

    svg {
        z-index: 20;
        width: 14px;
        height: 14px;
    }
`;

const ContentWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    max-width: 100%;
`;

const ImageWrapper = styled.div`
    position: relative;
    z-index: 1;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.2rem;
    max-width: 190px;
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 1.1rem;
    position: absolute;
    right: 2.5rem;
    top: -10px;
    bottom: 0;
`;

const Hour = styled.span`
    font-size: 10px;
    color: #888888;
    white-space: nowrap;
    z-index: 9;
`;

const CustomBadge = styled(Badge)`
    margin-right: 8px;
`;
