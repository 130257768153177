import Carousel, { consts } from 'react-elastic-carousel';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// Components
import FontAwesomeIcon from '../../../../components/common/FontAwesomeIcon';
import CardUpdatedAssignment from './CardUpdatedAssignment';

const UpdatedProgram = (props) => {
    const { program } = props;

    const divRef = useRef(null);

    const [containerWidth, setContainerWidth] = useState(0);
    const [count, setCount] = useState(1);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (divRef.current) {
            const divWidth = divRef.current.getBoundingClientRect().width;
            setContainerWidth(divWidth);
        }
    }, []);

    const carouselRef = useRef(null);

    const handleNextArrow = () => {
        carouselRef.current.slideNext();
        setCount((prev) => prev + 1);
    };

    const handlePrevArrow = () => {
        carouselRef.current.slidePrev();
        setCount((prev) => prev - 1);
    };

    return (
        <ProgramWrapper ref={divRef}>
            <h1>{program?.segment_program}</h1>
            <section>
                <InitialsCircle>{program?.acronym}</InitialsCircle>
                <ProgramTitle>
                    <p>{program?.type_program?.toUpperCase()}</p>
                    <h2>{program?.name}</h2>
                </ProgramTitle>
            </section>
            {program?.current_subjects?.length < 3 ? (
                <CardsContainer>
                    {program?.current_subjects?.map((assignment, _index) => (
                        <CardUpdatedAssignment assignment={assignment} />
                    ))}
                </CardsContainer>
            ) : (
                <>
                    <EditedCarousel
                        length={program?.current_subjects?.length}
                        ref={carouselRef}
                        itemPadding={[8, 0]}
                        itemPosition={consts.START}
                        itemsToShow={containerWidth / 380}
                        itemsToScroll={1}
                        onChange={(currentItem) =>
                            setCurrentIndex(currentItem.index)
                        }
                    >
                        {program?.current_subjects?.map(
                            (assignment, _index) => (
                                <CardUpdatedAssignment
                                    assignment={assignment}
                                />
                            )
                        )}
                    </EditedCarousel>
                    <SliderArrows>
                        <p>
                            <b>0{currentIndex + 1}</b> / 0
                            {program?.current_subjects?.length}
                        </p>
                        <button
                            onClick={handlePrevArrow}
                            disabled={count === 1}
                        >
                            <FontAwesomeIcon
                                icon="fa-light fa-arrow-left"
                                size={16}
                            />
                        </button>
                        <button
                            onClick={handleNextArrow}
                            disabled={
                                count === program?.current_subjects?.length
                            }
                        >
                            <FontAwesomeIcon
                                icon="fa-light fa-arrow-right"
                                size={16}
                            />
                        </button>
                    </SliderArrows>
                </>
            )}
        </ProgramWrapper>
    );
};

export default UpdatedProgram;

const ProgramWrapper = styled.div`
    width: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: #f9f9f9;
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    padding: 2rem 40px;

    @media (width < 1068px) {
        section {
            flex-direction: row;
        }

        h1 {
            font-size: 18px;
        }
    }
`;

const InitialsCircle = styled.span`
    min-width: 72px !important;
    height: 72px !important;
    border-radius: 50%;
    background-color: #ffbc00 !important;
    color: #fff;
    font-size: 20px;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
`;

const ProgramTitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    p {
        color: #616161;
        font-size: 16px;
    }

    h2 {
        font-size: 20px;
        color: #000;
    }

    @media (width < 1068px) {
        justify-content: start;
        align-items: start !important;

        p {
            font-size: 14px;
        }

        h2 {
            font-size: 16px;
        }
    }
`;

const CardsContainer = styled.div`
    display: flex;
    gap: 28px;
    padding-bottom: 1rem;
`;

const EditedCarousel = styled(Carousel)`
    padding-bottom: 8px;

    .rec.rec-slider-container {
        margin: 0;
    }
    .rec.rec-arrow {
        display: none;
        visibility: hidden;
    }

    .rec.rec-pagination {
        display: none;
        visibility: hidden;
    }
`;

const SliderArrows = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;

    button {
        cursor: pointer;

        &:disabled {
            color: #c4c4c4;
            cursor: not-allowed;
        }
    }
`;
