import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import MenuItem from '../menu/MenuItem';
import {
    SchoolRounded,
    CloseRounded,
    ContactsRounded,
} from '@mui/icons-material';
import PeopleIcon from '@mui/icons-material/People';
import WidgetsIcon from '@mui/icons-material/Widgets';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import canvas from '../../../assets/media/svg/canvas-icon.svg';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';

const MenuTeacher = (props) => {
    const { menu, closeMenu } = props;
    // STATE
    const wrapperRef = useRef();

    const ondemand = useSelector((state) => state.ondemand.ondemand);

    // EFFECTS
    useEffect(() => {
        if (menu) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menu]);

    // FUNCTIONS
    function handleClickOutside(e) {
        if (wrapperRef.current && wrapperRef.current.contains(e.target)) {
            return;
        }
        closeMenu();
    }

    // RETURN
    return (
        <Aside menu={menu} ref={wrapperRef}>
            <ButtonMenu>
                <MenuIcon />
            </ButtonMenu>
            <AsideTop>
                <Header menu={menu}>
                    <IconButton onClick={closeMenu} size="large">
                        <CloseRounded fontSize="default" color="secondary" />
                    </IconButton>
                </Header>
                <MenuItem
                    title="Dashboard"
                    to="/profesor/dashboard"
                    icon={<WidgetsIcon color="secondary" />}
                    closeMenu={closeMenu}
                />
                <MenuItem
                    title="Asignaturas"
                    to="/profesor/cursos-programas"
                    icon={<SchoolRounded color="secondary" />}
                    closeMenu={closeMenu}
                />
                <MenuItem
                    title="Alumnos"
                    to="/profesor/alumnos"
                    icon={<PeopleIcon color="secondary" />}
                    closeMenu={closeMenu}
                />
                <MenuItem
                    title="Ir a CANVAS"
                    to="https://aden.instructure.com/"
                    icon={<Canvas />}
                    closeMenu={closeMenu}
                    link={true}
                />
                <MenuItem
                    title="Comunidad"
                    to="/comunidad"
                    icon={<ContactsRounded color="secondary" />}
                    closeMenu={closeMenu}
                />
                <MenuItem
                    title="Consultas sincrónicas"
                    to="/chatear"
                    icon={<FreeBreakfastIcon color="secondary" />}
                    closeMenu={closeMenu}
                    isBeta={true}
                />
                <MenuItem
                    title="Oferta educativa"
                    to="/oferta-educativa"
                    icon={<LocalMallIcon color="secondary" />}
                    closeMenu={closeMenu}
                />
                <MenuItem
                    title="Biblioteca de contenidos"
                    to="/biblioteca"
                    icon={<PlayArrowRounded color="secondary" />}
                    closeMenu={closeMenu}
                />
            </AsideTop>
        </Aside>
    );
};

export default MenuTeacher;

const Aside = styled.aside`
    display: flex;
    flex-direction: column;
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    width: 70px;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
    transition: all 0.5s;
    z-index: 999999999;
    a {
        position: relative;
        padding: 1rem;
        transition: all 0.5s;
        padding-left: 18px;
        max-height: 55px;
        span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        svg {
            font-size: 22px;
        }
    }
    a:hover {
        transition: all 0.5s;
    }
    :hover {
        transition: all 0.5s;
        width: 250px;
        a {
            gap: 1rem;
            padding-left: 18px;
            span {
                display: flex;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            transition: ease-in-out all 1s;
        }
        a:hover {
            transition: all 0.5s;
            background: #f9f9f9;
            box-shadow:
                0px 4px 6px -4px rgba(24, 39, 75, 0.12),
                0px 8px 8px -4px rgba(24, 39, 75, 0.08);
            border-radius: 20px;
        }
    }

    @media (max-width: 768px) {
        visibility: ${(props) => (props.menu ? 'visible' : 'hidden')};
        opacity: ${(props) => (props.menu ? 1 : 0)};
        transition: all 0.5s ease;
        position: fixed;
        top: 0;
        left: ${(props) => (props.menu ? '0' : '-100%')};
        bottom: 0;
        z-index: 9999;
        width: 70vw;
        overflow: hidden;
        height: 100%;
    }
`;

const Header = styled.div`
    display: none;
    visibility: ${(props) => (props.menu ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.menu ? 1 : 0)};
    transition: all 0.5s ease;

    @media (max-width: 768px) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }
`;

const AsideTop = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0rem;
`;

const ButtonMenu = styled.div`
    width: 100%;
    height: 70px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        color: #aaaaaa;
        font-size: 2rem;
    }
`;

const Canvas = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_2614_32291)">
                <ellipse
                    cx="12.0533"
                    cy="21.7341"
                    rx="3.24468"
                    ry="3.24468"
                    fill="#999999"
                />
                <circle cx="12.0536" cy="16.734" r="1.01064" fill="#999999" />
                <ellipse
                    cx="15.5639"
                    cy="15.3511"
                    rx="1.01064"
                    ry="1.01064"
                    fill="#999999"
                />
                <ellipse
                    cx="16.8407"
                    cy="11.9468"
                    rx="1.01064"
                    ry="1.01064"
                    fill="#999999"
                />
                <ellipse
                    cx="15.5639"
                    cy="8.43618"
                    rx="1.01064"
                    ry="1.01064"
                    fill="#999999"
                />
                <ellipse
                    cx="12.0536"
                    cy="7.05324"
                    rx="1.01064"
                    ry="1.01064"
                    fill="#999999"
                />
                <ellipse
                    cx="8.64931"
                    cy="8.43618"
                    rx="1.01064"
                    ry="1.01064"
                    fill="#999999"
                />
                <ellipse
                    cx="7.26601"
                    cy="11.9468"
                    rx="1.01064"
                    ry="1.01064"
                    fill="#999999"
                />
                <ellipse
                    cx="8.64931"
                    cy="15.3511"
                    rx="1.01064"
                    ry="1.01064"
                    fill="#999999"
                />
                <circle cx="18.9683" cy="18.8617" r="3.24468" fill="#999999" />
                <ellipse
                    cx="21.8406"
                    cy="11.9468"
                    rx="3.24468"
                    ry="3.24468"
                    fill="#999999"
                />
                <ellipse
                    cx="18.9683"
                    cy="5.03191"
                    rx="3.24468"
                    ry="3.24468"
                    fill="#999999"
                />
                <ellipse
                    cx="12.0533"
                    cy="2.1596"
                    rx="3.24468"
                    ry="3.24468"
                    fill="#999999"
                />
                <ellipse
                    cx="5.13872"
                    cy="5.03191"
                    rx="3.24468"
                    ry="3.24468"
                    fill="#999999"
                />
                <ellipse
                    cx="2.26617"
                    cy="11.9468"
                    rx="3.24468"
                    ry="3.24468"
                    fill="#999999"
                />
                <ellipse
                    cx="5.13872"
                    cy="18.8617"
                    rx="3.24468"
                    ry="3.24468"
                    fill="#999999"
                />
            </g>
            <defs>
                <clipPath id="clip0_2614_32291">
                    <rect
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="10"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
