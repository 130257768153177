import React from 'react';
import {
    LinearProgress,
    LinearProgressWrapper,
    ProgressWrapper,
} from '../../../../styled-components/titulation/titulation';

const ProgressUploadFile = (props) => {
    const { uploadProgress } = props;

    return (
        <ProgressWrapper>
            <LinearProgressWrapper>
                <LinearProgress porcentaje={uploadProgress} />
            </LinearProgressWrapper>
            <p>{uploadProgress}%</p>
        </ProgressWrapper>
    );
};

export default ProgressUploadFile;
