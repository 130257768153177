import React from 'react';
import styled from 'styled-components';
import noSelectedChat from '../../../../assets/media/chat/no-selected-chat.png';
import LazyImg from '../../../../components/common/LazyImg';
import { Text } from '../../../../components/common/Texts';

const UnselectedView = () => {
    return (
        <Wrapper>
            <LazyImg
                src={noSelectedChat}
                backgroundColor="#ffffff"
                width="320px"
                height="320px"
            />
            <InfoContainer>
                <Text fontSize="16px">
                    Aquí encontrarás tus conversaciones con otros estudiantes de
                    la comunidad ADEN.
                </Text>
            </InfoContainer>
        </Wrapper>
    );
};

export default UnselectedView;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    height: 100%;
`;

const InfoContainer = styled.div`
    text-align: center;
    max-width: 420px;
`;
