import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Components
import GenericError from '../../../common/GenericError';
import { Text } from '../../../common/Texts';
import Documentation from '../../../ui/organisms/titulation/Documentation';
import { Icon } from '@iconify/react';
// import AdenBot from '../../../../assets/media/aden/AdenBot.png';
import DegreeTrackingModal from '../../../ui/organisms/titulation/DegreeTrackingModal';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { documentsActions } from '../../../../redux/actions';

const DocumentationTemplate = (props) => {
    const { handleSetStateDocumentation } = props;

    // REDUX
    const dispatch = useDispatch();
    const { documents, errorList } = useSelector((state) => state.documents);

    // STATES
    const [statusLog, setStatusLog] = useState('');
    const [documentState, setDocumentState] = useState(documents);

    // ============ tracking
    const [openDegreeTrackingModal, setOpenDegreeTrackingModal] =
        useState(false);
    const [trackingToView, setTrackingToView] = useState(false);

    // Effect
    useEffect(() => {
        if (documents === null || documents === undefined) {
            dispatch(documentsActions.getDocumentsRequest());
        }
        if (documents !== null && documents !== undefined) {
            countDocumentation(documents);
            setDocumentState(documents);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documents]);

    // Function
    const countDocumentation = (documents) => {
        documents.map((doc) => {
            if (doc.estado === 'Pendiente') {
                handleSetStateDocumentation(false);
                return false;
            }
            handleSetStateDocumentation(true);
            return true;
        });
    };

    const handleOpenDegreeTrackingModal = (data) => {
        setTrackingToView(data);
        setOpenDegreeTrackingModal(true);
    };

    const handleCloseDegreeTrackingModal = () => {
        setOpenDegreeTrackingModal(false);
    };

    if (!!errorList)
        return (
            <GenericError
                reloadFn={() =>
                    dispatch(documentsActions.getDocumentsRequest())
                }
            />
        );

    return (
        <>
            <DocWrapper>
                <ContainerWrapper>
                    <Text
                        color="#333333"
                        fontWeight="700"
                        fontSize="1.2rem"
                        lineHeight="42px"
                    >
                        Legajo digital
                    </Text>

                    <Text color="#1D1D18" fontWeight="400" fontSize="1rem">
                        En este apartado, deberás presentar cada uno de los
                        documentos requeridos para la inscripción formal de la
                        maestría.
                    </Text>

                    <Text
                        color="#1D1D18"
                        fontWeight="700"
                        fontSize="1rem"
                        lineHeight="1.5rem"
                    >
                        Ten en cuenta que, de no cumplir con la presentación de
                        todos los documentos para formalizar tu inscripción, no
                        podrás empezar a cursar tu CUARTA ASIGNATURA Y TU
                        CURSADO SERÁ BLOQUEADO.
                    </Text>

                    <Text
                        color="#1D1D18"
                        fontWeight="400"
                        fontSize="1rem"
                        lineHeight="1.5rem"
                    >
                        Para más información contacta a tu{' '}
                        {<strong>Asesor de documentación.</strong>}
                    </Text>
                    <RowAi>
                        <InfoCard>
                            <Icon
                                icon="akar-icons:info"
                                width="32"
                                height="32"
                            />
                            <div>
                                <Text
                                    color="#222222"
                                    fontWeight="500"
                                    fontSize="1rem"
                                    lineHeight="1.5rem"
                                >
                                    Formatos de archivo aceptados: {''}
                                    {<strong>PDF a color</strong>}
                                </Text>

                                <Text
                                    color="#222222"
                                    fontWeight="500"
                                    fontSize="1rem"
                                    lineHeight="1.5rem"
                                >
                                    Tamaño máximo: {''}
                                    {<strong>10MB por archivo</strong>}
                                </Text>
                            </div>
                        </InfoCard>
                        {/* <ButtonIA>
                            <img src={AdenBot} alt="AdenBot" />
                        </ButtonIA> */}
                    </RowAi>

                    {documentState && (
                        <Documentation
                            handleSetStateDocumentation={
                                handleSetStateDocumentation
                            }
                            handleOpenDegreeTrackingModal={
                                handleOpenDegreeTrackingModal
                            }
                            documents={documentState}
                            setStatusLog={setStatusLog}
                        />
                    )}
                </ContainerWrapper>
                {openDegreeTrackingModal && (
                    <DegreeTrackingModal
                        open={openDegreeTrackingModal}
                        handleCloseModal={handleCloseDegreeTrackingModal}
                        tracking={trackingToView}
                    />
                )}
            </DocWrapper>
        </>
    );
};

export default DocumentationTemplate;

const DocWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* @media (max-width: 1080px) {
    display: flex;
    flex-direction: column-reverse;
  } */
`;

const Container = styled.div`
    padding: 1rem;
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px;
`;

const ContainerWrapper = styled(Container)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
`;

const RowAi = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    position: relative;
`;

const InfoCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    border: 1px solid #e0bd28;
    border-radius: 20px;
    background-color: #f9f9f9;
    padding: 1rem;
    svg {
        color: #e0bd28;
    }
`;

// const ButtonIA = styled.button`
//     position: absolute;
//     cursor: pointer;
//     right: 0;
//     top: 0;
//     /* bottom: 6rem; */
//     width: 57px;
//     height: 57px;
//     background: linear-gradient(180deg, #e51a1a 0%, #b31d15 100%);
//     border-radius: 50%;

//     img {
//         margin-top: -4px;
//         margin-left: -8px;
//         transition: all 0.3s ease-in-out; /* Ensure transition is applied here */
//         scale: 0.7;
//     }

//     span {
//         display: none;
//     }

//     :hover {
//         img {
//             scale: 1;
//         }
//     }

//     :disabled {
//         background: #e5e5e5;
//         cursor: not-allowed;

//         img {
//             filter: grayscale(1);
//         }

//         :hover {
//             img {
//                 scale: 0.7;
//             }
//             span {
//                 display: block;
//             }
//         }
//     }
// `;
