import axios from 'axios';

export async function getMyReferred(crm_partner_id) {
    const URL = `${process.env.REACT_APP_NEW_CRM}/v1/referrals/leads/${crm_partner_id}`;

    try {
        const response = await axios.get(URL);
        return response.data;
    } catch (error) {
        return { error };
    }
}
