import { Modal } from '@mui/material';
import { Icon } from '@iconify/react';
import { Text } from '../../../common/Texts';
import {
    CloseButton,
    DividerTitulationModal,
    FilledButton,
    Image,
    WrapperModal,
} from '../../../../styled-components';
// import ExampleDocumentCV from '../../../../assets/media/titulations/Ejemplo_cv.png';

const OnlyViewExampleDocumentsModal = (props) => {
    const { open, handleCloseModal, title, url } = props;

    return (
        <Modal open={open} onClose={handleCloseModal}>
            <WrapperModal
                widthModal="550px"
                maxHeightModal="80vh"
                gapModal="1.5rem"
                topModal="50%"
                leftModal="50%"
            >
                <CloseButton onClick={handleCloseModal}>
                    <Icon icon="iconoir:cancel" width="24px" height="24px" />
                </CloseButton>
                <Text
                    fontSize="24px"
                    fontWeight={700}
                    color="#B31D15"
                    textAlign="center"
                >
                    Ejemplo de {title}
                </Text>
                <DividerTitulationModal />
                <Image src={url} alt={title} height={'auto'} />
                <FilledButton onClick={handleCloseModal}>
                    Entendido
                </FilledButton>
            </WrapperModal>
        </Modal>
    );
};

export default OnlyViewExampleDocumentsModal;
