import * as encode from 'nodejs-base64-encode';
import styled from 'styled-components';

// Components
import FontAwesomeIcon from '../../../../components/common/FontAwesomeIcon';
import CommonButton from '../../../../components/common/CommonButton';

// Assets
import MockupImg from '../../../../assets/media/aden/mockup.png';
import { useSelector } from 'react-redux';

const CardUpdatedAssignment = (props) => {
    const { assignment } = props;

    const hash = encode.encode(assignment?.id + '', 'base64');

    const user = useSelector((state) => state.auth.user);

    const handleClickGoToAssignment = () => {
        window.location.href = `/asignatura-actualizada/${hash}`;
    };

    const comportamientoInfo = {
        partner_id: user.partner_id,
        partner_name: user.name,
        asignatura_nueva_id: assignment?.id,
    };

    return (
        <Container>
            <img
                src={
                    !assignment?.url_image.includes('localhost')
                        ? assignment?.url_image
                        : MockupImg
                }
                alt={assignment?.name}
            />
            <Chip>
                <FontAwesomeIcon icon="fa-light fa-circle-check" size={24} />
                ¡Versión actualizada!
            </Chip>
            <Body>
                {/* <ProgressWrapper>
                    <LinearProgressWrapper>
                        <LinearProgress
                            porcentaje={assignment?.progress ?? 0}
                        />
                    </LinearProgressWrapper>
                    <Percentage>
                        {Math.ceil(assignment?.progress ?? 0)}%
                    </Percentage>
                </ProgressWrapper> */}
                <h1>{assignment?.name}</h1>
            </Body>
            <Footer>
                <CommonButton
                    label="Iniciar"
                    variant="filled"
                    onClick={handleClickGoToAssignment}
                    // Event detail
                    data-behaviour-action={'axv_asignatura_ver_nueva_version'}
                    // Event detail
                    data-behaviour-detail={JSON.stringify(comportamientoInfo)}
                />
            </Footer>
        </Container>
    );
};

export default CardUpdatedAssignment;

const Container = styled.div`
    position: relative;
    width: 320px;
    height: 340px;
    border-radius: 20px;
    border: 1px solid #ffbc00;
    box-shadow:
        0px 3px 6px 0px #0000003b,
        0px 3px 6px 0px #00000029;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 8px;

    img {
        width: 100%;
        height: 140px;
        border-radius: 20px 20px 0 0;
    }

    h1 {
        font-size: 16px;
        color: #222222;
        padding: 0 1rem 1rem 1rem;
    }
`;

const Chip = styled.div`
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: fit-content;
    padding: 8px 16px 8px 8px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: #ffbc00;
    color: #222;
    font-size: 12px;
    font-weight: 600;
`;

const Body = styled.div`
    height: 40%;

    h1 {
        margin-top: 0.7rem;
    }
`;

/* const ProgressWrapper = styled.div`
    width: calc(100% - 2rem);
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
    margin: 1rem 1rem;

    @media (width < 768px) {
        width: 100%;
    }
`;

const LinearProgressWrapper = styled.div`
    border-radius: 50px;
    width: 100%;
    height: 7px;
    background-color: #eaeaea;
`;

const LinearProgress = styled.div`
    border-radius: 50px;
    width: ${(props) => `${props.porcentaje}%`};
    height: 7px;
    background-color: #ffbc00;
`;

const Percentage = styled.span`
    font-size: 12px;
    color: #222222;
    flex-wrap: wrap;
`; */

const Footer = styled.div`
    width: calc(100% - 2rem);
    height: 72px;
    background-color: #f1f1f1;
    border-radius: 0 0 20px 20px;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0 1rem;
`;
