import styled from 'styled-components';
import { useState } from 'react';

// Components
import FontAwesomeIcon from '../../../../components/common/FontAwesomeIcon';
import CommonButton from '../../../../components/common/CommonButton';
import LazyImg from '../../../../components/common/LazyImg';
import CommunityModal from './CommunityModal';
import { toTitleCase } from '../../../../helpers/titleCase.helper';

const CommunityCard = (props) => {
    const { user, createRoom } = props;

    const [openModal, setOpenModal] = useState(false);

    return (
        <Container>
            <CommunityModal
                openModal={openModal}
                handleCloseModal={() => setOpenModal(false)}
                user={user}
            />
            {/* <MoreInfoButton onClick={() => setOpenModal(true)}>
                <FontAwesomeIcon icon="fa-regular fa-plus" size={24} />
            </MoreInfoButton> */}
            <LazyImg
                src={user.profilePhoto}
                alt={'imagen'}
                borderRadius="50%"
                width="120px"
                height="120px"
            />
            <h2>{toTitleCase(user.name)}</h2>
            {/* <p>
                <i>Licenciado en administración de empresas.</i>
            </p>
            <label>
                <FontAwesomeIcon icon="fa-light fa-location-dot" size={24} />
                Argentina
            </label> */}
            {/* <CommonButton
                variant="outlined"
                label="Ver en LinkedIn"
                onClick={() => {}}
                Icon={() => (
                    <div>
                        <FontAwesomeIcon
                            icon="fa-brands fa-linkedin"
                            size={24}
                        />
                    </div>
                )}
            /> */}
            <SeeMoreButtonContainer>
                <CommonButton
                    variant="text"
                    label="Ver más"
                    onClick={() => setOpenModal(true)}
                />
            </SeeMoreButtonContainer>
            <CommonButton
                variant="filled"
                label="Iniciar chat"
                onClick={() => createRoom(user)}
                Icon={() => (
                    <div>
                        <FontAwesomeIcon
                            icon="fa-light fa-message-lines"
                            size={24}
                        />
                    </div>
                )}
            />
        </Container>
    );
};

export default CommunityCard;

const Container = styled.div`
    position: relative;
    width: calc(100% - 2rem);
    min-height: 400px;
    height: calc(100% - 2rem);
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
    border: 1px solid #a8a8a8;
    border-radius: 30px;

    h2 {
        font-size: 20px;
        font-weight: 700;
        color: #b31d15;
        text-align: center;
    }

    p {
        font-size: 14px;
        font-weight: 600;
        color: #222;
        text-align: center;
    }

    label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    @media (width < 1068px) {
        min-height: 0;
        height: fit-content;

        p {
            display: none;
        }

        label {
            display: none;
        }
    }
`;

const MoreInfoButton = styled.button`
    width: 48px;
    height: 48px;
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: #dcdcdc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;

    &:disabled {
        cursor: not-allowed;
    }

    &:hover {
        box-shadow:
            0px 4px 4px -2px #18274b14,
            0px 2px 4px -2px #18274b1f;
        background-color: #c4c4c4;
    }

    @media (width < 1068px) {
        display: none;
    }
`;

const SeeMoreButtonContainer = styled.div`
    display: none;

    @media (width < 1068px) {
        display: flex;
    }
`;
