import React, { useState } from 'react';
import { Modal } from '@mui/material';
import { Icon } from '@iconify/react';
import OnlyViewExampleDocumentsModal from './OnlyViewExampleDocumentsModal';

import {
    TextButtonWhitIcon,
    CloseButton,
    DividerTitulationModal,
    ListDocumentRequirements,
    WrapperModal,
} from '../../../../styled-components';
import { Text } from '../../../common/Texts';
import WithoutFileModal from './WithoutFileModal';
import WithoutDetailsModal from './WithoutDetailsModal';

const DocumentRequirementsModal = (props) => {
    const {
        open,
        handleCloseModal,
        title,
        list,
        type,
        exampleUrl,
        downloadUrl,
    } = props;

    //  ========= MODALS STATES =========
    const [openDisplayModal, setOpenDisplayModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);

    function transformToDownloadableUrl(url) {
        const match = url.match(/\/d\/([a-zA-Z0-9_-]+)\//);
        if (match && match[1]) {
            const fileId = match[1];
            return `https://drive.google.com/uc?export=download&id=${fileId}`;
        }
        return null;
    }

    const handleClickView = () => {
        if (
            exampleUrl === null ||
            exampleUrl === undefined ||
            exampleUrl === false ||
            exampleUrl === ''
        ) {
            setOpenErrorModal(true);
        } else {
            setOpenDisplayModal(true);
        }
    };

    const handleDownload = () => {
        if (
            downloadUrl === null ||
            downloadUrl === undefined ||
            downloadUrl === false ||
            downloadUrl === ''
        ) {
            setOpenErrorModal(true);
        } else {
            generatePdf(transformToDownloadableUrl(downloadUrl), title);
        }
    };

    const generatePdf = (file, namePdf) => {
        const link = document.createElement('a');
        link.href = file;
        link.download = namePdf;
        link.click();
    };

    return (
        <>
            <Modal open={open} onClose={handleCloseModal}>
                <WrapperModal
                    widthModal="550px"
                    maxHeightModal="80vh"
                    gapModal="1.5rem"
                    topModal="50%"
                    leftModal="50%"
                >
                    <CloseButton onClick={handleCloseModal}>
                        <Icon
                            icon="iconoir:cancel"
                            width="24px"
                            height="24px"
                        />
                    </CloseButton>
                    <Text
                        fontSize="24px"
                        fontWeight={700}
                        color="#B31D15"
                        textAlign="left"
                    >
                        {title}
                    </Text>
                    <DividerTitulationModal />
                    {type.toLowerCase().match(/ficha|reglamento|formulario/) ? (
                        <>
                            <Text
                                fontSize="16px"
                                fontWeight={600}
                                color="#616161"
                                textAlign="left"
                            >
                                DESCARGAS
                            </Text>
                            <TextButtonWhitIcon onClick={handleDownload}>
                                <Icon
                                    icon="mdi-light:paperclip"
                                    width="24"
                                    height="24"
                                />
                                {title}
                            </TextButtonWhitIcon>
                        </>
                    ) : (
                        <TextButtonWhitIcon onClick={handleClickView}>
                            <Icon icon="fa6-solid:eye" width="24" height="24" />
                            Ver ejemplo{' '}
                        </TextButtonWhitIcon>
                    )}

                    <Text
                        fontSize="16px"
                        fontWeight={600}
                        color="#616161"
                        textAlign="left"
                    >
                        REQUERIMIENTOS
                    </Text>
                    {/* cuando solo cargan imagen o url sin descripción pega un salto de linea, total 11 caracteres  */}
                    {list.length <= 12 ? (
                        <WithoutDetailsModal />
                    ) : (
                        <ListDocumentRequirements
                            dangerouslySetInnerHTML={{
                                __html: list,
                            }}
                        />
                    )}
                </WrapperModal>
            </Modal>
            {openDisplayModal && (
                <OnlyViewExampleDocumentsModal
                    open={openDisplayModal}
                    handleCloseModal={() => setOpenDisplayModal(false)}
                    title={title}
                    url={exampleUrl}
                />
            )}
            {openErrorModal && (
                <WithoutFileModal
                    open={openErrorModal}
                    handleCloseModal={() => setOpenErrorModal(false)}
                />
            )}
        </>
    );
};

export default DocumentRequirementsModal;
