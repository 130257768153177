import axios from 'axios';

export async function getSedes() {
    const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/documentacion_estudiantes/sedes_acropolis_lab`;

    try {
        const response = await axios.get(URL);
        return response.data.sedes;
    } catch (error) {
        return { error };
    }
}

export async function getCountries() {
    const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/miscs/countries`;

    try {
        const response = await axios.get(URL);
        return response.data.response_data;
    } catch (error) {
        return { error };
    }
}

export async function upDateTitulation(data) {
    const dataEnvio = { ...data, domicilio_confirmado: true };
    // data.id_titulacion = "1233123";
    try {
        const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/documentacion_estudiantes/destino_diploma`;
        const response = await axios.patch(URL, dataEnvio);
        return response.data;
    } catch (error) {
        return { error };
    }
}

export const getDocumentById = async (id) => {
    const URL = `${process.env.REACT_APP_SISAPI}/documento/${id}`;
    try {
        const response = await axios.get(URL);
        return response.data.link;
    } catch (error) {
        return error;
    }
};
