import React, { forwardRef, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

// Components
import SkeletonLoading from '../../../../components/common/SkeletonLoading';
import { Text } from '../../../../components/common/Texts';
import ChatRoomCard from './ChatRoomCard';
import Search from '../common/Search';

// Material UI
import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';

// Context
import { ChatContext } from '../../../../contexts/chat/ChatProvider';

// Helpers
import { socketIO } from '../../../../helpers/connectionSocket.helper';
import { fakeArrayItems } from '../../../../utils/arrays/array.utils';
import { roomAdapter } from '../../adapters';

const AsideChatList = (props, ref) => {
    const loadingelements = fakeArrayItems(7);

    const { user, rooms, setRooms, latestMessage } = useContext(ChatContext);

    const [filteredRooms, setFilteredRooms] = useState(null);

    useEffect(() => {
        socketIO.emit('get-rooms', { user_id: user?.id });
        socketIO.on('get-rooms', (rooms) => {
            if (!!rooms && !!rooms.length) {
                const adaptedRooms = rooms.map((room) => roomAdapter(room));
                setRooms(sortRoomsByLatestMessage(adaptedRooms));
            }
        });
        socketIO.on('userConnected', () => {
            socketIO.emit('get-rooms', { user_id: user?.id });
            socketIO.on('get-rooms', (rooms) => {
                if (!!rooms && !!rooms.length) {
                    const adaptedRooms = rooms.map((room) => roomAdapter(room));
                    setRooms((prev) =>
                        sortRoomsByLatestMessage([...adaptedRooms])
                    );
                }
            });
        });
    }, [user?.id]);

    useEffect(() => {
        setRooms(sortRoomsByLatestMessage(rooms) ?? []);
    }, [rooms]);

    useEffect(() => {
        // El problema es que al llegar acá, rooms viene desactualizado, o sea, para cuando se re-ejecuta el useEffect
        // porque latestMessage cambia, rooms no ha sido actualizado aún.
        setTimeout(() => {
            // console.info('rooms 3000 ms later', rooms);
        }, 3000);
    }, [latestMessage]);

    const sortRoomsByLatestMessage = (rooms) => {
        const sortedRooms = rooms?.sort((a, b) => {
            const aDate = new Date(a.latestMessage.createdAt);
            const bDate = new Date(b.latestMessage.createdAt);

            // if (isNaN(bDate)) {
            //     return -1;
            // }

            if (aDate > bDate) {
                return -1;
            }

            if (aDate < bDate) {
                return 1;
            }

            return 0;
            // return bDate - aDate;
        });
        return sortedRooms;
    };

    /**
     * Schwartznian Transform
     * @param {*} data
     */
    function sortByDate(rooms) {
        const sortedRooms = rooms
            ?.map((room) => [+new Date(room.latestMessage.createdAt), room])
            ?.sort(([a], [b]) => b - a)
            ?.map(([, room]) => room);
        return sortedRooms;
    }

    const handleFilter = (event) => {
        const filtered = rooms?.filter((room) => {
            return room.name
                .toLocaleLowerCase()
                .includes(event.target.value.toLocaleLowerCase());
        });

        setFilteredRooms(filtered);
    };

    if (!rooms?.length || !rooms) {
        return (
            <Container>
                <Header>
                    <Text fontSize="20px" fontWeight="700">
                        Chat
                    </Text>
                </Header>
                <Body>
                    <SkeletonWrapper>
                        {loadingelements.map((_, index) => (
                            <SkeletonLoading
                                height="70px"
                                radius="10px"
                                key={index}
                            />
                        ))}
                    </SkeletonWrapper>
                </Body>
            </Container>
        );
    }

    return (
        <Container ref={ref}>
            <Header>
                <Text fontSize="20px" fontWeight="700">
                    Chat
                </Text>
                <InputsWrapper>
                    <SearchWrapper>
                        <Search
                            handleChange={handleFilter}
                            label="Buscar chat"
                        />
                    </SearchWrapper>
                    {/* <AddButton variant="contained" color="primary">
                        <Add />
                    </AddButton> */}
                </InputsWrapper>
            </Header>
            <Body>
                {(filteredRooms ?? rooms).map((room) => (
                    <ChatRoomCard key={room.id} room={room} userId={room.id} />
                ))}
            </Body>
        </Container>
    );
};

export default forwardRef(AsideChatList);

const SkeletonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    margin-top: 1rem;
`;

const Container = styled.section`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 2rem;
    border-radius: 20px;
    height: 100%;
    background-color: #ffffff;
    box-shadow:
        0px 0px 10px -6px rgba(0, 0, 0, 0.25),
        0px 12px 24px -15px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
`;

const InputsWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
`;

const AddButton = styled(Button)`
    border-radius: 50% !important;
    width: 40px;
    height: 40px;

    &.MuiButton-root {
        padding: 1rem;
        min-width: 0 !important;
    }
`;

const SearchWrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
`;

const Body = styled.div`
    box-sizing: border-box;
    padding-right: 1rem;
    height: 100%;
    overflow: auto;
`;

const MessageWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
`;
