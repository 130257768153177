export const socialNetworks = [
    {
        icon: 'mdi:linkedin',
        url: 'https://www.linkedin.com/school/adenbs/',
    },
    {
        icon: 'mdi:youtube',
        url: 'https://www.youtube.com/user/ADENBusinessSchool',
    },
    {
        icon: 'mdi:facebook',
        url: 'https://www.facebook.com/ADENBusinessSchool',
    },
    {
        icon: 'line-md:twitter-x',
        url: 'https://twitter.com/ADENBS',
    },
    {
        icon: 'mdi:instagram',
        url: 'https://www.instagram.com/adenbs/',
    },
];

export const aboutAden = [
    {
        label: 'Red educativa global',
        url: 'https://www.aden.org/sobre-aden/',
    },
    {
        label: 'Sedes',
        url: 'https://www.aden.org/sedes-aden/',
    },
    {
        label: 'Faculty',
        url: 'https://www.aden.org/faculty-aden/',
    },
    {
        label: 'Acceso campus virtual',
        url: 'https://app.aden.org/campus',
    },
    {
        label: 'Trabaja con nosotros',
        url: 'https://ar.computrabajo.com/aden',
    },
    {
        label: 'Políticas de privacidad',
        url: 'https://www.aden.org/privacy-policy/',
    },
    {
        label: 'Términos y condiciones',
        url: 'https://www.aden.org/terminos-y-condiciones/',
    },
    {
        label: 'Información pública',
        url: 'https://www.aden.org/informacion-publica/',
    },
];

export const academicOffering = [
    {
        label: 'Posgrados',
        url: 'https://www.aden.org/posgrados/',
    },
    {
        label: 'Grados',
        url: 'https://www.aden.org/grados/',
    },
    {
        label: 'Formación continua',
        url: 'https://www.aden.org/formacion-continua/',
    },
];

export const corporateSolutions = [
    {
        label: 'Corporate solutions',
        url: 'https://www.aden.org/corporate-solutions/',
    },
];

export const moreOfAden = [
    {
        label: 'Business Magazine',
        url: 'https://www.aden.org/business-magazine/',
    },
];
