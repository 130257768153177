import styled from 'styled-components';
import CuriosityPana from '../../../../../src/assets/media/aden/error.png';
import CommonButton from '../../../../components/common/CommonButton';
import FontAwesomeIcon from '../../../../components/common/FontAwesomeIcon';

const UsersError = (props) => {
    const { refetch } = props;

    return (
        <Container>
            <img src={CuriosityPana} alt="Curiosity Pana" width={350} />
            <h1>¡Ups! ocurrió un error</h1>
            <h3>Por favor, refresca esta página.</h3>
            <CommonButton
                variant="filled"
                onClick={refetch}
                label="Volver a cargar"
                Icon={() => (
                    <div>
                        <FontAwesomeIcon
                            icon="fa-light fa-arrow-rotate-right"
                            size={24}
                        />
                    </div>
                )}
            />
        </Container>
    );
};

export default UsersError;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: #fff;
    box-shadow:
        0px 12px 24px -15px #0000001a,
        0px 0px 10px -6px #00000040;
    padding: 2rem 0;
    border-radius: 30px;

    h1 {
        font-size: 24px;
        font-weight: 700;
        color: #b31d15;
    }

    h3 {
        font-size: 16px;
        font-weight: 400;
        color: #222;
    }
`;
