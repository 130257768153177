import { Card } from "@mui/material";
import React from "react";
import styled from "styled-components";
import DashboardReferred from "./panel-referred/DashboardReferred";

const PanelReferred = (props) => {
    const { myReferred } = props;

    return (
        <CardFake>
            <h2>Mis referidos</h2>
            <Body>
                <DashboardReferred myReferred={myReferred}/>
            </Body>
        </CardFake>
    );
};

export default PanelReferred;

const CardFake = styled(Card)`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 2rem;
    border-radius: 20px;
    width: calc(100% - 4rem);
    box-shadow: 0px 3px 6px 0px #0000003B, 0px 3px 6px 0px #00000029;
    background-color: #fff;
    padding: 2rem;

    h2 {
        font-size: 20px;
        font-weight: 600;
        color: #616161;
    }
`;

const Body = styled.div`
    width: 100%;
`;
