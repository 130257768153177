import styled from 'styled-components';
import { Icon } from '@iconify/react/dist/iconify.js';

// Material UI
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { Card } from '@mui/material';

const CardReferred = (props) => {
    const { values } = props;

    const referred = values;

    return (
        <CardFake>
            <section>
                <Chip type={referred?.stage || 'Abiertas'}>
                    {referred?.stage === 'Ganadas' ? (
                        <DoneIcon />
                    ) : referred?.stage === 'Perdidas' ? (
                        <ClearIcon />
                    ) : (
                        <AutorenewIcon />
                    )}
                    {referred?.stage === 'Ganadas'
                        ? 'Confirmado'
                        : referred?.stage === 'Perdidas'
                          ? 'Rechazado'
                          : 'Solicitado'}
                </Chip>
            </section>
            <Info>
                <label>Datos sobre la persona referida:</label>
                <span>
                    Nombre: <b>{referred?.name || 'Sebastian Maidana'}</b>
                </span>
                <span>
                    Correo electrónico:{' '}
                    <b>{referred?.email || 'sebamaidana@gmail.com'}</b>
                </span>
                <span>
                    Fecha de solicitud:{' '}
                    <b>
                        {new Date(referred?.create_date).toLocaleDateString(
                            'es-ES'
                        ) || '-'}
                    </b>
                </span>
            </Info>
        </CardFake>
    );
};

export default CardReferred;

const CardFake = styled(Card)`
    width: calc(100% - 3rem);
    height: fit-content;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-radius: 20px;
    border: 1px solid #a8a8a8;
    box-shadow:
        0px 12px 24px -15px #0000001a,
        0px 0px 10px -6px #00000040;

    section {
        width: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
    }
`;
const Chip = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    width: fit-content;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    color: #fff;
    font-size: 0.85rem;
    color: ${(props) =>
        props.type === 'perdida'
            ? '#B75E26'
            : props.type === 'Ganadas'
              ? '#1E8065'
              : '#C29F43'};
    background-color: ${(props) =>
        props.type === 'perdida'
            ? '#FBF0EA'
            : props.type === 'Ganadas'
              ? '#F7FDFB'
              : '#F9F5EC'};
    border: 1px solid
        ${(props) =>
            props.type === 'perdida'
                ? '#B75E26'
                : props.type === 'Ganadas'
                  ? '#1E8065'
                  : '#C29F43'};
    box-shadow:
        0px 8px 24px -4px #18274b14,
        0px 6px 12px -6px #18274b1f;

    svg {
        font-size: 1.2rem;
    }
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;

    label {
        font-size: 1rem;
        font-weight: 600;
        color: #b31d15;
    }

    h3 {
        color: #000;
        font-size: 1.3rem;
    }

    span {
        color: #616161;
        font-size: 1rem;
        display: flex;
        gap: 8px;
    }
`;
