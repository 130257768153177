import { styled } from '@mui/material/styles';
import { TableSortLabel } from '@mui/material';
import { Icon } from '@iconify/react';

const CustomIcon = styled((props) => (
    <Icon {...props} icon="flowbite:caret-down-solid" />
))`
    color: ${({ active }) =>
        active ? '#222222 !important' : '#a8a8a8 !important'};
    font-size: 24px;
`;

const StyledTableSortLabel = styled(TableSortLabel)`
    & .MuiTableSortLabel-icon {
        opacity: 1 !important;
        visibility: visible !important;
    }
    ${({ disabled }) =>
        disabled &&
        `
      display: none
    `}
`;

const MyTableSortLabel = ({ headCell, orderBy, order, createSortHandler }) => {
    const isActive = orderBy === headCell.id;
    const isDisabled = headCell.numeric === false;
    return (
        <StyledTableSortLabel
            IconComponent={(props) => (
                <CustomIcon {...props} active={isActive ? 1 : 0} />
            )}
            active={isActive}
            direction={isActive ? order : 'asc'}
            onClick={isDisabled ? undefined : createSortHandler(headCell.id)}
            disabled={isDisabled}
        />
    );
};

export default MyTableSortLabel;
