import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Components
import FontAwesomeIcon from '../../../common/FontAwesomeIcon';
import SimpleLoading from '../../../common/SimpleLoading';
import Text from '../../../common/Text';

// Material UI
import { FmdGood } from '@mui/icons-material';

// Redux
import { getInfoEvents } from '../../../../redux/api/events/events';
import { utilsActions } from '../../../../redux/actions';

// Utils
import { formatDate } from '../../../../utils/dates';
import parse from 'html-react-parser';
import moment from 'moment';

const InfoEventModal = (props) => {
    const { event } = props;

    const dispatch = useDispatch();

    const countries = useSelector((state) => state?.utils?.countries);

    const {
        publication_name,
        crm_id,
        modality,
        description,
        date_begin,
        location,
    } = event;

    const eventDateBegin = formatDate(date_begin, 'MM/DD/YYYY');

    const formattedDate = moment(eventDateBegin).locale('es').format('LL');

    // STATE
    const [infoEvent, setInfoEvent] = useState(null);
    const [speakers, setSpeakers] = useState(null);
    const [country, setCountry] = useState(null);

    useEffect(() => {
        if (infoEvent === null) {
            getInfoEvent();
        }

        !countries && dispatch(utilsActions.getCountriesRequest());

        !country &&
            !countries?.error &&
            setCountry(
                countries?.find((country) => country?.id === location?.contry)
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoEvent, country, countries]);

    useEffect(() => {
        if (infoEvent !== null) {
            if (!!infoEvent.speakers_json) {
                if (speakers === null) {
                    setSpeakers(JSON.parse(infoEvent.speakers_json));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoEvent]);

    //FUNCTIONS
    const getInfoEvent = async () => {
        const response = await getInfoEvents(crm_id);
        if (response.error) {
            console.log('response.error', response.error);
            setInfoEvent([]);
            return;
        }
        setInfoEvent(response);
    };

    const getHourNumber = (hour) => {
        return parseInt(hour.substring(0, 2));
    };

    const getMinuteNumber = (hour) => {
        return hour.substring(3, 5);
    };

    const sanitizerHtml = (html) => {
        const string = 'src="/';
        const regex = new RegExp(string, 'g');
        return html.replace(regex, 'src="');
    };

    // CAMBIA LA HORA SEGÚN LA LOCALIZACIÓN DEL USUARIO
    const ToggleLocationHour = (props) => {
        const { toggler } = props;
        const cases = {
            Argentina: (
                <Text fontSize="11px" color="#616161" component="span">
                    {event?.hour_begin}
                    {' (AR) '}{' '}
                </Text>
            ),
            Panama: (
                <Text fontSize="11px" color="#616161" component="span">
                    {getHourNumber(event?.hour_begin) -
                        2 +
                        ':' +
                        getMinuteNumber(event?.hour_begin)}{' '}
                    {' (PA) '}{' '}
                </Text>
            ),
            'Costa Rica': (
                <Text fontSize="11px" color="#616161" component="span">
                    {getHourNumber(event?.hour_begin) -
                        3 +
                        ':' +
                        getMinuteNumber(event?.hour_begin)}{' '}
                    {' (CR) '}
                </Text>
            ),
        };

        return cases[toggler] || cases['Argentina'];
    };

    return (
        <Container>
            <Header img_url={event?.image_url || '/assets/event-default.jpg'}>
                <WrapperChip>
                    <Chips>
                        {modality === 'physical'
                            ? 'Evento presencial'
                            : 'Evento virtual'}
                    </Chips>
                    <Chips>Sin cargo</Chips>
                </WrapperChip>
            </Header>
            <Body>
                {infoEvent === null ? (
                    <WrapperBody>
                        <SimpleLoading />
                    </WrapperBody>
                ) : (
                    <WrapperBody>
                        <Title>{publication_name}</Title>
                        <InfoWrapper>
                            <InfoBox>
                                <FontAwesomeIcon
                                    icon="fa-light fa-calendar-day"
                                    size={20}
                                    color={'#b31d15'}
                                />
                                <Text
                                    fontSize="16px"
                                    fontWeight="500"
                                    textColor="#222222"
                                >
                                    {formattedDate}
                                </Text>
                            </InfoBox>
                            {!!event?.hour_begin && (
                                <InfoBox>
                                    <FontAwesomeIcon
                                        icon="fa-light fa-clock"
                                        size={20}
                                        color={'#b31d15'}
                                    />
                                    {modality !== 'physical' ? (
                                        <>
                                            <ToggleLocationHour
                                                toggler={'Argentina'}
                                            />
                                            {'|'}
                                            <ToggleLocationHour
                                                toggler={'Panama'}
                                            />
                                            {'|'}
                                            <ToggleLocationHour
                                                toggler={'Costa Rica'}
                                            />
                                        </>
                                    ) : (
                                        <Text
                                            fontSize="16px"
                                            fontWeight="500"
                                            textColor="#222222"
                                        >
                                            {event?.hour_begin} (Hora local)
                                        </Text>
                                    )}
                                </InfoBox>
                            )}
                            {!!country && (
                                <InfoBox>
                                    <FmdGood fontSize="small" />
                                    <Text
                                        fontSize="16px"
                                        fontWeight="500"
                                        textColor="#222222"
                                    >
                                        {location?.street} | {location?.city},{' '}
                                        {country?.name}
                                    </Text>
                                </InfoBox>
                            )}
                        </InfoWrapper>
                        {infoEvent.length !== 0 ? (
                            <>
                                <DescriptionWrapper>
                                    <p>{infoEvent.description}</p>
                                </DescriptionWrapper>
                                <Footer>
                                    {speakers && (
                                        <>
                                            <h3>Disertantes</h3>
                                            {speakers.map((speaker) => (
                                                <p>
                                                    {speaker.name},{' '}
                                                    {speaker.description}
                                                </p>
                                            ))}
                                        </>
                                    )}
                                </Footer>
                            </>
                        ) : description !== '' ? (
                            <DescriptionWrapper>
                                <p>{parse(sanitizerHtml(description))}</p>
                            </DescriptionWrapper>
                        ) : (
                            <h2>No hay información del evento</h2>
                        )}
                    </WrapperBody>
                )}
            </Body>
        </Container>
    );
};

export default InfoEventModal;

const Container = styled.div`
    height: calc(90vh - 80px);
    width: 100%;
`;

const Header = styled.div`
    width: 100%;
    height: 220px;
    background-image: url('./assets/aden/aden-live.png');
    background-image: url(${(p) => p.img_url});
    background-size: cover;
    background-position: center center;
    position: relative;
    border-radius: 20px 20px 0 0;
`;

const WrapperChip = styled.div`
    position: absolute;
    display: flex;
    gap: 0.8rem;
    bottom: 20px;
    left: 20px;
`;

const Chips = styled.div`
    color: white;
    display: flex;
    align-items: center;
    background-color: white;
    color: #b31d15;
    border-radius: 20px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 600;

    svg {
        font-size: 1.2rem;
    }
`;

const Body = styled.div`
    background-color: #fff;
    width: 100%;
    height: 100%;
`;

const WrapperBody = styled.div`
    width: 100%;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: calc(100% - 245px);
    overflow-y: auto;

    ::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: 1350px) {
        width: calc(100% - 3rem);
    }
`;

const Title = styled.h3`
    width: 100%;
    color: #b31d15;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.7rem;

    svg {
        font-size: 1.6rem;
        color: #b31d15;
    }
`;

const InfoBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const DescriptionWrapper = styled.div`
    p {
        font-size: 1rem;
        line-height: 30px;
    }
`;

const Footer = styled.div`
    h3 {
        color: #b31d15;
        font-weight: bold;
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
    }
    p {
        font-size: 1rem;
        line-height: 30px;
    }
`;
