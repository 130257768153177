import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Components
import SimpleLoading from '../../../components/common/SimpleLoading';
import NoResultsFound from '../../../components/common/NoResultsFound';
import FontAwesomeIcon from '../../../components/common/FontAwesomeIcon';

const EventsGrid = (props) => {
    const {
        Card,
        propsValues,
        handleOpenModal,
        gtc,
        handleRemoveItem,
        loading,
        currentTab,
    } = props;

    // STATE
    const [values, setValues] = useState([]);
    const [changeState, setChangeState] = useState(false);
    const [pagesCount, setPagesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;

    const handleNextPage = () => {
        if (currentPage < Math.ceil(propsValues.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleFirstPage = () => {
        setCurrentPage(1);
    };

    const handleLastPage = () => {
        setCurrentPage(Math.ceil(propsValues.length / itemsPerPage));
    };

    useEffect(() => {
        setTimeout(() => {
            setChangeState(false);
        }, 200);
    }, [changeState]);

    useEffect(() => {
        setValues(propsValues);
        setPagesCount(Math.ceil(propsValues?.length / 12));
    }, [propsValues]);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    if (!!loading) {
        return <SimpleLoading />;
    }

    return (
        <>
            {values?.length === 0 || !values ? (
                <NoResultsFound />
            ) : (
                <>
                    {changeState ? (
                        <SimpleLoading />
                    ) : (
                        <>
                            <GridValues gtc={gtc}>
                                {propsValues
                                    ?.slice(startIndex, endIndex)
                                    .map((value, index) => (
                                        <Card
                                            value={value}
                                            contact={value}
                                            key={index}
                                            currentTab={currentTab}
                                            handleOpenModal={handleOpenModal}
                                            handleRemoveItem={handleRemoveItem}
                                        />
                                    ))}
                            </GridValues>
                            <PaginationWrapper>
                                <button onClick={handleFirstPage}>
                                    <FontAwesomeIcon
                                        icon="fa-light fa-arrow-left-to-line"
                                        size={24}
                                        color={'#616161'}
                                    />
                                </button>
                                <button onClick={handlePreviousPage}>
                                    <FontAwesomeIcon
                                        icon="fa-light fa-arrow-left"
                                        size={20}
                                        color={'#616161'}
                                    />
                                </button>
                                <span>
                                    <b>{currentPage}</b> / {pagesCount}
                                </span>
                                <button onClick={handleNextPage}>
                                    <FontAwesomeIcon
                                        icon="fa-light fa-arrow-right"
                                        size={20}
                                        color={'#616161'}
                                    />
                                </button>
                                <button onClick={handleLastPage}>
                                    <FontAwesomeIcon
                                        icon="fa-light fa-arrow-right-to-line"
                                        size={24}
                                        color={'#616161'}
                                    />
                                </button>
                            </PaginationWrapper>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default EventsGrid;

const GridValues = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
`;

const PaginationWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    button {
        cursor: pointer;
    }
`;
