import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Components
import UpdatedProgramSkeleton from './updates/UpdatedProgramSkeleton';
import { getUpdatedPrograms } from '../services/api/updates';
import UpdatedProgram from './updates/UpdatedProgram';

// Assets
import AccesoDePorVidaImg from '../../../assets/media/aden/acceso_de_por_vida.png';
import ChicaSentadaImg from '../../../assets/media/aden/sit-girl.png';

// Hooks
import useFetch from '../../../hooks/useFetch';

const Updates = () => {
    const repoId = useSelector((state) => state.auth.user.repo_id);

    const { data: updatedPrograms, loading } = useFetch({
        asyncFn: () => getUpdatedPrograms(repoId),
    });

    return (
        <Container>
            <section>
                <div>
                    <img src={AccesoDePorVidaImg} alt="Acceso de por vida" />
                    <h1>Acceso de por vida</h1>
                </div>
                <span>
                    <h1>Acceso de por vida</h1>
                    <p>
                        Para que sigas disfrutando
                        <b> acceso ilimitado a ADEN</b>, una vez que finalices
                        tu cursado y egreses, tendrás disponibles las
                        <b> versiones actualizadas </b>de las asignaturas, con
                        <b> contenido renovado </b>para que mantengas tus
                        conocimientos al día.
                    </p>
                </span>
            </section>
            {loading && <UpdatedProgramSkeleton />}
            {updatedPrograms?.length === 0 ? (
                <EmptyContainer>
                    <img src={ChicaSentadaImg} alt="Chica sentada" />
                    <p>
                        Aquí encontrarás las nuevas versiones de las <br />{' '}
                        asignaturas que has finalizado.
                    </p>
                </EmptyContainer>
            ) : (
                <>
                    {updatedPrograms?.map((program, _index) => {
                        if (!program?.subprograms) {
                            return <UpdatedProgram program={program} />;
                        } else {
                            return (
                                <>
                                    {program?.subprograms?.map(
                                        (subprogram, _index) => (
                                            <UpdatedProgram
                                                program={subprogram}
                                            />
                                        )
                                    )}
                                </>
                            );
                        }
                    })}
                </>
            )}
        </Container>
    );
};

export default Updates;

const Container = styled.div`
    width: calc(100% - 56px);
    min-height: 50vh;
    height: 100%;
    background-color: #b31d15;
    box-shadow:
        0px 14px 64px -4px #18274b1f,
        0px 8px 22px -6px #18274b1f;
    border-radius: 38px;
    padding: 2rem 28px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 24px;

    section {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 1rem;

        div {
            h1 {
                display: none;
            }
        }

        img {
            width: 80px;
            height: 80px;
        }

        span {
            height: 80px;
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: start;
        }
    }

    @media (width <= 1080px) {
        margin-top: 2rem;

        section {
            flex-direction: column;
            align-items: start;
            margin-bottom: 1rem;

            div {
                display: flex;
                align-items: center;
                gap: 1rem;

                h1 {
                    display: block;
                    font-size: 24px;
                    color: #fff;
                }
            }
        }

        span {
            h1 {
                display: none;
            }

            p {
                font-size: 16px;

                b {
                    font-size: 16px;
                }
            }
        }
    }
`;

const EmptyContainer = styled.div`
    width: 100%;
    min-height: 500px;
    height: 100%;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;

    p {
        font-size: 20px;
        font-weight: 700;
        color: #222;
        text-align: center;
    }

    img {
        height: 200px;
    }

    @media (width <= 1080px) {
        min-height: 300px;

        img {
            height: 100px;
        }

        p {
            font-size: 16px;
        }
    }
`;
