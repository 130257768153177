import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button, Card, CardContent, Popover } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Text from '../../../common/Text';
import { ChatContext } from '../../../../contexts/chat/ChatProvider';
import NotificationCard from './NotificationCard';

const NotificationsPopover = (props) => {
    const { id, open, onClose, anchorEl, notifications } = props;

    const history = useHistory();

    const { deleteNotification } = useContext(ChatContext);

    const goToChat = () => {
        if (!window.location.href.includes('chat')) {
            history.push('/chat');
        }
    };

    return (
        <CustomPopover
            id={id}
            open={open}
            onClose={onClose}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            container={document.getElementById('parent')}
        >
            <Notification>
                <Header>
                    <Text fontSize="20px" fontWeight="600" color="#ffffff">
                        Chat
                    </Text>
                </Header>
                <Body>
                    <CardsWrapper empty={!notifications.length}>
                        {!notifications.length && (
                            <Text
                                fontSize="18px"
                                fontWeight="600"
                                color="#b31d15"
                            >
                                No tienes notificaciones
                            </Text>
                        )}

                        {notifications?.map((notification) => (
                            <NotificationCard
                                deleteNotification={deleteNotification}
                                notification={notification}
                            />
                        ))}
                    </CardsWrapper>
                </Body>
                <Footer>
                    <Button onClick={goToChat} variant="contained">
                        Ir al chat
                    </Button>
                </Footer>
            </Notification>
        </CustomPopover>
    );
};

export default NotificationsPopover;

const CustomPopover = styled(Popover)`
    box-sizing: border-box;
    .MuiPopover-paper {
        border-radius: 20px !important;
        background-color: none !important;
    }
`;

const Notification = styled(Card)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 450px;
    width: 350px;
    border-radius: 20px;
    background-color: #ffffff;
`;

const Header = styled(CardContent)`
    background-color: #b31d15;
`;

const CardsWrapper = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: ${({ empty }) => (empty ? 'center' : 'none')};
    justify-content: ${({ empty }) => (empty ? 'center' : 'none')};
    height: 100%;
    gap: 0.7rem;
    z-index: 1;
`;

const Body = styled(CardContent)`
    position: relative;
    box-sizing: border-box;
    padding: 0.5rem;
    overflow-y: auto;
    height: 100%;
    background-color: #f9f9f9;
    z-index: 1;
`;

const Footer = styled(CardContent)`
    background-color: #b31d15;
    height: 30px;
    width: 100%;
`;
