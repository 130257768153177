import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import AsideChatList from './AsideChatList';
import ChatRoom from './ChatRoom';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import { ChatContext } from '../../../../contexts/chat/ChatProvider';

const ChatBoard = () => {
    const asideRef = useRef(null);
    const chatRef = useRef(null);

    const { currentRoom, rooms } = useContext(ChatContext);

    const windowWidth = useWindowWidth();

    useEffect(() => {
        if (!!asideRef.current && !!chatRef.current) {
            handleDisplay();
        }
    }, [!!currentRoom, windowWidth, rooms]);

    const handleDisplay = () => {
        if (!rooms) {
            (chatRef.current.style.display = 'none') &&
                (asideRef.current.style.display = 'block');
        }

        if (windowWidth < 780) {
            !!currentRoom
                ? (chatRef.current.style.display = 'flex') &&
                  (asideRef.current.style.display = 'none')
                : (chatRef.current.style.display = 'none') &&
                  (asideRef.current.style.display = 'flex');

            return;
        }
        asideRef.current.style.display = 'flex';
        chatRef.current.style.display = 'flex';
    };

    return (
        <Wrapper>
            <AsideChatList ref={asideRef} />
            <ChatRoom ref={chatRef} />
        </Wrapper>
    );
};

export default ChatBoard;

const Wrapper = styled.section`
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 715px;
    column-gap: 2rem;

    @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr 4fr;
    }

    @media screen and (max-width: 768px) {
        padding: 0 1rem 0 2rem;
        display: flex;
        height: 100%;
        /* .chat-rooms {
            display: none;
        } */
    }
`;
