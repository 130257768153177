import React from 'react';
import { Text } from '../../../common/Texts';
import { ColumnWrapper, Image } from '../../../../styled-components';
import WithoutDetailsImage from '../../../../assets/media/titulations/withoutDetails.png';

const WithoutDetailsModal = () => {
    return (
        <ColumnWrapper>
            <Image width={140} height={140} src={WithoutDetailsImage} />

            <Text
                fontSize="24px"
                fontWeight={700}
                color="#B31D15"
                textAlign="center"
            >
                Error al cargar los requerimientos
            </Text>
            <div>
                <Text
                    fontSize="16px"
                    fontWeight={400}
                    color="#222222"
                    textAlign="center"
                >
                    Hubo un problema al intentar cargar los requerimientos.
                </Text>

                <Text
                    fontSize="16px"
                    fontWeight={700}
                    color="#222222"
                    textAlign="center"
                >
                    Por favor, comunícate con tu asesor de documentación.
                </Text>
            </div>
        </ColumnWrapper>
    );
};

export default WithoutDetailsModal;
