import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// Components
import Carousel, { consts } from 'react-elastic-carousel';
import FontAwesomeIcon from '../../../components/common/FontAwesomeIcon';
import CertificateCard from './CertificateCard';

const MyCertificatesBody = () => {
    const divRef = useRef(null);

    const [containerWidth, setContainerWidth] = useState(0);
    const [count, setCount] = useState(1);

    useEffect(() => {
        if (divRef.current) {
            const divWidth = divRef.current.getBoundingClientRect().width;
            setContainerWidth(divWidth);
        }
    }, []);

    const carouselRef = useRef(null);

    const handleNextArrow = () => {
        carouselRef.current.slideNext();
        setCount((prev) => prev + 1);
    };

    const handlePrevArrow = () => {
        carouselRef.current.slidePrev();
        setCount((prev) => prev - 1);
    };

    const program = {
        segment_program: 'ONE YEAR MBA c/o en Compliance',
        initials: 'PDG',
        type_program: 'ciclo esencial',
        program: 'Programa de Desarrollo Gerencial',
        certificates: [
            {
                title: 'Certificado de asistencia',
                date: '10/10/2021',
                status: 'Pendiente',
            },
            {
                title: 'Certificado de asistencia',
                date: '10/10/2021',
                status: 'Pendiente',
            },
            {
                title: 'Certificado de asistencia',
                date: '10/10/2021',
                status: 'Pendiente',
            },
            {
                title: 'Certificado de asistencia',
                date: '10/10/2021',
                status: 'Pendiente',
            },
            {
                title: 'Certificado de asistencia',
                date: '10/10/2021',
                status: 'Pendiente',
            },
            {
                title: 'Certificado de asistencia',
                date: '10/10/2021',
                status: 'Pendiente',
            },
            {
                title: 'Certificado de asistencia',
                date: '10/10/2021',
                status: 'Pendiente',
            },
            {
                title: 'Certificado de asistencia',
                date: '10/10/2021',
                status: 'Pendiente',
            },
            {
                title: 'Certificado de asistencia',
                date: '10/10/2021',
                status: 'Pendiente',
            },
        ],
    };

    return (
        <>
            {Array(3)
                .fill(null)
                .map((_, index) => {
                    return (
                        <ProgramWrapper ref={divRef}>
                            <h1>{program?.segment_program}</h1>
                            <section>
                                <InitialsCircle>
                                    {program?.initials}
                                </InitialsCircle>
                                <ProgramTitle>
                                    <p>
                                        {program?.type_program?.toUpperCase()}
                                    </p>
                                    <h2>{program?.program}</h2>
                                </ProgramTitle>
                            </section>
                            <FakeCarousel
                                length={program?.certificates?.length}
                                ref={carouselRef}
                                itemPadding={[8, 0]}
                                itemPosition={consts.START}
                                itemsToShow={containerWidth / 380}
                                itemsToScroll={1}
                            >
                                {program?.certificates?.map(
                                    (certificate, index) => (
                                        <CertificateCard
                                            certificate={certificate}
                                        />
                                    )
                                )}
                            </FakeCarousel>
                            <SliderArrows>
                                <p>
                                    <b>0{count}</b> / 0
                                    {program?.certificates?.length}
                                </p>
                                <button
                                    onClick={handlePrevArrow}
                                    disabled={count === 1}
                                >
                                    <FontAwesomeIcon
                                        icon="fa-light fa-arrow-left"
                                        size={16}
                                    />
                                </button>
                                <button
                                    onClick={handleNextArrow}
                                    disabled={
                                        count === program?.certificates?.length
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon="fa-light fa-arrow-right"
                                        size={16}
                                    />
                                </button>
                            </SliderArrows>
                        </ProgramWrapper>
                    );
                })}
        </>
    );
};

export default MyCertificatesBody;

const ProgramWrapper = styled.div`
    width: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: #f9f9f9;
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    padding: 2rem 40px;

    h1 {
        font-size: 22px;
        font-weight: 700;
        color: #222;
    }

    section {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
`;

const InitialsCircle = styled.span`
    width: 72px !important;
    height: 72px !important;
    border-radius: 50%;
    background-color: #ffbc00 !important;
    color: #fff;
    font-size: 20px;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
`;

const ProgramTitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    p {
        color: #616161;
        font-size: 16px;
    }

    h2 {
        font-size: 20px;
        color: #000;
    }
`;

const FakeCarousel = styled(Carousel)`
    padding-bottom: 8px;

    .rec.rec-slider-container {
        margin: 0;
    }
    .rec.rec-arrow {
        display: none;
        visibility: hidden;
    }

    .rec.rec-pagination {
        display: none;
        visibility: hidden;
    }
`;

const SliderArrows = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;

    button {
        cursor: pointer;

        &:disabled {
            color: #c4c4c4;
        }
    }
`;
