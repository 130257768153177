import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Badge, Name, TextState } from '../../../../styled-components';
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from 'react-simple-snackbar';
import { Icon } from '@iconify/react';
import { documentsActions } from '../../../../redux/actions';
import { optionsStyle } from '../../../../utils/snackStyles';
import { Text } from '../../../common/Texts';
import DocumentRequirementsModal from './DocumentRequirementsModal';
import UpdateDocumentsModal from './UpdateDocumentsModal';
import UploadDocumentsModal from './UploadDocumentsModal';
import DownloadButton from './DownloadButton';
import SuccessfulUploadModal from './SuccessfulUploadModal';

const Document = (props) => {
    // Props
    const { data } = props;

    const documentId = data?.id_documentacion;
    const documentName = data?.nombre_documento;
    const documentType = data?.tipo_documentacion;

    const downloadFile = data?.documentation_url;
    const exampleFile = data?.instructive_image;
    const requirementsDetail =
        data?.text_instructive !== false ? data?.text_instructive : '';

    // Redux
    const { listLoading, errorList } = useSelector((state) => state.documents);
    const dispatch = useDispatch();

    // States
    const [loading, setLoading] = useState(false);
    const [flag, setFlag] = useState(false);
    const [openSnackbar] = useSnackbar(optionsStyle);

    //  ========= MODALS STATES =========
    const [openRequirementsModal, setOpenRequirementsModal] = useState(false);
    const [openUpdateDocumentModal, setOpenUpdateDocumentModal] =
        useState(false);
    const [openUploadDocumentModal, setOpenUploadDocumentModal] =
        useState(false);
    const [openSuccesModal, setOpenSuccesModal] = useState(false);

    //Effects
    useEffect(() => {
        if (flag) {
            if (!listLoading) {
                if (errorList) {
                    openSnackbar(`Ocurrió un error al guardar documento`);
                    setFlag(false);
                } else {
                    setOpenSuccesModal(true);
                    dispatch(documentsActions.getDocumentsRequest());
                    setFlag(false);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listLoading]);

    // ========= FUNCTIONS =========
    //Lista de requerimientos
    const handleViewRequirements = () => {
        setOpenRequirementsModal(true);
    };
    const handleCloseViewRequirements = () => {
        setOpenRequirementsModal(false);
    };

    //modal para cargar el archivo
    const handleClickUpload = () => {
        setOpenUploadDocumentModal(true);
    };
    const handleClickUpdate = () => {
        setOpenUpdateDocumentModal(true);
    };

    //download file
    const openSnackbarText = (text) => {
        openSnackbar(text);
    };

    const handleActionButton = (status, documentId, documentName) => {
        const renderTooltip = (title, icon, action) => (
            <FileActionsButton onClick={action}>
                <Tooltip title={title} placement="top" arrow>
                    <Icon icon={icon} width="24" height="24" />
                </Tooltip>
            </FileActionsButton>
        );
        switch (status.toLowerCase()) {
            case 'rechazado':
            case 'rechazada':
                return renderTooltip(
                    'Actualizar documentación',
                    'lucide:rotate-cw',
                    handleClickUpdate
                );
            case 'pendiente aprobación':
            case 'pendiente':
            case 'aceptado':
            case 'aceptada':
                return (
                    <DownloadButton
                        label={null}
                        idFile={documentId}
                        nameFile={documentName}
                        setLoading={setLoading}
                        openSnackbarText={openSnackbarText}
                    />
                );
            default:
                return renderTooltip(
                    'Subir documentación',
                    'stash:folder-arrow-up',
                    handleClickUpload
                );
        }
    };

    const getBadgeContent = (status) => {
        const renderBadge = (title, icon) => (
            <Badge status={status}>
                <Icon icon={icon} width="24" height="24" />
                <Name>{title}</Name>
            </Badge>
        );
        switch (status) {
            case 'Pendiente aprobación':
            case 'pendiente':
                return renderBadge('En revisión', 'mdi-light:clock');
            case 'Pendiente carga':
                return renderBadge('No presentado', 'mdi-light:alert-circle');
            case 'Rechazado':
            case 'Rechazada':
                return renderBadge('Rechazado', 'ph:x-circle-thin');
            case 'Aceptado':
            case 'Aceptada':
                return renderBadge('Aceptado', 'circum:circle-check');
            case 'loading':
                return renderBadge('Aguarda ... ', 'mdi-light:clock');
            default:
                return null;
        }
    };

    const savePdf = async (doc) => {
        if (!doc) return;
        const file = new FormData();
        file.append('documento', doc);
        file.append('tipo_documentacion', documentType);
        openSnackbar(`Guardando archivo`);
        try {
            dispatch(documentsActions.postDocumentRequest(file));
            setFlag(true);
        } catch (error) {
            openSnackbar(`Error al guardar el archivo`);
        }
    };

    const updatePdf = async (doc, id) => {
        const file = new FormData();
        file.append('documento', doc);
        file.append('tipo_documentacion', documentType);
        openSnackbar(`Actualizando archivo`);
        try {
            dispatch(
                documentsActions.putDocumentRequest({
                    id,
                    pdf: file,
                })
            );
            setFlag(true);
        } catch (error) {
            openSnackbar(`Error al guardar el archivo`);
        }
    };

    return (
        <>
            {!!data && data.nombre !== '' && (
                <>
                    <Container>
                        <Header>
                            <Text fontWeight="700" color="#555555">
                                {data?.nombre}
                            </Text>
                        </Header>
                        <Body>
                            <Left>
                                <TextState
                                    fontWeight="400"
                                    fontSize="15px"
                                    color="#222222"
                                    letterSpacing="0.1%"
                                    lineHeight="28px"
                                >
                                    Estado:
                                </TextState>
                                {loading
                                    ? getBadgeContent('loading')
                                    : getBadgeContent(data?.estado)}
                            </Left>

                            <ButtonsWrapper>
                                <RequirementsButton
                                    onClick={handleViewRequirements}
                                >
                                    <Tooltip
                                        title="Requerimientos"
                                        placement="top"
                                        arrow
                                    >
                                        <Icon
                                            icon="uil:file-info-alt"
                                            width="24"
                                            height="24"
                                            onClick={handleViewRequirements}
                                        />
                                    </Tooltip>
                                </RequirementsButton>
                                {handleActionButton(
                                    data.estado,
                                    documentId,
                                    documentName
                                )}
                            </ButtonsWrapper>
                        </Body>
                    </Container>
                    {openRequirementsModal && (
                        <DocumentRequirementsModal
                            open={openRequirementsModal}
                            handleCloseModal={handleCloseViewRequirements}
                            title={data?.nombre}
                            list={requirementsDetail}
                            type={documentType}
                            exampleUrl={exampleFile}
                            downloadUrl={downloadFile}
                            documentId={documentId}
                            setLoading={setLoading}
                            openSnackbarText={openSnackbarText}
                        />
                    )}

                    {openUpdateDocumentModal && (
                        <UpdateDocumentsModal
                            open={openUpdateDocumentModal}
                            handleCloseModal={() =>
                                setOpenUpdateDocumentModal(false)
                            }
                            handleSave={updatePdf}
                            title={data?.nombre}
                            documentId={documentId}
                            setLoading={setLoading}
                            openSnackbarText={openSnackbarText}
                            comments={data?.motivo_rechazo}
                            dateReview={data?.fecha}
                        />
                    )}

                    {openUploadDocumentModal && (
                        <UploadDocumentsModal
                            open={openUploadDocumentModal}
                            handleCloseModal={() =>
                                setOpenUploadDocumentModal(false)
                            }
                            handleSave={savePdf}
                            title={data?.nombre}
                        />
                    )}

                    {openSuccesModal && (
                        <SuccessfulUploadModal
                            open={openSuccesModal}
                            handleCloseModal={() => {
                                setOpenSuccesModal(false);
                            }}
                            title={data?.nombre}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default Document;

const Container = styled.div`
    height: 180px;
    background-color: #ffffff;
    border-radius: 20px;
    margin-bottom: 1rem;
    border: 1px solid #c4c4c4;
    @media (max-width: 380px) {
        max-width: 250px;
        height: 200px;
    }
`;

const Header = styled.div`
    height: 60%;
    width: calc(80% - 20px);
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 380px) {
        height: 80px;
    }
`;

const Body = styled.div`
    height: calc(40% - 2rem);
    width: calc(100% - 2rem);
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f1f1f1;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    @media (max-width: 380px) {
        flex-direction: column;
        height: calc(120px - 2rem);
    }
`;

const Left = styled.div`
    display: flex;
    flex-direction: row;
    width: 60%;
    justify-content: flex-start;
    align-items: center;
    gap: 0.25rem;
    @media (max-width: 380px) {
        width: 100%;
        justify-content: center;
    }
`;

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 40%;
    gap: 0.25rem;
    @media (max-width: 380px) {
        width: 100%;
        justify-content: center;
    }
`;

const FileActionsButton = styled.div`
    width: calc(48px - 16px);
    height: calc(48px - 16px);
    padding: 8px;
    border-radius: 100px;
    border: 1.5px solid #cd2118;
    background-color: #cd2118;
    color: #fafafa;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const RequirementsButton = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #cd2118;
    color: #cd2118;
    cursor: pointer;
`;
