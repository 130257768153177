import React from 'react';
import { Modal } from '@mui/material';
import styled from 'styled-components';
import LazyImg from '../../../../components/common/LazyImg';

const ImageModal = ({ onClose, open, image }) => {
    return (
        <CustomModal onClose={onClose} open={open}>
            <ImageWrapper>
                <LazyImg
                    src={image}
                    width="100%"
                    height="100%"
                    backgroundColor="none"
                />
            </ImageWrapper>
        </CustomModal>
    );
};

export default ImageModal;

const CustomModal = styled(Modal)`
    display: grid;
    place-items: center;
`;

const ImageWrapper = styled.div`
    outline: none;
    max-width: 1000px;
    height: fit-content;
    width: auto;
    height: auto;
    border-radius: 10px;
`;
