import axios from 'axios';
import { axiosErrorFormatter } from '../../../utils/error.handler';

const URL = `${process.env.REACT_APP_CHAT_V2}`;

export async function getUser(payload) {
    try {
        const request = await axios.post(`${URL}/users/get`, payload, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return request.data;
    } catch (error) {
        return axiosErrorFormatter(error);
    }
}

export async function getUserByRepoPartnerId(repoId) {
    try {
        const request = await axios.get(`${URL}/users/getByRepoId/${repoId}`);
        return request.data;
    } catch (error) {
        return axiosErrorFormatter(error);
    }
}

export async function createUser(payload) {
    try {
        const request = await axios.post(`${URL}/users/create`, payload);
        return request.data;
    } catch (error) {
        return axiosErrorFormatter(error);
    }
}
