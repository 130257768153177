import { getDocumentById } from '../../../../redux/api/titulation/titulation';
import {
    DownloadOutlinedButton,
    FileActionsButton,
} from '../../../../styled-components';
import { Tooltip } from '@mui/material';
import { Icon } from '@iconify/react';

const DownloadButton = (props) => {
    const {
        idFile,
        nameFile,
        setLoading,
        openSnackbarText,
        label = null,
    } = props;

    const downloadFile = async (id, name) => {
        console.log('entra a downloadFile :>> ');
        setLoading(true);
        const result = await getDocumentById(id);
        try {
            generatePdf(result, name);
        } catch (error) {
            openSnackbarText(`No se pudo descargar el documento`);
        } finally {
            openSnackbarText(`El documento se descargó exitosamente`);
            setLoading(false);
        }
    };

    const generatePdf = (file, namePdf) => {
        const link = document.createElement('a');
        link.href = file;
        link.download = namePdf;
        link.click();
    };

    return (
        <>
            {label ? (
                <DownloadOutlinedButton
                    onClick={() => downloadFile(idFile, nameFile)}
                >
                    {label}
                </DownloadOutlinedButton>
            ) : (
                <FileActionsButton
                    onClick={() => downloadFile(idFile, nameFile)}
                >
                    <Tooltip
                        title="Descargar documentación"
                        placement="top"
                        arrow
                    >
                        <Icon
                            icon="f7:arrow-down-to-line-alt"
                            width="24"
                            height="24"
                        />
                    </Tooltip>
                </FileActionsButton>
            )}
        </>
    );
};

export default DownloadButton;
