import { Icon } from '@iconify/react/dist/iconify.js';

const TrashButton = (props) => {
    const { handleTrashFile } = props;
    return (
        <button onClick={handleTrashFile}>
            <Icon icon="mdi:trash" width="24px" height="24px" />
        </button>
    );
};

export default TrashButton;
