import * as encode from 'nodejs-base64-encode';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useState } from 'react';

// Components
import FontAwesomeIcon from '../../components/common/FontAwesomeIcon';
import UpdatedSubjectHeader from './components/UpdatedSubjectHeader';
import UpdatedSubjectObject from './components/UpdatedSubjectObject';
import CommonButton from '../../components/common/CommonButton';

// Assets
import AccesoDePorVidaImg from '../../assets/media/aden/acceso_de_por_vida.png';
import useFetch from '../../hooks/useFetch';
import { getSubjectData } from './services/api/updatedSubject';
import { useSelector } from 'react-redux';
import SkeletonLoading from '../../components/common/SkeletonLoading';

const UpdatedSubject = () => {
    const { id } = useParams();
    const repoId = useSelector((state) => state.auth.user.repo_id);

    const hash = encode.decode(id, 'base64');

    const { data: subjectData, loading } = useFetch({
        asyncFn: () => getSubjectData(hash, repoId),
    });

    if (loading) {
        return (
            <Container>
                <SkeletonLoading width={'100%'} height={80} margin={0} />
                <SkeletonLoading width={'100%'} height={500} margin={0} />
            </Container>
        );
    }

    return (
        <Container>
            <Header>
                <span>
                    <img
                        src={AccesoDePorVidaImg}
                        alt="Acceso de por vida"
                        style={{ width: 80, height: 80 }}
                    />
                    <h1>Acceso de por vida</h1>
                </span>
                <Line />
                <CommonButton
                    label="Volver"
                    variant="text"
                    Icon={() => (
                        <div>
                            <FontAwesomeIcon
                                icon="fa-light fa-arrow-left"
                                size={21}
                            />
                        </div>
                    )}
                    onClick={() => (window.location.href = `/mi-progreso`)}
                />
            </Header>
            <SubContainer>
                <UpdatedSubjectHeader subjectData={subjectData} />
                <h1>Bloques que componen esta asignatura:</h1>
                <UpdatedSubjectObjectContainer subjectData={subjectData} />
            </SubContainer>
        </Container>
    );
};

export default UpdatedSubject;

const UpdatedSubjectObjectContainer = (props) => {
    const { subjectData } = props;

    const [open, setOpen] = useState(Array(5).fill(false));

    const handleSetOpen = (booleanValue, indexValue) => {
        setOpen(
            open.map((value, index) => {
                return index === indexValue ? booleanValue : value;
            })
        );
    };
    return (
        <>
            {subjectData?.courses
                .sort((a, b) => a.order - b.order)
                .map((course, index) => {
                    return (
                        <UpdatedSubjectObject
                            key={index}
                            index={index}
                            disableLast={index + 1 === Array(5).length}
                            open={open[index]}
                            setOpen={handleSetOpen}
                            nextOpen={open[index + 1]}
                            course={course}
                        />
                    );
                })}
        </>
    );
};

const Container = styled.div`
    min-height: 80vh;
    width: calc(100% - 4rem);
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 2rem;
    background-color: #b31d15;
    padding: 2rem;
    border-radius: 38px;

    h1 {
        font-size: 20px;
        font-weight: 500;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    color: #fff;

    h1 {
        white-space: nowrap;
    }

    button {
        height: fit-content;
    }

    span {
        display: flex;
        align-items: center;
        gap: 2rem;
    }

    @media (width < 1068px) {
        flex-direction: column;
        align-items: start;
    }
`;

const Line = styled.div`
    width: 100%;
    height: 2px;
    background-color: #fff;

    @media (width < 1068px) {
        display: none;
    }
`;

const SubContainer = styled.div`
    background-color: #fff;
    padding: 2rem;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
