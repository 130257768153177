const moment = require('moment');

export function getMonthName(date) {
    return moment(date).subtract(0, 'month').startOf('month').format('MMMM');
}

export function getDayName(date) {
    return moment(date).format('dddd').substring(0, 8);
}

export function formatDate(date, format = 'DD/MM/YYYY') {
    return moment(date).format(format);
}

export function getDefaultDate(date) {
    const houres = new Date(date).getHours();
    const minutes = new Date(date).getMinutes();
    return `${houres}:${minutes < 10 ? '0' + minutes : minutes}`;
}

export function getDateNumber(date) {
    return moment(date).date();
}

export function getFullTimeFormat(date) {
    return moment(date).format('H:MM');
}

export function getDateFullYear(date) {
    return new Date(date).getFullYear();
}

export function getLocalUTC() {
    const offsetMinutes = new Date().getTimezoneOffset();
    const offsetHours = offsetMinutes / 60;

    const offsetFormatted =
        offsetHours > 0
            ? `-${Math.abs(offsetHours)}`
            : `+${Math.abs(offsetHours)}`;

    return offsetFormatted;
}

export function formatDateTime(date) {
    const formattedTime = new Date(date).toLocaleString('es-AR', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });

    return formattedTime
        ?.replaceAll('.', '')
        ?.split(' ')
        ?.map((timeStringDivision, i) =>
            i === 1
                ? timeStringDivision.replaceAll(/\s/g, '')?.toUpperCase()
                : timeStringDivision
        )
        ?.join(' ');
}
