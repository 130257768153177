import { io } from 'socket.io-client';

export const socket = io(`${process.env.REACT_APP_CHAT}`, {
    transports: ['websocket'],
});

export const socketIO = io(`${process.env.REACT_APP_CHAT_V2}`, {
    transports: ['websocket', 'polling'],
    timeout: 10000,
    upgrade: false,
    forceNew: true,
    reconnection: true,
    reconnectionAttempts: 5,
    closeOnBeforeunload: true,
});
