import { Modal } from '@mui/material';
import React, { useMemo } from 'react';
import {
    Badge,
    CloseButton,
    DividerTitulationModal,
    IdTrackingDiv,
    Name,
    RowDetails,
    WrapperModal,
} from '../../../../styled-components';
import { Text } from '../../../common/Texts';
import TrackingCard from '../../molecules/titulation/TrackingCard';
import { formatDate } from '../../../../utils/dates/dates.utils';
import { Icon } from '@iconify/react';

const DegreeTrackingModal = (props) => {
    const { open, handleCloseModal, tracking } = props;

    const sortedTrackingMessages = useMemo(() => {
        return tracking.registro_cambio_estado_ids?.sort(
            (a, b) => a.estado_id - b.estado_id
        );
    }, [tracking.registro_cambio_estado_ids]);

    return (
        <>
            <Modal onClose={handleCloseModal} open={open}>
                <WrapperModal
                    widthModal="320px"
                    maxHeightModal="80vh"
                    gapModal="1rem"
                    topModal="50%"
                    leftModal="50%"
                    minWidthModal="320px"
                >
                    <CloseButton onClick={handleCloseModal}>
                        <Icon
                            icon="iconoir:cancel"
                            width="24px"
                            height="24px"
                        />
                    </CloseButton>
                    <Text
                        fontWeight="600"
                        fontSize="15px"
                        color="#616161"
                        letterSpacing="0.1%"
                        lineHeight="20px"
                    >
                        Estado del título:
                    </Text>
                    <Badge status={tracking.estado}>
                        {tracking.estado === 'Entregado a participante' ? (
                            <>
                                <Icon
                                    icon="circum:circle-check"
                                    width="24"
                                    height="24"
                                />
                                <Name>Entregado</Name>
                            </>
                        ) : (
                            <>
                                <Icon
                                    icon="mdi-light:clock"
                                    width="24"
                                    height="24"
                                />
                                <Name>{tracking.estado}</Name>
                            </>
                        )}
                    </Badge>
                    {tracking.nro_seguimiento_envio_titulo !== '' && (
                        <>
                            <DividerTitulationModal color={'#A8A8A8'} />
                            <IdTrackingDiv>
                                <RowDetails>
                                    <Text
                                        fontSize="12px"
                                        fontWeight={700}
                                        color="#000000"
                                        textAlign="center"
                                        lineHeight="24px"
                                    >
                                        Medio de envío:{' '}
                                    </Text>

                                    <Text
                                        fontSize="12px"
                                        fontWeight={400}
                                        color="#616161"
                                        textAlign="center"
                                        lineHeight="24px"
                                    >
                                        {tracking.medio_de_envio
                                            ? tracking.medio_de_envio
                                            : '-'}
                                    </Text>
                                </RowDetails>
                                <RowDetails>
                                    <Text
                                        fontSize="12px"
                                        fontWeight={700}
                                        color="#000000"
                                        textAlign="center"
                                        lineHeight="24px"
                                    >
                                        Número de seguimiento:{' '}
                                    </Text>

                                    <Text
                                        fontSize="12px"
                                        fontWeight={400}
                                        color="#616161"
                                        textAlign="center"
                                        lineHeight="24px"
                                    >
                                        {tracking.nro_seguimiento_envio_titulo
                                            ? tracking.nro_seguimiento_envio_titulo
                                            : '-'}{' '}
                                    </Text>
                                </RowDetails>
                            </IdTrackingDiv>
                        </>
                    )}

                    <DividerTitulationModal color={'#A8A8A8'} />
                    {sortedTrackingMessages?.map((track, index) => (
                        <TrackingCard
                            key={index}
                            state={track.estado_nombre}
                            date={
                                track.fecha_cambio_estado_manual !== false
                                    ? formatDate(
                                          track.fecha_cambio_estado_manual,
                                          'DD/MM/YYYY'
                                      )
                                    : track.fecha_cambio_estado !== false
                                      ? formatDate(
                                            track.fecha_cambio_estado,
                                            'DD/MM/YYYY'
                                        )
                                      : ''
                            }
                            isCurrrentState={track.is_current_state}
                            trackedStatus={
                                track.fecha_cambio_estado_manual ||
                                track.fecha_cambio_estado
                                    ? true
                                    : false
                            }
                            lastTracking={
                                sortedTrackingMessages.length - 1 === index
                            }
                        />
                    ))}
                </WrapperModal>
            </Modal>
        </>
    );
};

export default DegreeTrackingModal;
