import styled from 'styled-components';
import SkeletonLoading from '../../../../components/common/SkeletonLoading';

const UpdatedProgramSkeleton = () => {
    return (
        <EmptyContainer>
            <SkeletonLoading
                variant="rectangular"
                height={30}
                width={400}
                margin={0}
            />
            <SkeletonLoading
                variant="rectangular"
                height={70}
                width={400}
                margin={0}
            />
            <Wrapper>
                <SkeletonLoading
                    variant="rectangular"
                    height={340}
                    width={320}
                    margin={0}
                />
                <SkeletonLoading
                    variant="rectangular"
                    height={340}
                    width={320}
                    margin={0}
                />
                <SkeletonLoading
                    variant="rectangular"
                    height={340}
                    width={320}
                    margin={0}
                />
            </Wrapper>
        </EmptyContainer>
    );
};

export default UpdatedProgramSkeleton;

const EmptyContainer = styled.div`
    width: calc(100% - 80px);
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 2rem;
    background-color: #f9f9f9;
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    padding: 2rem 40px;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 32px;
    overflow: hidden;
`;
