import React, { useState } from 'react';
import styled from 'styled-components';
import { CircularProgress, IconButton } from '@mui/material';
import { InsertDriveFile, Download, Close } from '@mui/icons-material';
import { Text } from '../../../../components/common/Texts';
import { download } from '../../../../redux/api/chat-V2/http/upload';

const FileComponent = (props) => {
    const { name, size, href, onClose } = props;

    const [progress, setProgress] = useState(0);
    const [downloading, setDownloading] = useState(false);

    const kb = size / 1024;
    const mb = kb / 1024;

    const handleDownload = async (e) => {
        setDownloading(true);
        const response = await download(name, downloadProgress);

        const buffer = Buffer(response[0].data);
        const blob = new Blob([buffer]);

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = name || 'download.pdf';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
        setDownloading(false);
        setProgress(0);
    };

    function downloadProgress(progressEvent) {
        const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
        );

        setProgress(percentCompleted);
    }

    return (
        <Container>
            <InsertDriveFile />
            <FileData>
                <Text
                    ellipsis="ellipsis"
                    fontSize="13px"
                    fontWeight="700"
                    color="#b31d15"
                >
                    {name}
                </Text>
                <Text fontSize="13px" fontWeight="400" color="#222222">
                    {mb.toFixed(2)} MB
                </Text>
            </FileData>
            {!!href && !downloading && (
                <IconButton id="download-button" onClick={handleDownload}>
                    <Download />
                </IconButton>
            )}
            {!!onClose && (
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            )}

            {downloading && (
                <CustomCircularProgress
                    sx={{
                        borderRadius: '50%',
                        boxShadow: `inset 0 0 0 4px #e1e1e1e1`,
                        maxHeight: '30px',
                        maxWidth: '30px',
                    }}
                    variant="determinate"
                    value={progress}
                    thickness={4}
                />
            )}
        </Container>
    );
};

export default FileComponent;

const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    height: 70px;
    min-width: 220px;
    max-width: 260px;
    align-items: center;
    border-radius: 20px;
    padding: 0.8rem 1rem;
    gap: 0.5rem;
    border: 1px solid #e5e5e5;
    background-color: #f9f9f9;

    svg {
        color: #a3a3a3;
    }
`;

const FileData = styled.div`
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    max-width: 110px;
`;

const CustomCircularProgress = styled(CircularProgress)``;
