import styled from 'styled-components';
import CertificateImg from '../../../assets/media/aden/certificate.png';

const CertificateCard = () => {
    return (
        <Container>
            <Body>
                <img
                    src={CertificateImg}
                    alt="Certificate"
                    style={{ width: 150, height: 150 }}
                />
                <h2>Arquitectura de negocios y nuevas metodologías</h2>
            </Body>
            <Footer></Footer>
        </Container>
    );
};

export default CertificateCard;

const Container = styled.div`
    width: 320px;
    height: 360px;
    border-radius: 20px;
    border: 2px solid #ffbc00;
    box-shadow:
        0px 3px 6px 0px #0000003b,
        0px 3px 6px 0px #00000029;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Body = styled.div`
    width: calc(100% - 4rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 2rem;

    h2 {
        width: 100%;
        font-size: 16px;
    }
`;

const Footer = styled.div`
    width: 100%;
    height: 76px;
    background-color: #f1f1f1;
    border-radius: 0 0 20px 20px;
`;
