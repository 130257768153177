import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

// Components
import FontAwesomeIcon from '../../../../components/common/FontAwesomeIcon';
import DefaultSearch from '../../../../components/common/DefaultSearch';
import SimpleLoading from '../../../../components/common/SimpleLoading';
import CommonButton from '../../../../components/common/CommonButton';
import UsersNotFound from './UsersNotFound';
import CommunityCard from './CommunityCard';
import UsersError from './UsersError';

// Redux
import { getUsers } from '../../../../redux/api/chat-V2/http/users';

// Hooks
import useFetch from '../../../../hooks/useFetch';

// Adapters
import { usersAdapter } from '../../adapters';
import { socketIO } from '../../../../helpers/connectionSocket.helper';
import { ChatContext } from '../../../../contexts/chat/ChatProvider';

const ChatCommunity = () => {
    const { user, setCurrentRoom, createRoom } = useContext(ChatContext);

    const [search, setSearch] = useState('');
    const [selectFilter, setSelectFilter] = useState('');
    const [page, setPage] = useState(1);

    const { data, loading, error, isError, refetch } = useFetch({
        asyncFn: () => getUsers(search, selectFilter, page),
        adapter: usersAdapter,
    });

    const users = data?.users;
    const totalUsers = data?.total;
    const totalPages = Math.ceil(totalUsers / 12);

    const handleChange = (e) => {
        setSearch(e.target.value);
    };

    const filterUsers = () => {
        setPage(1);
        refetch();
    };

    const handleChangeSelect = (e) => {
        setPage(1);
        setSelectFilter(e.target.value);
    };

    const handleFirstPage = () => {
        setPage(1);
    };

    const handlePreviousPage = () => {
        setPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handleLastPage = () => {
        setPage(totalPages);
    };

    useEffect(() => {
        !loading && refetch();
    }, [selectFilter]);

    useEffect(() => {
        !loading && refetch();
    }, [page]);

    return (
        <Container>
            <FiltersContainer>
                <SearchFilterContainer>
                    <DefaultSearch
                        search={search}
                        handleChange={handleChange}
                        handleOnKeyDown={filterUsers}
                        label="Buscar por nombre"
                    />
                    <CommonButton
                        variant="filled"
                        label="Filtrar"
                        onClick={filterUsers}
                        Icon={() => (
                            <div>
                                <FontAwesomeIcon
                                    icon="fa-light fa-bars-filter"
                                    size={24}
                                />
                            </div>
                        )}
                    />
                </SearchFilterContainer>
                <OrderFilterContainer>
                    <label>Ordenar por</label>
                    <Select value={selectFilter} onChange={handleChangeSelect}>
                        <option value="asc">Nombre A-Z</option>
                        <option value="desc">Nombre Z-A</option>
                        <option value="" selected>
                            Recientes
                        </option>
                    </Select>
                </OrderFilterContainer>
            </FiltersContainer>
            {loading ? (
                <Loader>
                    <SimpleLoading />
                    <h2>Cargando usuarios</h2>
                </Loader>
            ) : (
                <>
                    {isError ? (
                        <UsersError refetch={refetch} />
                    ) : (
                        <>
                            {users?.length > 0 ? (
                                <CommunityGridContainer>
                                    {users?.map((user) => (
                                        <CommunityCard
                                            user={user}
                                            createRoom={createRoom}
                                        />
                                    ))}
                                </CommunityGridContainer>
                            ) : (
                                <UsersNotFound />
                            )}
                        </>
                    )}
                </>
            )}
            <PaginationContainer>
                <button onClick={handleFirstPage} disabled={page === 1}>
                    <FontAwesomeIcon
                        icon="fa-light fa-arrow-left-to-line"
                        size={18}
                    />
                </button>
                <button onClick={handlePreviousPage} disabled={page === 1}>
                    <FontAwesomeIcon icon="fa-light fa-arrow-left" size={18} />
                </button>
                <label>
                    <b>{page}</b>/{totalPages}
                </label>
                <button onClick={handleNextPage} disabled={page === totalPages}>
                    <FontAwesomeIcon icon="fa-light fa-arrow-right" size={18} />
                </button>
                <button onClick={handleLastPage} disabled={page === totalPages}>
                    <FontAwesomeIcon
                        icon="fa-light fa-arrow-right-to-line"
                        size={18}
                    />
                </button>
            </PaginationContainer>
        </Container>
    );
};

export default ChatCommunity;

const Container = styled.div`
    width: calc(100% - 12rem);
    height: 100%;
    padding: 0 6rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @media (width < 1068px) {
        width: calc(100% - 2rem);
        padding: 0 1rem;
        gap: 2rem;
    }
`;

const FiltersContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (width < 1068px) {
        flex-direction: column;
        align-items: start;
        margin-top: 1rem;
    }
`;

const SearchFilterContainer = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;

    .MuiFormControl-root {
        margin: 0;
        border: 1px solid #a8a8a8;
    }

    @media (width < 1068px) {
        width: 100%;
    }
`;

const OrderFilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 8px;

    label {
        font-size: 14px;
        font-weight: 400;
    }

    @media (width < 1068px) {
        margin-top: 1rem;
    }
`;

const Select = styled.select`
    appearance: none; /* Elimina el estilo nativo */
    height: 48px;
    width: 200px;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid #a8a8a8;
    cursor: pointer;

    option {
        padding: 8px 0;
        cursor: pointer;
    }
`;

const CommunityGridContainer = styled.div`
    width: 100%;
    height: fit-content;
    background-color: #fff;
    box-shadow:
        0px 12px 24px -15px #0000001a,
        0px 0px 10px -6px #00000040;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 1rem;
    border-radius: 30px;
    padding: 24px;

    @media (width < 1068px) {
        width: calc(100% - 3rem);
    }
`;

const Loader = styled.div`
    width: 100%;
    min-height: 300px;
    background-color: #fff;
    box-shadow:
        0px 12px 24px -15px #0000001a,
        0px 0px 10px -6px #00000040;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    color: #b31d15;
`;

const PaginationContainer = styled.div`
    width: calc(100% + 3rem);
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    button {
        cursor: pointer;
    }
`;
