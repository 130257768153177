import styled from 'styled-components';

// Components
import CommonButton from '../../components/common/CommonButton';
import FontAwesomeIcon from '../../components/common/FontAwesomeIcon';

// Assets
import AccesoDePorVidaImg from '../../assets/media/aden/acceso_de_por_vida.png';
import MyCertificatesBody from './components/MyCertificatesBody';

const MyCertificates = () => {
    return (
        <Container>
            <Header>
                <img
                    src={AccesoDePorVidaImg}
                    alt="Acceso de por vida"
                    style={{ width: 80, height: 80 }}
                />
                <h1>Acceso de por vida</h1>
                <Line />
                <CommonButton
                    label="Volver"
                    variant="text"
                    Icon={() => (
                        <div>
                            <FontAwesomeIcon
                                icon="fa-light fa-arrow-left"
                                size={21}
                            />
                        </div>
                    )}
                    onClick={() => (window.location.href = `/mi-progreso`)}
                />
            </Header>
            <MyCertificatesBody />
        </Container>
    );
};

export default MyCertificates;

const Container = styled.div`
    width: calc(100% - 4rem);
    min-height: 80vh;
    height: 100%;
    background-color: #b31d15;
    border-radius: 38px;
    box-shadow:
        0px 14px 64px -4px #18274b1f,
        0px 8px 22px -6px #18274b1f;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    h1 {
        font-size: 20px;
        font-weight: 500;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    color: #fff;

    h1 {
        white-space: nowrap;
    }

    button {
        height: fit-content;
    }
`;

const Line = styled.div`
    width: 100%;
    height: 2px;
    background-color: #fff;
`;
