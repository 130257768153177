import { Card } from '@mui/material';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Text } from '../../../../common/Texts';
import UserData from '../../../molecules/teacher/UserData';
import { ChatContext } from '../../../../../contexts/chat/ChatProvider';

const MessageTeacher = (props) => {
    const { pending = true, values } = props;

    const { deleteNotification } = useContext(ChatContext);

    const date = new Date(values.created_at);
    const history = useHistory();

    const viewNotification = async (to, from) => {
        const deleted = await deleteNotification(values._id);
        if (!deleted) {
            return;
        }

        selectChannel(values.reciver._id, values.emitter._id);
    };

    const selectChannel = async () => {
        setTimeout(() => history.push(`/chat?room=${values.room_id}`), 200);
    };

    return (
        <MessageWrapper
            onClick={() => {
                viewNotification(
                    parseInt(values.reciver._id),
                    parseInt(values.emitter._id)
                );
            }}
            title="Ir al chat"
            pending={!pending}
        >
            <Header>
                <UserData
                    img={values.reciver.profile_photo}
                    name={values.reciver.name}
                />
                <Text fontWeight="bold" fontSize="12px" color="#888888">
                    {date.toLocaleDateString()}
                </Text>
            </Header>
            <Text fontSize=".8rem" fontWeight="600" color="#888888">
                {
                    values?.messages[values?.messages?.length - 1]?.message_body
                        ?.message
                }
            </Text>
        </MessageWrapper>
    );
};

export default MessageTeacher;

const MessageWrapper = styled(Card)`
    height: auto;
    margin: 10px;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 0px 6px 2px rgba(222, 222, 222, 0.75);
    -webkit-box-shadow: 0px 0px 6px 2px rgba(222, 222, 222, 0.75);
    -moz-box-shadow: 0px 0px 6px 2px rgba(222, 222, 222, 0.75);
    background-color: ${(p) => p.pending && '#f7f7f7'};
    cursor: pointer;
    :hover {
        box-shadow: 0px 0px 6px 2px rgba(155, 155, 155, 0.75);
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    padding: 0 0 0.3rem 0;
`;

const NewMessage = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fc2958;
`;
