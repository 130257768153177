import React, { useState } from 'react';
import styled from 'styled-components';

// Components
import InfoEventModal from '../../../../components/ui/molecules/events/InfoEventModal';
import FontAwesomeIcon from '../../../../components/common/FontAwesomeIcon';
import CommonButton from '../../../../components/common/CommonButton';

// Material UI
import { Modal } from '@mui/material';

// Redux
import { postEvets } from '../../../../redux/api/events/events';
import { useSelector } from 'react-redux';

const RegisterEventModal = (props) => {
    const { handleClick, event, handleModalSuccess } = props;

    const { crm_id, is_registered } = event;

    const partner_id = useSelector((state) => state.auth.user.partner_id);

    // STATE
    const [isOpen, setIsOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState({
        view: false,
        msg: '',
    });

    // FUNCTIONS
    function handleModal() {
        handleClick();
        setIsOpen(false);
    }

    const handleSubmit = async (values) => {
        const data = {
            partner_id: partner_id,
            event_id: crm_id,
        };
        setLoading(true);

        const req = await postEvets(data);
        setLoading(false);

        if (!req.error) {
            handleModalSuccess();
            handleClick();
            /* setMsg({
                view: true,
                msg: '¡Se registró correctamente!',
                type: true,
            }); */
        }

        setTimeout(() => {
            setMsg({
                view: false,
                msg: '',
                type: null,
            });
        }, 2000);

        if (req.error) {
            if (
                req.error.response.data.error.includes(
                    'El contacto ya se encuentra registrado al evento'
                )
            ) {
                setMsg({
                    view: true,
                    msg: '¡Ya se encuentra registrado en el evento!',
                    type: false,
                });
                return;
            }

            if (
                req.error.response.data.error.includes(
                    'No quedan plazas para este evento.'
                )
            ) {
                setMsg({
                    view: true,
                    msg: req.error.response.data.error,
                    type: false,
                });
                return;
            }

            setMsg({
                view: true,
                msg: '¡No se pudo registrar al evento!',
                type: false,
            });

            setTimeout(() => {
                setMsg({
                    view: false,
                    msg: '',
                    type: null,
                });
            }, 2000);
        }
    };

    const handleOpenWebinaURL = () => {
        if (event?.transmission_url?.includes('http')) {
            window.open(event?.transmission_url, '_blank');
        } else {
            window.open(`https://${event?.transmission_url}`, '_blank');
        }
    };

    // RETURN
    return (
        <>
            <ModalFake onClose={handleModal} open={isOpen}>
                <Container>
                    <CloseModal onClick={handleModal}>
                        <FontAwesomeIcon icon="fa-light fa-close" size={20} />
                    </CloseModal>
                    <InfoEventModal event={event} />
                    <Footer>
                        {is_registered && (
                            <CommonButton
                                disabled={!event?.transmission_url}
                                onClick={handleOpenWebinaURL}
                                variant="filled"
                                label="Ingresar el evento"
                            />
                        )}
                        <CommonButton
                            onClick={handleSubmit}
                            variant="filled"
                            label={
                                loading
                                    ? 'Inscribiendo...'
                                    : 'Inscribirme ahora'
                            }
                            disabled={loading || is_registered}
                        />
                    </Footer>

                    {/* Cambiar esto por un modal */}
                    {msg.view && (
                        <WrapperMsg type={msg.type}>
                            <p>{msg.msg}</p>
                        </WrapperMsg>
                    )}
                </Container>
            </ModalFake>
        </>
    );
};

export default RegisterEventModal;

const ModalFake = styled(Modal)`
    /* position: relative; */
`;

const Container = styled.div`
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    width: 70%;
    max-width: 90vw;
    height: 90vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    overflow-y: hidden;
    overflow-x: hidden;
    @media (max-width: 768px) {
        width: 100%;
        max-width: 100vw;
        height: 100%;
        max-height: unset;
        border-radius: 0;
        top: 0;
        left: 0;
        padding: 0rem;
        right: 0;
        bottom: 0;
        transform: none;
    }
`;

const CloseModal = styled.button`
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 30px;
    height: 30px;
    background-color: #cd2118;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        border-radius: 100%;
        color: #fff;
        font-size: 1.6rem;
    }

    &:hover {
        background-color: #b31d15;
    }
`;

const WrapperMsg = styled.div`
    position: absolute;
    bottom: 5px;
    left: calc(50% - 100px - 0.3rem);
    width: 200px;
    border-radius: 5px;
    height: 40px;
    padding: 0.3rem;
    background-color: ${(p) => (p.type ? '#35D0A5' : '#FC2958')};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    p {
        color: #fff;
        font-weight: bold;
        text-align: center;
    }
`;

const Footer = styled.div`
    position: relative;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    box-sizing: border-box;
    padding: 1rem 0;
    background-color: #f1f1f1;
`;
