import { socketIO } from '../../../../helpers/connectionSocket.helper';

export const createMessage = (payload, callback) => {
    return socketIO.emit('message', payload, (response) => {
        callback(!(response === 'Success'));
    });
};

// export const getChatMessages = () => {
//     return socketIO.on('getMessages', (messages) => {
//         setMessages(messages);
//         setRoomMessages(messages);
//     });
// };
