import React from "react";
import styled from "styled-components";
import NoReferredImg from "../../../../../assets/media/aden/notebook-img.png"

const NotReferred = (props) => {

    const { tab } = props;

    return (
        <Container>
            {
                tab === "Todos" &&  <h2>No tienes referidos añadidos por el momento.</h2>
            }
            {
                tab === "Solicitados" && <h2>No tienes referidos solicitados por el momento.</h2>
            }
            {
                tab === "Confirmados" && <h2>No tienes referidos confirmados por el momento.</h2>
            }
            <p>Una vez que los tengas, los encontrarás aquí.</p>
            <img alt="" src={NoReferredImg} />
        </Container>
    );
};

export default NotReferred;

const Container = styled.div`
    width: calc(100% - 4rem);
    height: calc(391px - 4rem);
    padding: 2rem;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    text-align: center;

    img {
        width: 300px;
    }

    h2 {
        color: #222;
        font-size: 16px;
        font-weight: 700;
    }

    p {
        color: #222;
        font-size: 14px;
    }
`;
