import axios from 'axios';

export async function getUsers(name, selectFilter, page) {
    let URL = `${process.env.REACT_APP_CHAT_V2}/users/getAll`;

    if (name) {
        URL += `?name=${name}`;
    }

    if (selectFilter) {
        URL += name ? `&sort=${selectFilter}` : `?sort=${selectFilter}`;
    }

    if (page) {
        URL += name || selectFilter ? `&page=${page}` : `?page=${page}`;
    }

    try {
        const response = await axios.get(URL + `&limit=12`);

        return response.data;
    } catch (error) {
        throw new Error(error);
    }
}
