export const MIMETYPE_CASES = ['image/jpeg', 'image/jpg', 'image/png'];

export const MIMETYPE_DOCS_CASES = [
    'application/pdf',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/docx',
    'text/csv',
    'text/css',
    'text/html',
    'text/plain',
];
