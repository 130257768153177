import styled from 'styled-components';
import CuriosityPana from '../../../../../src/assets/media/aden/curiosity-search-pana.png';

const UsersNotFound = () => {
    return (
        <Container>
            <img src={CuriosityPana} alt="Curiosity Pana" />
            <h1>No pudimos encontrar este estudiante</h1>
            <h3>Por favor, verifica que el nombre ingresado sea correcto.</h3>
        </Container>
    );
};

export default UsersNotFound;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: #fff;
    box-shadow:
        0px 12px 24px -15px #0000001a,
        0px 0px 10px -6px #00000040;
    padding-bottom: 2rem;
    border-radius: 30px;

    h1 {
        font-size: 24px;
        font-weight: 700;
        color: #b31d15;
    }

    h3 {
        font-size: 16px;
        font-weight: 400;
        color: #222;
    }
`;
