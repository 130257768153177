import React, { useRef, useState } from 'react';
import { Icon } from '@iconify/react/dist/iconify.js';
import {
    ButtonFileInputPDFDocument,
    ContainerInputPDFDocument,
    GapInputPDFDocument,
    InfoFileDivInputPDFDocument,
    InfoSuccesTitle,
    InfoSuccesTop,
    InputForPDFDocument,
    LabelInputPDFDocument,
    SpanInputPDFDocument,
    UploadSuccessMessage,
    UploadSuccessWrapper,
    WrapperInputPDFDocument,
} from '../../../../styled-components';
import TrashButton from './TrashButton';
import { Text } from '../../../common/Texts';
import ProgressUploadFile from './ProgressUploadFile';

const InputPDFDocument = (props) => {
    const { file, setFile, handleSaveFile } = props;

    const [infoFile, setInfoFile] = useState(null);
    const [fileTypeError, setFileTypeError] = useState(false);
    const [fileSizeError, setFileSizeError] = useState(false);
    const [dragOver, setDragOver] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const fileInputRef = useRef(null);

    const allowedTypes = ['pdf'];

    const handleChange = (e) => {
        if (file === null) {
            handleFile(e.target.files[0]);
        }
    };

    const handleFile = (selectedFile) => {
        setFileTypeError(false);
        setFileSizeError(false);
        const type = selectedFile.type.split('/')[1];
        const lastDotIndex = selectedFile.name.lastIndexOf('.');
        const typeName =
            lastDotIndex !== -1
                ? selectedFile.name.substring(lastDotIndex + 1)
                : '';

        if (!allowedTypes.includes(type) && !allowedTypes.includes(typeName)) {
            setFileTypeError(true);
            return;
        } else {
            setFileTypeError(false);
            const size = (selectedFile.size / 1048576).toFixed(2);
            if (size <= 10) {
                setFile(selectedFile);
                setInfoFile({
                    name: selectedFile.name,
                    size: size + ' MB',
                    type: type,
                });
                // Simulate upload progress
                let progress = 0;
                const interval = setInterval(() => {
                    progress += 25;
                    setUploadProgress(progress);
                    if (progress >= 100) {
                        clearInterval(interval);
                    }
                }, 500);
            } else {
                setFileSizeError(true);
            }
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragOver(false);
        const droppedFile = e.dataTransfer.files[0];
        handleFile(droppedFile);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = () => {
        setDragOver(false);
    };

    const handleTrashFile = () => {
        setFile(null);
        setFileTypeError(false);
        setFileSizeError(false);
        setUploadProgress(0);
        setInfoFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    return (
        <WrapperInputPDFDocument>
            <ContainerInputPDFDocument>
                <LabelInputPDFDocument
                    htmlFor="filePdfInput"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    dragOver={dragOver}
                >
                    {!fileTypeError ? (
                        !fileSizeError ? (
                            file === null ? (
                                <>
                                    <Icon
                                        icon="ic:sharp-cloud-upload"
                                        width="1.2em"
                                        height="1.2em"
                                    />
                                    <GapInputPDFDocument>
                                        <SpanInputPDFDocument>
                                            Arrastra y suelta el archivo aquí
                                        </SpanInputPDFDocument>
                                        <SpanInputPDFDocument>
                                            o
                                        </SpanInputPDFDocument>
                                        <ButtonFileInputPDFDocument>
                                            Selecciona un archivo
                                        </ButtonFileInputPDFDocument>
                                    </GapInputPDFDocument>
                                </>
                            ) : (
                                <UploadSuccessWrapper>
                                    <InfoFileDivInputPDFDocument>
                                        <InfoSuccesTop>
                                            <InfoSuccesTitle>
                                                <SpanInputPDFDocument>
                                                    <Text
                                                        fontSize="15px"
                                                        fontWeight={600}
                                                        color="#616161"
                                                        textAlign="center"
                                                    >
                                                        {infoFile?.name}{' '}
                                                    </Text>
                                                    <Text
                                                        fontSize="10px"
                                                        fontWeight={600}
                                                        color="#a8a8a8"
                                                        textAlign="center"
                                                    >
                                                        {infoFile?.size}
                                                    </Text>
                                                </SpanInputPDFDocument>
                                                <TrashButton
                                                    handleTrashFile={
                                                        handleTrashFile
                                                    }
                                                />
                                            </InfoSuccesTitle>
                                            <ProgressUploadFile
                                                uploadProgress={uploadProgress}
                                            />
                                        </InfoSuccesTop>
                                    </InfoFileDivInputPDFDocument>
                                    {uploadProgress === 100 && (
                                        <>
                                            <UploadSuccessMessage>
                                                <Icon
                                                    icon="simple-line-icons:check"
                                                    width="24px"
                                                    height="24px"
                                                />
                                                <p>
                                                    El archivo se ha adjuntado
                                                    exitosamente. Para completar
                                                    el proceso, haz clic en el
                                                    botón "Subir documento" a
                                                    continuación.
                                                </p>
                                            </UploadSuccessMessage>
                                        </>
                                    )}
                                </UploadSuccessWrapper>
                            )
                        ) : (
                            // fileSizeError
                            <InfoFileDivInputPDFDocument>
                                <Text
                                    fontSize="15px"
                                    fontWeight={600}
                                    color="#616161"
                                    textAlign="center"
                                >
                                    Peso máximo permitido
                                </Text>
                                <Text
                                    fontSize="10px"
                                    fontWeight={600}
                                    color="#a8a8a8"
                                    textAlign="center"
                                >
                                    10MB
                                </Text>

                                <TrashButton
                                    handleTrashFile={handleTrashFile}
                                />
                            </InfoFileDivInputPDFDocument>
                        )
                    ) : (
                        // FileTypeError
                        <InfoFileDivInputPDFDocument>
                            <Text
                                fontSize="15px"
                                fontWeight={600}
                                color="#616161"
                                textAlign="center"
                            >
                                Error de formato de archivo, recuerda que sólo
                                puedes subir el documento en formato PDF.{' '}
                            </Text>

                            <TrashButton handleTrashFile={handleTrashFile} />
                        </InfoFileDivInputPDFDocument>
                    )}
                </LabelInputPDFDocument>
                <InputForPDFDocument
                    multiple={false}
                    onChange={handleChange}
                    id="filePdfInput"
                    type="file"
                    ref={fileInputRef}
                />

                {uploadProgress === 100 && (
                    <ButtonFileInputPDFDocument onClick={handleSaveFile}>
                        <Icon
                            icon="system-uicons:paper-plane"
                            width="24"
                            height="24"
                        />{' '}
                        Subir documento
                    </ButtonFileInputPDFDocument>
                )}
            </ContainerInputPDFDocument>
        </WrapperInputPDFDocument>
    );
};

export default InputPDFDocument;
