import React, { useState } from 'react';
import styled from 'styled-components';

// Material UI
import { SearchRounded } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';

// Components
import SimpleLoading from '../../../../../components/common/SimpleLoading';
import GridCard from '../../../../../components/common/GridCard';
import CardReferred from './CardReferred';
import ReferredFilter from './ReferredFilter';
import NotReferred from './NotReferred';

const DashboardReferred = (props) => {
    const { myReferred } = props;

    // STATES
    const [filterField, setFilterField] = useState('');
    const [currentState, setCurrentState] = useState('Todos');
    const [filtered, setFiltered] = useState(null);

    const handleRefered = (values) => {
        setFiltered(values);
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setFilterField(value);
        handleRefered(
            myReferred.filter((user) =>
                user.name.toLowerCase().includes(e.target.value.toLowerCase())
            )
        );
    };

    function handleCurrentState(newValue) {
        setCurrentState(newValue);
        if (newValue === 'Todos') {
            handleRefered(myReferred);
            return;
        }
        if (newValue === 'Confirmados') {
            handleRefered(myReferred.filter((user) => user.stage === 'Ganado'));
            return;
        }
        handleRefered(myReferred.filter((user) => user.stage !== 'Ganado'));
        return;
    }

    return (
        <Wrapper>
            <Header>
                <EditedTextField
                    value={filterField}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    label="Buscar"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchRounded />
                            </InputAdornment>
                        ),
                    }}
                />
            </Header>
            {/* INPUT DE BUSQUEDA */}
            <FilterWrapper>
                <ReferredFilter
                    currentState={currentState}
                    handleCurrentState={handleCurrentState}
                />
            </FilterWrapper>
            {myReferred === null ? (
                <SimpleLoading />
            ) : filtered?.length === 0 || myReferred?.length === 0 ? (
                <NotReferred tab={currentState} />
            ) : (
                <GridCard
                    cards={filtered || myReferred}
                    component={CardReferred}
                    display="grid"
                    templateColumns="repeat(3, 1fr)"
                    margin="0px"
                    gap="3rem"
                    widthContainer="calc(100% - 1rem)"
                />
            )}
        </Wrapper>
    );
};

export default DashboardReferred;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const FilterWrapper = styled.div`
    width: 100%;
`;

const EditedTextField = styled(TextField)`
    border-radius: 20px;

    .MuiInputBase-root {
        border-radius: 20px;
    }
`;
