import React, { useContext, useEffect, useState } from 'react';
import { IconButton, TextField } from '@mui/material';
import styled from 'styled-components';
import FileComponent from './FileComponent';
import {
    MIMETYPE_CASES,
    MIMETYPE_DOCS_CASES,
} from '../../../../utils/file.utils';
import { Close } from '@mui/icons-material';
import { ChatContext } from '../../../../contexts/chat/ChatProvider';

const MessageInput = (props) => {
    const { onChange, value, file, resetFileInput, loading } = props;

    const { deleteNotification, notifications, currentRoom } =
        useContext(ChatContext);

    const [renderFile, setRenderFile] = useState(null);

    useEffect(() => {
        if (!file) {
            setRenderFile(null);
        }

        setRenderFile(!!file ? URL.createObjectURL(file) : null);
    }, [file]);

    const deleteNotificationOnFocus = () => {
        if (!!notifications?.length) {
            const notification = notifications.find((note) => {
                return note?.room_id === currentRoom?.roomId;
            });
            if (!!notification?._id) {
                deleteNotification(notification?._id);
            }
        }
    };

    return (
        <TextFieldWrapper>
            <TextFieldInput
                disabled={loading}
                id="chat-input"
                onChange={onChange}
                onFocus={deleteNotificationOnFocus}
                value={value}
                placeholder="Escribe tu mensaje aquí"
            />

            {file && MIMETYPE_DOCS_CASES.includes(file?.type) && (
                <FileWrapper>
                    <FileComponent
                        name={file.name}
                        size={file.size}
                        onClose={resetFileInput}
                    />
                </FileWrapper>
            )}
            {file && MIMETYPE_CASES.includes(file?.type) && (
                <FileWrapper>
                    <Image src={renderFile} alt="img" />
                    <CloseImageButton onClick={resetFileInput}>
                        <Close />
                    </CloseImageButton>
                </FileWrapper>
            )}
        </TextFieldWrapper>
    );
};

export default MessageInput;

const TextFieldInput = styled(TextField)`
    width: 100%;
    background-color: #f1f1f1;
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 10px !important;

    .MuiOutlinedInput-notchedOutline {
        display: none !important;
    }
`;

const TextFieldWrapper = styled.div`
    /* position: relative; */
    width: calc(100% - 280px);
    border-radius: 10px;
    height: auto;
    padding: 0.5rem 0.5rem;
    background-color: #f1f1f1;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
    /* &::before {
        content: '';
        position: absolute;
        margin: auto;
        width: calc(100% - 4rem);
        height: 1px;
        background-color: #a8a8a8;
        top: -20px;
        left: 0;
        right: 0;
    } */
`;

const FileWrapper = styled.div`
    width: fit-content;
    position: relative;
    max-width: 450px;
    width: 100%;
    height: auto;
`;

const Image = styled.img`
    max-width: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
    object-position: top;
    border-radius: 10px;
`;

const CloseImageButton = styled(IconButton)`
    position: absolute;
    margin: auto;
    right: 10px;
    top: 10px;
    background-color: #ffffff;

    &:hover {
        background-color: #ffffff;
    }
`;
