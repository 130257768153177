import axios from 'axios';

export async function deleteNotificationRequest(notificationId) {
    const URL = `${process.env.REACT_APP_CHAT_V2}/notifications/delete`;

    const payload = {
        notification_id: notificationId,
    };

    try {
        const response = await axios.delete(URL, {
            data: payload,
            headers: { 'Content-Type': 'application/json' },
        });

        return response.data;
    } catch (error) {
        throw new Error({
            message: 'Somthing went wrong!',
            error: error.toString(),
        });
    }
}
