import styled from 'styled-components';

// Components
import FontAwesomeIcon from '../../../components/common/FontAwesomeIcon';
import CommonButton from '../../../components/common/CommonButton';
import UpdatedCircularProgress from './UpdatedCircularProgress';

const UpdatedSubjectHeader = (props) => {
    const { subjectData } = props;

    return (
        <Container>
            <ProgressWrapper>
                <UpdatedCircularProgress
                    strokeWidth={24}
                    progress={Math.ceil(subjectData?.progress?.progress)}
                />
            </ProgressWrapper>
            <Wrapper>
                <span>
                    <h2>ASIGNATURA</h2>
                </span>
                <h1>{subjectData?.name}</h1>
            </Wrapper>
            <CommonButton
                label="Ver certificado"
                variant="filled"
                disabled
                onClick={() => {}}
                Icon={() => (
                    <div>
                        <FontAwesomeIcon
                            icon="fa-light fa-award-simple"
                            size={21}
                        />
                    </div>
                )}
            />
        </Container>
    );
};

export default UpdatedSubjectHeader;

const Container = styled.div`
    width: calc(100% - 4rem);
    height: 300px;
    border-radius: 20px;
    border: 1px solid #ffbc00;
    box-shadow:
        0px 12px 24px -15px #0000001a,
        0px 0px 10px -6px #00000040;
    background-color: #fff;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0 2rem;

    button {
        height: fit-content;
        white-space: nowrap;
    }

    @media (width < 1068px) {
        height: fit-content;
        flex-direction: column;
        gap: 2rem;
        padding: 2rem;
    }
`;

const ProgressWrapper = styled.div`
    position: relative;
    width: fit-content;
    height: 100%;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (width < 1600px) {
        width: 25%;
    }

    @media (max-width: 768px) {
        width: 190px;
        height: 190px;
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

const Wrapper = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 2rem;

    h2 {
        font-size: 16px;
        font-weight: 500;
        color: #222;
    }

    h1 {
        font-size: 24px;
        font-weight: 700;
        color: #ffbc00;
    }

    span {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    @media (width < 1068px) {
        width: 100%;
    }
`;
