import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

// Components
import ChatBoard from './components/room/ChatBoard';

// Hooks
import { ChatContext } from '../../contexts/chat/ChatProvider';

const Chat = () => {
    const { user } = useContext(ChatContext);

    const history = useHistory();

    useEffect(() => {
        user === 'not-found' && history.push('/not-found');
    }, [user]);

    return (
        <Wrapper>
            <Container>
                <ChatBoard />
            </Container>
        </Wrapper>
    );
};

export default Chat;

const Wrapper = styled.div`
    box-sizing: border-box;
    width: calc(100% + 4rem);
    height: 100%;
    margin: -2rem;
`;

const TabsWrapper = styled.div`
    width: calc(100% - 2rem);
    background-color: #fff;
    box-shadow:
        0px 4px 4px -2px #18274b14,
        0px 2px 4px -2px #18274b1f;
    padding-left: 2rem;
`;

const Container = styled.div`
    box-sizing: border-box;
    height: fit-content;
    padding: 2rem;

    @media screen and (max-width: 1024px) {
        padding: 1rem 0 1rem 0;
    }
    @media screen and (max-width: 768px) {
        height: calc(100%);
    }
`;
