import styled, { keyframes } from 'styled-components';
import React, { useEffect, useState } from 'react';

// Components
import { Icon } from '@iconify/react/dist/iconify.js';
import FormRefered from '../FormRefered';

// Material
import { Card, Dialog } from '@mui/material';

// Redux
import { getCountries } from '../../../../redux/api/utils';
import { useSelector } from 'react-redux';

const BannerReferred = () => {
    const { user } = useSelector((state) => state.auth);

    const [open, setOpen] = useState(false);
    const [countries, setCountries] = useState(null);

    const handleModal = () => {
        setOpen(!open);
    };

    useEffect(() => {
        !countries && getCountry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // FUNCTIONS
    const getCountry = async () => {
        try {
            const request = await getCountries();
            setCountries(request.data.response_data);
        } catch (error) {}
    };

    return (
        <CardFake>
            <Dialog open={open} onClose={handleModal}>
                <FormRefered
                    countries={countries}
                    student={user}
                    misReferidos={true}
                />
            </Dialog>
            <h2>
                Comparte, expande y crece con el <b>"Programa de Referidos"</b>
            </h2>
            <p>
                ¡Sigamos impulsando el talento y la prosperidad de la comunidad
                latinoamericana!
                <br />
                Queremos que más ejecutivos vivan la experiencia ADEN.
            </p>
            <p>
                <b>
                    Invita a un colega, amigo o familiar y obtén increíbles
                    beneficios.
                </b>
            </p>
            <button onClick={handleModal}>
                <Icon
                    icon="simple-line-icons:plus"
                    width="24px"
                    height="24px"
                />
                Añadir referido
            </button>
        </CardFake>
    );
};

export default BannerReferred;

const addOutline = keyframes`
    0% {
        outline: none;
    }
    16% {
        outline: 1px solid #D5D5D5;
    }
    32% {
        outline: 2px solid #D5D5D5;
    }
    48% {
        outline: 3px solid #D5D5D5;
    }
    64% {
        outline: 4px solid #D5D5D5;
    }
    80% {
        outline: 5px solid #D5D5D5;
    }
    100% {
        outline: 6px solid #D5D5D5;
    }
`;

const CardFake = styled(Card)`
    position: relative;
    border-radius: 20px;
    width: 60%;
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 4rem;
    background: #ffffff;
    box-shadow:
        0px 3px 6px 0px #0000003b,
        0px 3px 6px 0px #00000029;
    border-radius: 20px;
    border: 1px solid #a8a8a8;
    z-index: 1;

    h2 {
        color: #b31d15;
        font-size: 24px;
    }

    p {
        color: #000000;
        font-size: 20px;
    }

    button {
        cursor: pointer;
        height: 44px;
        padding: 0 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        background-color: #cd2118;
        color: #ffffff;
        border-radius: 100px;
        animation: ${addOutline} 0.5s infinite alternate;
    }
`;
