import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Badge, IconButton } from '@mui/material';
import { ChatBubble } from '@mui/icons-material';
import { ChatContext } from '../../../../contexts/chat/ChatProvider';
import NotificationsPopover from '../chatV2/NotificationsPopover';

const MessagesV2 = () => {
    const { notifications = null } = useContext(ChatContext);

    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopover = (event) => {
        setAnchorEl(!anchorEl ? event.currentTarget : null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'notifications-popover' : undefined;

    if (!notifications) {
        return <></>;
    }

    return (
        <Wrapper id="parent">
            <CustomIconButton onClick={handlePopover} aria-describedby={id}>
                <CustomBadge
                    badgeContent={notifications?.length}
                    color="primary"
                >
                    <ChatBubble htmlColor="#bfbfbf" />
                </CustomBadge>
            </CustomIconButton>
            <NotificationsPopover
                id={id}
                open={open}
                onClose={handlePopover}
                anchorEl={anchorEl}
                notifications={notifications}
            />
        </Wrapper>
    );
};

export default MessagesV2;

const Wrapper = styled.div`
    position: relative;
    padding: 0 !important;
    min-width: 60px;
    min-height: 60px;
    z-index: 1;
`;

const CustomIconButton = styled(IconButton)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
`;

const CustomBadge = styled(Badge)`
    position: absolute;
    z-index: 10;
`;
