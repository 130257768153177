import axios from 'axios';

export const getSubjectData = async (hash, repoId) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/assignment/${hash}?repo_id=${repoId}`;
    try {
        const res = await axios.get(URL);
        const data = res.data;
        const postData = data.response_data.assignment;
        /* setMenu(postData.name); */
        /* const URL_POST = `${process.env.REACT_APP_REPO}/v1/repo_aden/enrollment/update_latest_entry_acropolis/${repoId}?asignatura_id=${postData?.id}`;
        try {
            await axios.post(URL_POST);
        } catch (error) {
            console.error(error)
        } */
        return postData;
    } catch (error) {
        return error.request.status;
        /* return {
            error,
        }; */
    }
};
