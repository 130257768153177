import React from 'react';
import styled from 'styled-components';
import { SearchRounded } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';

const Search = (props) => {
    const { search, handleChange, label = 'Buscar', disabled = false } = props;

    return (
        <TextFieldInput
            value={search}
            onChange={handleChange}
            size="small"
            label=""
            variant="outlined"
            placeholder={label}
            disabled={disabled}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchRounded />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default Search;

const TextFieldInput = styled(TextField)`
    width: 100%;
    background-color: #ffffff;
    border-radius: 100px;

    .MuiInputBase-root {
        color: #a8a8a8;
        font-weight: 500;
        border-radius: 100px;
    }
    fieldset {
        border-radius: 100px;
    }
    svg {
        fill: #616161;
        font-size: 24px;
        font-weight: 400;
    }
`;
