import React, { useState } from 'react';
import styled from 'styled-components';

import Navegation from '../navegation/Navegation';
import ErrorBoundary from '../../../utils/ErrorBoundary';
import ErrorBoundaryAlert from '../../../components/common/ErrorBoundaryAlert';
import Copyright from '../../common/Copyright';
import { useServices } from '../../../hooks/useServices';
import BannerServices from '../../common/BannerServices';
import { BlockedAlert } from '../finantial-status/BlockedAlert';
import Anniversary from '../../ui/shared/Anniversary';
import WhatsappChat from '../chat-layout/WhatsappChat';

const Layout = (props) => {
    const { finantial_status, title } = props;

    // STATE
    const [menu, setMenu] = useState(false);
    // const [cumbre, setCumbre] = useState(null)
    const [services] = useServices();

    // FUNCTIONS
    function toggleMenu() {
        setMenu(!menu);
    }

    function closeMenu() {
        setMenu(false);
    }

    // RETURN
    return (
        <>
            {!!services && services.length > 0 && (
                <BannerServices
                    absolut={false}
                    title="¡Algunos de nuestros servicios están en mantenimiento! Puede que presente algun error el sitio."
                />
            )}
            {/* <BlockedAlert
                show={true}
                blocked={finantial_status?.blocked}
            /> */}
            {/* {cumbre && layout === "common" && <BannerLayout evento={cumbre} />} */}
            <NavCointainer
                services={!!services && services.length ? true : false}
            >
                <Navegation
                    title={title}
                    menu={menu}
                    closeMenu={closeMenu}
                    toggleMenu={toggleMenu}
                />
                {/* <Menu menu={menu} closeMenu={closeMenu} /> */}
            </NavCointainer>

            <Overlay menu={menu}></Overlay>
            <Main menu={menu}>
                <ErrorBoundary fallback={<ErrorBoundaryAlert />}>
                    {props.children}
                </ErrorBoundary>
                {/* <Footer /> */}
            </Main>

            <WhatsappChat />

            {/* Aniversario 30 años */}
            {/* <Anniversary /> */}
        </>
    );
};

export default Layout;

const NavCointainer = styled.div`
    box-sizing: border-box;
    position: fixed;
    z-index: 999;
    height: ${(props) => (props.bannerExists ? 'calc(100vh - 70px)' : '100vh')};
    top: ${(props) => (props.bannerExists ? '70px' : '0')};
    height: '100vh';
    top: '0';

    @media screen and (max-width: 768px) {
        height: ${(props) =>
            props.bannerExists ? 'calc(100vh - 110px)' : '100vh'};
        top: ${(props) => (props.bannerExists ? '110px' : '0')};
    }
`;

const Main = styled.main`
    box-sizing: border-box;
    position: relative;
    padding: ${(props) =>
        props.bannerExists
            ? 'calc(2rem + 140px) 2rem 9em calc(2rem + 70px)'
            : 'calc(2rem + 70px) 2rem 9rem calc(2rem + 70px)'};
    overflow: ${(props) => props.menu && 'hidden'};
    height: 100vh;
    min-height: calc(100vh - 120px - 60px - 4rem);
    background-color: #f9f9f9;
    @media (max-width: 1440px) {
        padding: ${(props) =>
            props.bannerExists
                ? 'calc(2rem + 140px) 1rem 9rem calc(1rem + 70px)'
                : 'calc(2rem + 70px) 1rem 9rem calc(1rem + 70px)'};
    }

    @media (max-width: 1024px) {
        padding: ${(props) =>
            props.bannerExists
                ? 'calc(2rem + 140px) 1rem 12rem calc(1rem + 70px)'
                : 'calc(2rem + 70px) 1rem 12rem calc(2rem + 70px)'};
    }

    @media (max-width: 768px) {
        padding: ${(props) =>
            props.bannerExists
                ? 'calc(2rem + 180px) 1rem 18rem 1rem'
                : 'calc(2rem + 70px) 1rem 1rem 1rem'};
    }
`;

const Overlay = styled.div`
    box-sizing: border-box;
    display: ${(props) => (props.menu ? 'flex' : 'none')};
    visibility: ${(props) => (props.menu ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.menu ? 1 : 0)};
    transition: all 0.5s ease;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background-color: #00000050;
    z-index: 9;
`;
