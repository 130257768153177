import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Components
import BannerReferred from './components/my-referrals/BannerReferred';
import MyCardReferred from './components/my-referrals/MyCardReferred';
import PanelReferred from './components/my-referrals/PanelReferred';
import BenefitsCard from './components/my-referrals/BenefitsCard';
import InfoSection from './components/my-referrals/InfoSection';

// Redux
import { getMyReferred } from '../../redux/api/referred';

// Adapters
import { createReferredAdapter, createUserAdapter } from './adapters';

// Hooks
import useFetch from '../../hooks/useFetch';

const MyReferrals = () => {
    const reduxUser = useSelector((state) => state?.auth?.user);
    const user = createUserAdapter(reduxUser);

    const { data, loading, error } = useFetch({
        asyncFn: () => getMyReferred(user.crmPartnerId),
    });

    console.info('myReferred', data);

    return (
        <Container>
            <section>
                <MyCardReferred user={user} infoReferred={data} />
                <BannerReferred />
            </section>
            <BenefitsCard />
            <InfoSection />
            <PanelReferred myReferred={data} />
        </Container>
    );
};

export default MyReferrals;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    section {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 2rem;
    }
`;
