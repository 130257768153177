import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import WhatsappIcon from '@mui/icons-material/WhatsApp';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import { IconButton, Tooltip } from '@mui/material';
import { ChatContext } from '../../../contexts/chat/ChatProvider';
import { getUserByRepoPartnerId } from '../../../redux/api/chatAPI/user';

const SocialMediaToCard = (props) => {
    const { value } = props;

    const { handleChat } = useContext(ChatContext);

    //Chat students of subject
    const partnerId = value.partner_id ? value.partner_id : value.id;

    return (
        <SocialMedia>
            <BtnSocial onClick={() => handleChat(partnerId)} variant="outlined">
                <Tooltip title="Chat">
                    <ForumRoundedIcon />
                </Tooltip>
            </BtnSocial>

            <BtnSocial
                disabled={value.whatsapp === ''}
                href={`https://wa.me/?phone=${value.whatsapp}`}
                target="_blank"
                rel="noreferrer"
            >
                <Tooltip title="Whatsapp">
                    <WhatsappIcon />
                </Tooltip>
            </BtnSocial>

            <BtnSocial
                disabled={value.personal_email === ''}
                href={`mailto:${value.personal_email}`}
                target="_blank"
                rel="noreferrer"
            >
                <Tooltip title="E-mail">
                    <AlternateEmailRoundedIcon />
                </Tooltip>
            </BtnSocial>

            <BtnSocial
                disabled={value.mobile === ''}
                href={`tel:${value.mobile}`}
                target="_blank"
                rel="noreferrer"
            >
                <Tooltip title="Llamado telefónico">
                    <LocalPhoneRoundedIcon />
                </Tooltip>
            </BtnSocial>
        </SocialMedia>
    );
};

export default SocialMediaToCard;

const SocialMedia = styled.div`
    height: 100%;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const BtnSocial = styled(IconButton)`
    cursor: pointer;
    /* width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center; */
    svg {
        color: #b31d15;
        font-size: 22px;
    }
`;
