export const roomAdapter = (room) => {
    const adaptedRoom = {
        id: room?._id,
        name: room?.name,
        repoId: room?.partner_id,
        origins: room?.origins,
        createdAt: room?.created_at,
        socketId: room?.socket_id || '',
        online: room?.online,
        roomId: room?.room_id,
        profilePhoto: room?.profile_photo,
        unseenMessages: room?.unseen_messages,
        latestMessage: {
            messageId: room?.latest_message?._id || null,
            emitter: room?.latest_message?.emitter || null,
            reciver: room?.latest_message?.reciver || null,
            createdAt: room?.latest_message?.created_at || null,
            messageBody: {
                message: room?.latest_message?.message_body?.message || null,
                fileUrl: room?.latest_message?.message_body?.file_url || null,
                mimeType: room?.latest_message?.message_body?.mime_type || null,
                downloadName:
                    room?.latest_message?.message_body?.download_name || null,
            },
        },
    };

    return adaptedRoom;
};
