import styled from 'styled-components';

// Material UI
import { Card } from '@mui/material';

// Components
import SimpleLoading from '../../../../components/common/SimpleLoading';
import { Text } from '../../../../components/common/Texts';

const MyCardReferred = (props) => {
    const { user, infoReferred } = props;

    const confirmed =
        infoReferred?.filter((item) => item.stage === 'Ganadas').length || 0;

    console.info('leads', infoReferred);

    return (
        <CardFake>
            <Header>
                <Image>
                    <img alt="user" src={user?.photo} />
                </Image>
                <UserWrapper>
                    <label>
                        Nombre: <b>{user?.name}</b>
                    </label>
                    <label>
                        Correo electrónico: <b>{user?.personalEmail}</b>
                    </label>
                    <label>
                        Legajo: <b>{user?.sisId}</b>
                    </label>
                </UserWrapper>
            </Header>
            <Text fontSize="20px" fontWeight="600" color="#b31d15">
                Mi resumen del Programa de Referidos:
            </Text>
            {infoReferred === null ? (
                <SimpleLoading />
            ) : (
                <Body>
                    <MiniCard>
                        <span>{infoReferred?.length || '0'}</span>
                        <p>
                            Amigos <br /> referidos
                        </p>
                    </MiniCard>
                    <MiniCard>
                        <span>{confirmed}</span>
                        <p>
                            Amigos <br /> confirmados
                        </p>
                    </MiniCard>
                    {/* <MiniCard>
						<span>{infoReferred?.benefits || "0"}</span>
						<p>
							Beneficios <br /> recibidos <br />
						</p>
					</MiniCard> */}
                </Body>
            )}
        </CardFake>
    );
};

export default MyCardReferred;

const CardFake = styled(Card)`
    background-color: #ffffff;
    box-shadow:
        0px 3px 6px 0px #0000003b,
        0px 3px 6px 0px #00000029;
    border-radius: 20px;
    max-width: 35%;
    min-height: 416px;
    width: calc(100% - 4rem);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 1rem;
    border: 1px solid #a8a8a8;
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    gap: 2rem;
    align-items: center;
    max-width: 100%;
`;

const Image = styled.div`
    width: 120px;
    height: 120px;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        border-radius: 100%;
    }
`;

const UserWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    label {
        font-size: 16px;
        font-weight: 400;
        color: #222222;
    }
`;

const Body = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1rem;
`;

const MiniCard = styled.div`
    width: 157px;
    height: 124px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid #c4c4c4;
    box-shadow:
        0px 8px 8px -4px #18274b0a,
        0px 4px 6px -4px #18274b0a;
    border-radius: 20px;

    span {
        color: #000000;
        font-weight: 700;
        font-size: 36px;
    }

    p {
        color: #000000;
        font-size: 15px;
        text-align: center;
    }
`;
