import React from 'react';
import { Text } from '../../../common/Texts';
import styled from 'styled-components';
import { Icon } from '@iconify/react';

const TrackingCard = (props) => {
    const { state, date, isCurrrentState, trackedStatus, lastTracking } = props;

    return (
        <ContainerTrackingCard
            trackedStatus={trackedStatus}
            lastTracking={lastTracking}
        >
            <CardTracking isCurrrentState={isCurrrentState}>
                <Text
                    fontWeight="600"
                    fontSize="13px"
                    color="#222222"
                    letterSpacing="0.5%"
                    lineHeight="16px"
                >
                    {state}
                </Text>
                {date && (
                    <Text
                        fontWeight="400"
                        fontSize="13px"
                        color="#616161"
                        letterSpacing="0.5%"
                        lineHeight="16px"
                    >
                        FECHA: {date}
                    </Text>
                )}
            </CardTracking>
            <Check trackedStatus={trackedStatus}>
                {isCurrrentState ? (
                    <Icon
                        icon="material-symbols-light:circle-outline"
                        width="32"
                        height="32"
                    />
                ) : trackedStatus ? (
                    <Icon icon="lets-icons:check-fill" width="32" height="32" />
                ) : (
                    <Icon
                        icon="material-symbols-light:circle-outline"
                        width="32"
                        height="32"
                    />
                )}
            </Check>
        </ContainerTrackingCard>
    );
};

export default TrackingCard;

const ContainerTrackingCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    border-left: ${(props) =>
        props.lastTracking === true
            ? 'none'
            : props.trackedStatus === true
              ? '2px solid #28a986'
              : '2px solid #a1a1a1'};
    width: 250px;
    height: calc(80px - 2rem);
    padding: 0 0 1rem 2rem;
    position: relative;
`;

const Check = styled.div`
    position: absolute;
    top: -8px;
    left: -16px;
    svg {
        color: ${(props) =>
            props.trackedStatus === true ? '#28a986' : '#a1a1a1'};
        background-color: white;
    }
`;

const CardTracking = styled.div`
    border: ${(props) =>
        props.isCurrrentState === true ? '1px solid #28a986' : 'none'};
    border-radius: 10px;
    margin-top: -1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    box-shadow: ${(props) =>
        props.isCurrrentState === true
            ? '6px 6px 12px 2px #0000001a, 0px 0px 10px -6px #00000040'
            : 'none'};
`;
